import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Platform } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { ApiService } from '../../services/api.service';

@Component({
  selector: 'app-button-reaction-test',
  templateUrl: './button-reaction-test.component.html',
  styleUrls: ['./button-reaction-test.component.scss'],
})
export class ButtonReactionTestComponent {

  @Input() maxRetries: number = 3;
  @Input() practiceMode: boolean = false;
  @Output() result = new EventEmitter<{ trials: number[], averageTime: number }>();

  averageTime: any;
  trials: number[] = [];
  showLight = false;
  startTime: number;
  endTime: number;

  constructor() { }

  startTest() {
    console.log("Starting test");
    if (!this.practiceMode && this.trials.length >= this.maxRetries) return;

    setTimeout(() => {
      this.showLight = true;
      this.startTime = Date.now();
    }, Math.random() * 2000 + 1000);
  }

  onClick() {
    if (this.showLight) {
      this.endTime = Date.now();
      this.trials.push(this.endTime - this.startTime);
      this.showLight = false;
      this.calculateAverage();
      if (!this.practiceMode && this.trials.length >= this.maxRetries) {
        this.result.emit({ trials: this.trials, averageTime: this.calculateAverage() });
      }
    }
  }

  calculateAverage(): number {
    const sum = this.trials.reduce((a, b) => a + b, 0);
    this.averageTime = sum / this.trials.length;
    return this.averageTime;
  }

}
