<!-- <h1 mat-dialog-title *ngIf="data.title">{{ data.title }}</h1>
<div mat-dialog-content>
  <p *ngIf="data.message" style="white-space: break-spaces;">{{ data.message }}</p>
</div>
<div class="action mt-4 text-center">
  <app-adios-btn class="action-button" highlight="teal" [addmargin]="false" [addmarginleft]="false"
  (click)="onPositiveClick()">
    {{ positive }}
  </app-adios-btn>

  <app-adios-btn class="action-button ml-2 mr-2 mb-2" highlight="teal" [addmargin]="false" [addmarginleft]="false" *ngIf="option"
    (click)="onOptionClick()">
    {{ option }}
  </app-adios-btn>

  <app-adios-btn class="action-button ml-2 mr-2 mb-2" highlight="teal" [addmargin]="false" [addmarginleft]="false" *ngIf="showNegative" (click)="onNoClick()">
    {{negative}}
  </app-adios-btn>

</div> -->


<div class="dialog-box bg-beige">
  <h1 *ngIf="data.title">{{ data.title }}</h1>
  <div class="dialog-content" *ngIf="data.message" >
    <p *ngIf="data.message" style="white-space: break-spaces;">{{ data.message }}</p>
  </div>
  <div class="action mt-4 text-center">
    
    <button class="bg-white btn-round action-button"
      (click)="onPositiveClick()">
      {{ positive }}
    </button>
  
    <button class="bg-white btn-round" highlight="teal" *ngIf="option"
      (click)="onOptionClick()">
      {{ option }}
    </button>
  
    <button class="btn-default action-button ml-2 mr-2 mb-2"  *ngIf="showNegative" (click)="onNoClick()">
      {{negative}}
    </button>
  
  </div>
</div>