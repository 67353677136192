import { ChangeDetectorRef, Component, NgZone } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { UserService } from '../../services/user.service';
import { ApiService } from '../../services/api.service';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Router } from '@angular/router';

@Component({
  selector: 'modal-congratulations',
  templateUrl: './congratulations.component.html',
  styleUrls: ['./congratulations.component.scss']
})
export class CongratulationsModalComponent {
  
  messageText: string;
  importance: string;
  messageToTeam: string;
  name: string;
  showCelebration:boolean = false;
  numSituations: any;
  loading?:boolean = false;

  selectedOption: any;
  message: string = '';
  selectedIcon: string = '';

  situationId: any;
  isSettingImportance : boolean = false;
  importanceSet: boolean = false;
  selectOption(option: any) {
    this.selectedOption = option;

    this.isSettingImportance = true;
    this.apiService.setSituationImportance(this.situationId, option).then((res: any) => {
      
      this.importanceSet = true;
      this.isSettingImportance = false;
      document.querySelector('.box').scrollTo(0,0);
      
    }).catch(e => {
      if (!e.msg) {
        e.msg = "Something went wrong";
      }
      this.snackbar.open(e.msg);
      this.isSettingImportance = false;
    });

  }

  selectIcon(icon: string) {
    this.selectedIcon = icon;
  }

  sendingLove?: boolean = false;
  loveSent?: boolean = false;
  sendLove(){
    this.sendingLove = true;
    this.apiService.sendSituationLove(this.situationId, this.messageToTeam, this.selectedIcon, this.selectedOption).then((res: any) => {
      this.loveSent = true;
      this.loading = true;
      document.querySelector('.back').scrollTo(0,0);

      setTimeout(() => {
        this.loading = false;
        this.sendingLove = false;
      }, 50);
      
    }).catch(e => {
      if (!e.msg) {
        e.msg = "Something went wrong";
      }
      this.snackbar.open(e.msg);
      this.sendingLove = false;
    });

  }

  referAdios() {      
    this.router.navigateByUrl('/recommend');
    this.modalController.dismiss();
  }

  constructor(
    private modalController: ModalController, 
    private navParams: NavParams,
    private user: UserService,
    private apiService: ApiService,
    private router: Router,
    private snackbar: MatSnackBar,
    private ref: ChangeDetectorRef,
    private zone: NgZone
  ) {
    this.name = this.user.getUserName();
  }

  ngOnInit() {
    this.numSituations = this.navParams.get('numSituations');
    this.situationId = this.navParams.get('situationId');
    this.showCelebration = true;
    setTimeout(() => {
      this.showCelebration = false;
      this.ref.detectChanges();
    }, 3000);
    
    this.messageText = `You resolved this situation on your own. You have resolved ${this.numSituations} situations until now. With every situation you resolve, you will get better and better at finding your own answers in your life on your own without relying on anyone else. And some situations take longer while others might be resolved quickly. Take your time. No rush.`;
  }

  async closeModal() {
    const modalData = {
      importance: this.importance,
      messageToTeam: this.messageToTeam,
      selectedIcon: this.selectedIcon
    };
    await this.modalController.dismiss(modalData);
  }
}