<div class="ion-page">
  <ion-content>
    <div class="fullscreen beige-space">
      <div class="box" style="max-width: 800px;">
        <h1 class="title flex">Terms & Conditions
          <div class="ml-auto align-self-end pr-4"></div>
        </h1>
        <div class="box-body overflow-auto">
          <ng-container>
            <div class="description">
              <p>Please read these terms carefully before using the Adios platform.</p>
              <p>These Terms of Use (these “Terms”) were last updated on Aug 30th, 2022. When we say “our,” “us,” “we,” “Adios,” or “Company” in these Terms, we mean Adios Contemplation Pvt Ltd. When we say “Adios Platform” or “Platform,” we mean the websites – www.adiosworld.com, www.adioscontemplator.com, and the Adios mobile applications on all platforms (Android, iOS, any other). The terms “user,” “you,” “your,” or “yours” refer to any user of the Platform. If you are using the Platform on behalf of a company or other legal entity (“organization”), you represent that you have the authority to bind such entity and its affiliates to these Terms, in which case the terms “user” or “you” shall refer to such entity and its affiliates as well.</p>
              <p>These Terms govern your access and use of the Platform. Please read them carefully, as they constitute a binding agreement between you and us.</p>
              <h2>ACCEPTANCE OF TERMS</h2>
              <p>Accessing, browsing, or otherwise using the Platform or clicking “SIGN UP” or the like (if provided) indicates your acceptance. This means that you have read, understood, and agreed to be bound by these Terms and to the collection and use of your information set out in our Privacy Policy and any other rules or policies available on the Platform, as amended from time to time (collectively referred to as “Policies”). The Policies are incorporated by reference into these Terms. If the contents of any of our other Policies conflict with these Terms, these Terms shall prevail over the Policies.</p>
              <p>We may revise these Terms and update the Platform from time to time. Your continued use of the Platform following any modifications to the Terms will be deemed as acceptance of the modified Terms. For this reason, you should frequently review these Terms. If you do not agree with any part of these Terms, please stop using the Platform immediately.</p>
              <h2>ELIGIBILITY</h2>
              <p>To use or access the Platform, you must be competent to enter a contract under applicable laws. Your continued use of the Platform will mean that you represent and warrant to us that you are competent to enter into a valid and binding contract under applicable laws.</p>
              <h2>USING THE PLATFORM</h2>
              <p>Signing up to use our Platform: While you can scroll through the Platform and access some portion of our services (“Services”) without registering with us, you will need to create a user account (“Adios Account”) and purchase a subscription to the Platform to access and use all our Services. To create an Adios Account, you must provide us with your personal information (including your name, email ID, and your position at your organization) and information about your organization (including its name, description, country of origin, year of creation/incorporation, type of industry and number of employees). We may require you to share additional information in the future.</p>
              <p>Duty to be responsible: You will be responsible for maintaining the confidentiality of your Adios Account, password, and restricting access to your computer or mobile device, and you hereby accept responsibility for all the activities that occur on your Adios Account.</p>
              <p>Security Breach: If you know or have reasons to believe that the security of your Adios Account has been breached, you should report it on our Platform or contact our grievance officer immediately as per the contact information provided below in Section 15 (Grievance Officer). If we find a security breach or suspected security breach of your Adios Account, we may require you to change your password and/or temporarily or permanently block your Adios Account without us incurring any liability.</p>
              <h2>PRIVACY OF DATA</h2>
              <p>The Adios Privacy Policy (available here) provides the manner in which we store, process, collect, use, and share the data that is collected from you. Please read it so you know your rights in this regard.</p>
              <h2>LIMITED LICENSE</h2>
              <p>We grant you a limited, non-exclusive, non-transferable, non-sublicensable, revocable license to access and use the Platform.
              You do not have any permission to download, copy, install, reverse-engineer, or use the Platform or information made available on the Platform for any other purpose without our prior written consent. We reserve and retain all rights not expressly granted to you in these Terms. We reserve the right, at our sole discretion and at any time, without notice, to terminate your license to use the Platform and prevent your future access to the Platform.</p>
              <h2>YOUR ORGANIZATION’S INFORMATION</h2>
              <p>In order to avail yourself of our Services, we shall ask you to provide certain information about your organization from time to time (collectively, “Organizational Information”) on the Platform, including the time of the creation of your Adios Account.</p>
              <p>You represent and warrant to us that:</p>
              <p>You have been duly authorized by your organization to provide Organizational Information on the Platform.</p>
              <p>The Organizational Information you provide does not infringe any third-party rights.</p>
              <p>You acknowledge that you are solely responsible for any and all information and content that is uploaded via your Adios Account.</p>
              <h2>RESTRICTION ON USE</h2>
              <p>You agree and undertake that you shall not:</p>
              <p>Use the Platform or any content or information for any purpose or in any manner that is illegal or prohibited by these Terms or under any applicable laws;</p>
              <p>Introduce any trojans, viruses, any other malicious software, any bots or scrape our Platform for any user information. Additionally, you will not probe, scan, or test the vulnerability of any system, security or authentication measures implemented by us. If you tamper or attempt to tamper with our technical design and architecture, we may terminate your Adios Account. We may further report such actions to the appropriate law enforcement authorities and initiate legal actions.</p>
              <h2>THIRD-PARTY DATA AND ADVERTISING</h2>
              <p>Third-Party Data: We may use various third-party data, software, and/or services on the Platform, including payment gateway providers. Although we have appropriate agreements in place with these third parties, we do not accept any liabilities that may arise from our use of/reliance on such third-party data, software, or services.</p>
              <p>Third-Party Advertising: We may, from time to time, display offers and advertisements from third parties on our Platform for your benefit. However, this does not mean we endorse these third parties. If you accept any of the services of such third parties, such arrangement shall be solely between you and the third party. You should avail of such services only after you have read their terms of use and privacy policies.</p>
              <h2>DISCLAIMERS</h2>
              <p>You agree to use the Platform at your own risk. The Platform, including any content and any other information, is provided “as is” and, to the fullest extent permitted by law, is provided without warranties of any kind, either express or implied. We do not warrant the quality of the Platform, including its uninterrupted, timely, secure, or error-free provision, or continued compatibility for your use, or that the Platform will be accurate, reliable, and free of trojans, viruses, malware, or other harmful components.</p>
              <h2>INTELLECTUAL PROPERTY</h2>
              <p>Our Intellectual Property: All rights, interest, and title in the Platform, its layout, trademarks, copyrights, content, information, images, illustrations, graphics, video clips, text, databases, utility models, underlying technology, firmware, software, know-how, designs, ideas, discoveries, inventions, improvements, underlying codes and algorithm, mask works, moral rights, trade secrets, database rights patent, rights of publicity, trademarks, service marks, logos and designs, data analysis, data models, data sets, formulae and processes, and any upgrades, fixes, improvements or modifications thereto (“Intellectual Property”), vests with us unconditionally and constitutes our exclusive Intellectual Property.</p>
              <p>Prohibited use of Intellectual Property: You do not have the right to duplicate, distribute, create derivative works of, display, extract the source code of the Platform, translate the Platform, information and content thereof, or commercially exploit our Intellectual Property directly or indirectly, without our prior written permission. If you create any derivative works using our Intellectual Property without our explicit written permission, then we shall have all ownership and beneficial rights in such derivative work free of charge.</p>
              <h2>LIMITATION OF LIABILITY</h2>
              <p>In no case shall we, our directors, officers, employees, affiliates, agents, contractors, or licensors, be liable for any direct, indirect, incidental, punitive, special, or consequential damages arising from your use of or reliance on the Platform, our Services or information or content provided therein, even if advised of their possibility. Our total aggregate monetary liability arising under these Terms shall be limited to INR 10,000 (Rupees Ten One Thousand only).</p>
              <p>Adios is a provider of online and mobile content in the health & wellness space. It trains you in a regular habit of Contemplation - deep thinking - to achieve clarity of thought and peace of mind. We are not a health care or medical device provider, nor should our products be considered medical advice. Only your physician or other health care provider can do that. Adios makes no claims, representations, or guarantees that our products provide a physical or therapeutic benefit.</p>
              <p>To the extent that you participate or engage in any program, actions, or exercises on the Adios Platform, you represent and warrant that you possess adequate physical health to perform such activities and have no disability or condition that would make such actions or exercises dangerous. You should consult a licensed physician prior to beginning any Adios program. You acknowledge that Adios has advised you of the necessity of doing so.</p>
              <p>Any advice or other materials on the Adios Platform are intended for general information purposes only. They are not intended to be relied upon and are not a substitute for professional medical advice based on your individual condition and circumstances.
              People with existing mental health conditions should first speak with their healthcare providers before starting any Adios program or the practice of Contemplation or any exercises on the Adios Platform.</p>
              <h2>INDEMNIFICATION</h2>
              <p>You agree to defend, indemnify, and hold harmless us as well as our affiliates and their respective investors, directors, officers, employees, and agents from and against any and all claims, damages, obligations, losses, liabilities, costs or debt, and expenses (including but not limited to attorney’s fees) arising from: (i) your use and access to Platform and/or our Services; (ii) your violation of these Terms; (iii) your violation of any third party right; (iv) your breach of applicable laws; and (v) any unauthorized, improper, illegal or wrongful use of your Adios Account by any person, including by a third party, whether or not authorized or permitted by you.</p>
              <h2>TERM AND TERMINATION</h2>
              <p>Indefinite term until termination: These Terms will be effective until terminated by us or you. We reserve the right, at any time, to:</p>
              <p>Discontinue or modify any aspect of the Platform and our Services and/or</p>
              <p>Terminate these Terms and your use of the Platform with or without cause.</p>
              <p>Termination by us: We may remove your Adios Account as well as blacklist you from the Platform immediately if you use the Platform in contravention of these Terms or our Privacy Policy.</p>
              <h2>ELECTRONIC COMMUNICATION</h2>
              <p>You consent to receive communications from us electronically. This shall include notifications, promotions, advertisements, and other communications related to our Platform, information, and other content and services. We will communicate with you by email, through push notifications on your computer or mobile device, or by posting notices on the Platform. You agree that all agreements, notices, disclosures, and other communications that we provide to you electronically satisfy any legal requirement that such communications be in writing. You agree that if there are any changes to any information you provide to us, including your email address and other contact details, you will be solely responsible for updating them regularly. If we send any communication by email, it shall be sent to your email address available on the records created by you on the Platform, and it shall be deemed to have been received by you once it is reflected as sent in the outbox of our email ID.</p>
              <h2>GRIEVANCE OFFICER</h2>
              <p>If you have any complaints, grievances, or questions related to the Platform, information, and other content and services (“Grievances”), please contact our grievance officer in the following manner:</p>
              <p>Name: Tarun Gulati</p>
              <p>Email: tarun@adiosworld.com</p>
              <h2>SURVIVAL</h2>
              <p>Sections 9 (Disclaimers), 10 (Intellectual Property), 11 (Limitation of Liability), 12 (Indemnification), 13 (Term and Termination), 15 (Grievance Officer), 16 (Survival), and 17 (General Provisions) shall survive the efflux of time and the termination of these Terms.</p>
              <h2>GENERAL PROVISIONS</h2>
              <p>Disputes: These Terms and any action related thereto will be governed by the laws of the Republic of India. Any disputes arising out of or related to the Terms and/or Platform (collectively, “Dispute(s)”) shall be subject to the jurisdiction of the courts located in Pune. Any and all Disputes shall be referred to and finally resolved by a mutually appointed sole arbitrator in accordance with the provisions for fast-track arbitration provided under the Arbitration and Conciliation Act 1996, including any amendment or modification thereto. The seat and venue of arbitration shall be Pune. The arbitration proceedings shall be in English. Each party shall bear its own expenses and costs in relation to the arbitral proceedings unless otherwise stated in the award.</p>
              <p>Assignment: You shall not assign or transfer any right or obligation that may have accrued to you under these Terms, and any attempt to do so shall be null and void. At our sole discretion, we may assign or transfer any right or obligation that has accrued in our favor without any restriction.</p>
              <p>Waiver: Unless otherwise expressly stated, any delay in our exercising any rights or remedies arising out of these Terms shall not constitute a waiver of rights or remedies, and no single or partial exercise of any rights or remedies hereunder shall prevent any further exercise of rights or remedies by us.</p>
              <p>Severability: If any of these Terms is held to be illegal or unenforceable, the validity, legality, and enforceability of the remaining provisions contained herein shall not in any way be affected or impaired thereby. Any such provision held to be invalid, illegal, or unenforceable shall be substituted by a provision of similar import reflecting the original intent of the parties to the extent permissible under applicable laws.</p>
              <p>Force Majeure: We will not be held responsible for any delay or failure to comply with our obligations under these conditions if the delay or failure arises from any cause that is beyond our reasonable control, such as pandemics, epidemics, acts of God, civil war, embargoes, strikes, etc. This condition does not affect your statutory rights.</p>
              

            </div>
          </ng-container>
        </div>
        <div class="d-flex w-100">
          <button class="btn btn-success w-100 text-white" type="button" (click)="acceptTerms()">
            <span *ngIf="loading" class="spinner-border spinner-border-lg" role="status"
              aria-hidden="true"></span>
            <span *ngIf="!loading">I agree</span>
          </button>
        </div>
      </div>
    </div>
  </ion-content>
</div>