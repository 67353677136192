import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ApiService } from '../../services/api.service';
declare var $: any; 

@Component({
  selector: 'sb-journal-notes',
  templateUrl: './journal-notes.component.html',
  styleUrls: ['./journal-notes.component.scss']
})
export class JournalNotesComponent implements OnInit {

  @Input() contentId?: any;

  @ViewChild('notesList', { static: false }) notesList?: ElementRef;
  loadingPreviousNotes?: boolean = false
  loading: boolean = false;
  loadingJournal: boolean = false;
  savingNote: boolean = false;
  dateOffset: any;

  notes: any = [];
  noteTags: any = [];

  newNote: any = {
    UserJournalTags: []
  };

  constructor(private apiService: ApiService, private snackbar: MatSnackBar) {
    this.dateOffset = this.apiService.getDateOffset();
  }

  ngOnInit(): void {
    this.loadJournal()
  }

  public loadJournal() {
    this.loadingJournal = true;
    this.apiService
      .getUserJournal(null, this.contentId)
      .then((res: any) => {
        this.notes = res.data.notes;
        this.noteTags = res.data.tags;
        this.loadingJournal = false;
        this.scrollToBottom();
      })
      .catch(e => {
        if (!e.msg) {
          e.msg = 'Something went wrong. Please try again';
        }
        this.snackbar.open(e.msg);
        this.loadingJournal = false;
      });
  }

  fetchPreviousNotes() {
    this.loadingPreviousNotes = true;
    if (this.notes.length > 0) {
      var first_message = this.notes[0].UserJournalId;
      var first_note_id = this.notes[0].UserJournalId;
      
      this.apiService.getUserJournal(first_message, this.contentId)
        .then((res: any) => {
          this.notes = res.data.notes.concat(this.notes);
          this.loadingPreviousNotes = false;
          document.getElementById(first_note_id)?.scrollIntoView();
        }).catch((error) => {
          if (!error.msg) {
            error.msg = "Something went wrong";
          }
          this.snackbar.open(error.msg);
          this.loadingPreviousNotes = false;
        });
    }
  }

  onUp(){
    console.log("Scrolled Up");
    if (!this.loadingPreviousNotes) this.fetchPreviousNotes();
  }

  onScrollDown(){
    console.log("Scrolled Down");
  }

  saveNote() {
    if (!this.newNote.Text || this.newNote.Text.trim().length == 0) {
      return;
    }
    this.newNote.ContentId = this.contentId;
    this.savingNote = true;
    this.processtags(this.newNote.UserJournalTags).then((res: any) => {
      var data = JSON.parse(JSON.stringify(this.newNote));
      data.UserJournalTags = res;
      this.apiService.addToJournal(data).then((res: any) => {
        // this.noteTags.push(res.data.newUserTags);
        this.notes.push(res.data.userJournal);
        this.savingNote = false;
        this.newNote = {
          UserJournalTags: []
        };
        this.scrollToBottom();
      }).catch(e => {
        if (!e.msg) {
          e.msg = 'Something went wrong. Please try again';
        }
        this.snackbar.open(e.msg);
        this.savingNote = false;
      });
    });
  }


  processtags(tags: any) {
    var journaltags = JSON.parse(JSON.stringify(tags));
    return new Promise((res, rej) => {
      res(journaltags);
      // if(journaltags.length == 0){
      //   res(journaltags);
      // } else {
      //   var x = 0;
      //   journaltags.forEach((userTag: any) => {
      //     if(userTag.UserTagId && userTag.UserTagId != 0){
      //       userTag.UserTag = undefined;
      //     } else {
      //       userTag.UserTagId = 0;
      //     }
      //     x++;
      //     if (x == journaltags.length){
      //       res(journaltags);
      //     }
      //   });
      // }
    });
  }

  scrollToBottom(): void {
    setTimeout(() => {
      $("#notesList").animate({ scrollTop: $('#notesList').prop("scrollHeight") }, 100);
    }, 50);
  }
}
