<!-- <mat-dialog-content> -->
  <!-- <div class="skip-button">
    <app-adios-btn class="place-self-flex-end" [addmargin]="false" type="button" (click)="skip()"   highlight="lillac">SKIP
    </app-adios-btn>
  </div> -->
  <swiper-container #swiper [keyboard]="true" [pagination]="false" [modules]="swiperModules" >
    <swiper-slide  class="swiper-slide bg-grey">

      <div class="slide-box-container">
        <div class="slide-box overflow-auto">
          <img src="../../../../assets/images/icons/head-tab-icon.svg" class="head-icon">
          <h2 class="mt-3">Welcome {{ username }},</h2>
          <p class="pt-4">Over the next few days, you will learn to think deeply on a
            regular basis - Contemplation. </p>
          <p>This will help you think more clearly and you will be able to find answers to all your personal or
            professional questions on your own, without relying on anyone else.</p>
          <p>If you train your inner counsellor, you may never need to depend on an external one.</p>
          <p>Trust the process. It works.</p>
        </div>
        <div class="w-100 d-flex flex-column">
          <app-adios-btn class="place-self-flex-end" [addmargin]="false" type="button" (click)="next()" highlight="lillac">
          </app-adios-btn>
        </div>
      </div>

    </swiper-slide>
    <!-- <swiper-slide class="swiper-slide bg-grey">
      <div class="slide-box-container">
        <div class="slide-box place-center align-center">
          <h2>Here is how we suggest you go about it.</h2>
        </div>
        <div class="w-100 d-flex place-space-between mt-3">
          <app-back-btn class="place-self-flex-start" type="button" [arrow]="false" (click)="previous()" highlight="lillac">
          </app-back-btn>

          <app-adios-btn class="place-self-flex-end" [addmargin]="false" type="button" (click)="next()" highlight="lillac">
          </app-adios-btn>
        </div>
      </div>
    </swiper-slide> -->
    <swiper-slide class="swiper-slide bg-grey">
      <div class="slide-box-container">
        <div class="slide-box">
          <h2>1. Learn the Adios principles.</h2>
          <img class="mt-4 banner" src="../../../../assets/images/program-screen.png">
          <!-- <img class="mt-4 banner" src="../../../../assets/images/adios-orientation.png"> -->
        </div>
        <div class="w-100 d-flex place-space-between mt-3">
          <app-back-btn class="place-self-flex-start" type="button" [arrow]="false" (click)="previous()" highlight="lillac">
          </app-back-btn>

          <app-adios-btn class="place-self-flex-end" [addmargin]="false" type="button" (click)="next()" highlight="lillac">
          </app-adios-btn>
        </div>
      </div>
    </swiper-slide>

    <swiper-slide class="swiper-slide bg-grey">
      <div class="slide-box-container">
        <div class="slide-box">
          <h2>2. Resolve critical life situations using your decision making journal.</h2>
          <!-- <p class="mt-4">The Adioscope is your decision making journal.</p> -->
          <img class="mt-4 banner" src="../../../../assets/images/adioscope-screen.png">
        </div>
        <div class="w-100 d-flex place-space-between mt-3">
          <app-back-btn class="place-self-flex-start" type="button" [arrow]="false" (click)="previous()" highlight="lillac">
          </app-back-btn>

          <app-adios-btn class="place-self-flex-end" [addmargin]="false" type="button" (click)="next()" highlight="lillac">
          </app-adios-btn>
        </div>
      </div>
    </swiper-slide>
    
    <!-- <swiper-slide class="swiper-slide bg-grey">
      <div class="slide-box-container">
        <div class="slide-box">
          <h2>Analyse your situations by learning the principles in the program.</h2>

          <img class="mt-4 banner" src="../../../../assets/images/program-screen.png">
        </div>
        <div class="w-100 d-flex place-space-between mt-3">
          <app-back-btn class="place-self-flex-start" type="button" [arrow]="false" (click)="previous()" highlight="lillac">
          </app-back-btn>

          <app-adios-btn class="place-self-flex-end" [addmargin]="false" type="button" (click)="next()" highlight="lillac">
          </app-adios-btn>
        </div>
      </div>
    </swiper-slide> -->

    <!-- <swiper-slide class="swiper-slide bg-grey">
      <div class="slide-box-container">
        <div class="slide-box">
          <h2>Solve your situations using Contemplation on the principles you learn in the program.</h2>
          <img class="mt-4 banner" src="../../../../assets/images/adioscope-anaylse.png">
        </div>
        <div class="w-100 d-flex place-space-between mt-3">
          <app-back-btn class="place-self-flex-start" type="button" [arrow]="false" (click)="previous()" highlight="lillac">
          </app-back-btn>

          <app-adios-btn class="place-self-flex-end" [addmargin]="false" type="button" (click)="next()" highlight="lillac">
          </app-adios-btn>
        </div>
      </div>
    </swiper-slide> -->
    
    <swiper-slide class="swiper-slide bg-grey">
      <div class="slide-box-container">
        <div class="slide-box">
          <h2>3. Attend online live Contemplation sessions to improve your practice.</h2>
          <img class="mt-4 banner bg-white border-0" src="../../../../assets/images/adios-zoom.png">
        </div>
        <div class="w-100 d-flex place-space-between mt-3">
          <app-back-btn class="place-self-flex-start" type="button" [arrow]="false" (click)="previous()" highlight="lillac">
          </app-back-btn>

          <app-adios-btn class="place-self-flex-end" [addmargin]="false" type="button" (click)="next()" highlight="lillac">
          </app-adios-btn>
        </div>
      </div>
    </swiper-slide>
    <swiper-slide class="swiper-slide bg-grey">
      <div class="slide-box-container">
        <div class="slide-box place-center align-center">
          <h2>4. Make decisions that keep you peaceful.</h2>
          <!-- <h2>Notice a conspicuous difference in your personal and work life as you practice regular Contemplation.</h2> -->
        </div>
        <div class="w-100 d-flex place-space-between mt-3">
          <app-back-btn class="place-self-flex-start" type="button" [arrow]="false" (click)="previous()" highlight="lillac">
          </app-back-btn>

          <!-- <app-adios-btn class="place-self-flex-end" [addmargin]="false" [addmarginleft]="false" type="button" (click)="next()" highlight="lillac">LISTEN TO INTRO
          </app-adios-btn> -->

          <button (click)="getStarted()" class="btn btn-info text-white place-self-flex-end" type="button">
            LISTEN TO INTRO
          </button>
          
        </div>
      </div>
    </swiper-slide>
    <!-- <swiper-slide class="swiper-slide bg-grey">
      <div class="slide-box-container">
        <div class="slide-box place-center align-center">
          <h2>Please listen to the introduction session and start the first week for free.</h2>
        </div>
        <div class="w-100 d-flex place-space-between mt-3">
          <app-back-btn class="place-self-flex-start" type="button" [arrow]="false" (click)="previous()" highlight="lillac">
          </app-back-btn>
          <app-adios-btn class="place-self-flex-end" [addmargin]="false" type="button" (click)="getStarted()" highlight="lillac">LISTEN NOW
          </app-adios-btn>
        </div>
      </div>
    </swiper-slide> -->
  </swiper-container>
<!-- </mat-dialog-content> -->