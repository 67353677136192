import { Injectable } from '@angular/core';
import { NativeStorage } from '@awesome-cordova-plugins/native-storage/ngx';
import { Platform } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { CommonService } from '../common.service';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  currentPlatform: any;

  constructor(private common: CommonService, private nativeStorage: NativeStorage, private storage: Storage) {

  }

  getItem(key: string) {
    return new Promise((res, rej) => {
      this.common.canUseNative().then((isnative: boolean) => {
        if(isnative) {
          this.nativeStorage.getItem(key).then((value: string) => {
            res(value);
          }).catch(e => {
            rej(e);
          });
        } else {
          this.storage.create();
          this.storage.get(key).then((value: any) => {
            res(value);
          }).catch(e => {
            rej(e);
          });
        }
      });
    });
  }

  setItem(key: string, value: any) {
    return new Promise((res, rej) => {
      this.common.canUseNative().then((isnative: boolean) => {
        if(isnative) {
          this.nativeStorage.setItem(key, value).then((result:any) => {
            res(result);
          }).catch(e => {
            rej(e);
          });
        } else {
          this.storage.set(key, value).then((value: any) => {
            res(value);
          }).catch(e => {
            rej(e);
          });
        }
      });
    });
  }

  removeItem(key: string) {
    return new Promise((res, rej) => {
      this.common.canUseNative().then((isnative: boolean) => {
        if(isnative) {
          this.nativeStorage.remove(key).then((result:any) => {
            res(result);
          }).catch(e => {
            rej(e);
          });
        } else {
          this.storage.remove(key).then((value: any) => {
            res(value);
          }).catch(e => {
            rej(e);
          });
        }
      });
    });
  }

  clearAll() {
    return new Promise((res, rej) => {
      this.common.canUseNative().then((isnative: boolean) => {
        if(isnative) {
          this.nativeStorage.clear().then((result:any) => {
            res(result);
          }).catch(e => {
            rej(e);
          });
        } else {
          this.storage.clear().then((value: any) => {
            res(value);
          }).catch(e => {
            rej(e);
          });
        }
      });
    });
  }

  public getToken(): any {
    return new Promise((res, rej) => {
      this.common.canUseNative().then((isnative: boolean) => {
        if(isnative) {
          this.nativeStorage.getItem('soto').then((value: string) => {
            res(value);
          }).catch(e => {
            rej(e);
          });
        } else {
          this.storage.get('soto').then((value: any) => {
            res(value);
          }).catch(e => {
            rej(e);
          });
        }
      });
    });
  }

  public setToken(token: string | null) {
    return new Promise((res, rej) => {
      if (token == null) {
        this.removeItem('soto').then((token: any) => {
          res(token);
        }).catch(e => {
          rej('');
        });
      } else {
        this.setItem('soto', token).then((token: any) => {
          res(token);
        }).catch(e => {
          rej('');
        });
      }
    });
  }

  public getValidity(): any {
    return new Promise((res, rej) => {
      this.common.canUseNative().then((isnative: boolean) => {
        if(isnative) {
          this.nativeStorage.getItem('sotov').then((value: string) => {
            res(value);
          }).catch(e => {
            rej(e);
          });
        } else {
          this.storage.get('sotov').then((value: any) => {
            res(value);
          }).catch(e => {
            rej(e);
          });
        }
      });
    });
  }

  public setValidity(token: string | null) {
    return new Promise((res, rej) => {
      if (token == null) {
        this.removeItem('sotov').then((token: any) => {
          res(token);
        }).catch(e => {
          rej('');
        });
      } else {
        this.setItem('sotov', token).then((token: any) => {
          res(token);
        }).catch(e => {
          rej('');
        });
      }
    });
  }
}
