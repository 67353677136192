import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-knob-slider',
  template: `
    <div class="knob-container" [class.resolved]="isResolved">
      <span class="holder">{{ message }}</span>
      <div class="knob" (click)="toggleResolved()">
        <!-- <img
          src="../../../../assets/images/icons/right-arrow.svg"
          alt="arrow"
          class="arrow"
        /> -->
      </div>
    </div>
  `,
  styles: [
    `
      .knob-container {
        position: relative;
        width: 100%;
        height: 50px;
        background-color: #e9e4dd;
        border-radius: 30px;
        /* overflow: hidden; */
      }

      .resolved .holder {
        opacity: 1;
      }

      .holder {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        place-content: center;
        align-items: center;
        overflow: hidden;
        background: transparent;
        font-size: 12px;
        opacity: 0.4;
        font-weight: 300;
      }

      .knob {
        position: absolute;
        top: 0;
        height: 100%;
        cursor: pointer;
        width: 50px;
        background-color: #94b3b4;
        border-radius: 30px;
        box-shadow: 0 0 10px rgb(148 179 180 / 49%);
        transition: transform 0.2s ease-out;
        display: flex;
        place-content: center;
        align-items: center;
        font-size: 14px;
        padding: 10px;
        color: #fff;
        transform: translateX(0);
      }

      .resolved .knob {
        left: calc(100% - 50px);
        background : #94b3b4;
        animation: ani 0.7s linear;
      }

      .arrow {
        height: 20px;
      }

      @keyframes ani {
        0% {
          left: calc(0px);
        background-color: #ecd73c;
          transform: scale(1);
        }
        25% {
          left: calc(25% - 50px);
    background-color: #d8c540;
          transform: scale(1);
        }
        50% {
          left: calc(50% - 50px);
    background-color: #bca445;
          transform: scale(1);
        }
        75% {
          left: calc(75% - 50px);
    background-color: #a28e4a;
          transform: scale(1);
        }
        100% {
          left: calc(100% - 50px);
    background-color: #94b3b4;
          transform: scale(1);
        }
      }

      @media (max-width: 575.98px) {
        .holder {
          padding-right: 15px;
          place-content: end;
          font-size: 11px;
        }
      }
    `,
  ],
})
export class KnobSliderComponent {
  
  @Input() isResolved: boolean = false;
  @Output() resolved = new EventEmitter<boolean>();

  message: string = 'Tap to mark as Resolved';
  toResolveMessage: string = 'Tap to mark as Resolved';
  resolvedMessage: string = 'Resolved';

  toggleResolved(): void {
    this.isResolved = !this.isResolved;
    this.message = this.isResolved ? this.resolvedMessage : this.toResolveMessage;
    setTimeout(() => {
      this.resolved.emit(this.isResolved);
    }, 1000);
  }

  get knobPosition(): string {
    return this.isResolved ? 'calc(100% - 50px)': '0';
  }
}







// import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
// import * as Hammer from 'hammerjs';

// @Component({
//   selector: 'app-knob-slider',
//   template: `
//     <div class="knob-container" #knobContainer>
//       <span class="holder">{{ message }}</span>
//       <div class="knob" #knob>
//           <img src="../../../../assets/images/icons/right-arrow.svg" alt="arrow" class="arrow">
//       </div>
//     </div>
//   `,
//   styles: [
//     `
//       .knob-container {
//         position: relative;
//         width: 100%;
//         height: 50px;
//         background-color: #e9e4dd;
//         border-radius: 30px;
//         /* overflow: hidden; */
//       }

//       .holder {
//         position: absolute;
//         width: 100%;
//         height: 100%;
//         display: flex;
//         place-content: center;
//         align-items: center;
//         overflow: hidden;
//         background: transparent;
//         font-size: 12px;
//         opacity: 0.4;
//         font-weight: 300;
//       }

//       .knob {
//         position: absolute;
//         top: 0;
//         left: 0;
//         height: 100%;
//         cursor: pointer;
//         width: 50px;
//         background-color: #94b3b4;
//         border-radius: 30px;
//         box-shadow: 0 0 10px rgb(148 179 180 / 49%);
//         transition: transform 0.2s ease-out;
//         display: flex;
//         place-content: center;
//         align-items: center;
//         font-size: 14px;
//         padding: 10px;
//         color: #fff;
//       }
//       @media (max-width: 575.98px) {
//         .holder {
//           padding-right: 15px;
//           place-content: end;
//           font-size: 11px;
//         }
//       }
//     `,
//   ],
// })
// export class KnobSliderComponent {
//   @ViewChild('knobContainer') knobContainerRef!: ElementRef<HTMLDivElement>;
//   @ViewChild('knob') knobRef!: ElementRef<HTMLDivElement>;

//   @Input() isResolved: boolean = false;

//   @Output() resolved = new EventEmitter<any>();

//   private hammer!: HammerManager;
//   private isDragging = false;
//   private startPosition = 0;

//   message: String = "Swipe to mark as Resolved";

//   toResolveMessage: String = "Swipe to mark as Resolved";

//   resolvedMessage: String = "Resolved";

//   ngOnInit(){
//     if(this.isResolved) {
//       this.message = this.resolvedMessage;
//     }
//   }

//   ngAfterViewInit(): void {
//     this.hammer = new Hammer(this.knobRef.nativeElement);
//     this.hammer.add(new Hammer.Pan({ direction: Hammer.DIRECTION_HORIZONTAL }));

//     this.hammer.on('panstart', this.handlePanStart.bind(this));
//     this.hammer.on('panmove', this.handlePanMove.bind(this));
//     this.hammer.on('panend', this.handlePanEnd.bind(this));
//   }

//   ngOnDestroy(): void {
//     this.hammer.destroy();
//   }

//   private handlePanStart(event: HammerInput): void {
//     this.isDragging = true;
//     this.startPosition = this.getKnobPosition();
//   }

//   private handlePanMove(event: HammerInput): void {
//     if (this.isDragging) {
//       const distance = event.deltaX;
//       const newPosition = Math.max(0, this.startPosition + distance);
//       const containerWidth = this.knobContainerRef.nativeElement.offsetWidth;
      
//       if (newPosition >= (containerWidth - this.knobRef.nativeElement.offsetWidth) / 2) {
//         this.message = this.resolvedMessage;
//       } else {
//         this.message = this.toResolveMessage;
//       }

//       if (newPosition >= containerWidth - this.knobRef.nativeElement.offsetWidth) {
//         this.isDragging = false;
//         this.isResolved = true;
//         // this.resolved.emit();
//         this.setKnobPosition(containerWidth - this.knobRef.nativeElement.offsetWidth);
//       } else {
//         this.setKnobPosition(newPosition);
//       }
//     }
//   }

//   private handlePanEnd(event: HammerInput): void {
//     this.isDragging = false;

//     const distance = event.deltaX;
//     const newPosition = Math.max(0, this.startPosition + distance);
//     const containerWidth = this.knobContainerRef.nativeElement.offsetWidth;

//     // const knobPosition = this.getKnobPosition();
//     // const containerWidth = this.knobContainerRef.nativeElement.offsetWidth;

//     // console.log("Knob Position : " + knobPosition)
//     // console.log("container-widthd : " + containerWidth)
//     // console.log("Knob offset : " + this.knobRef.nativeElement.offsetWidth)

//     if (newPosition >= containerWidth - this.knobRef.nativeElement.offsetWidth) {
//       // this.resolved.emit();
//       this.isResolved = true;
//       this.resolved.emit(true);
//     } else {
//       this.setKnobPosition(0);
//       // this.resolved.emit(false);
//       this.isResolved = false;
//     }
//   }

//   private getKnobPosition(): number {
//     return this.knobRef.nativeElement.offsetLeft;
//   }

//   private setKnobPosition(position: number): void {
//     this.knobRef.nativeElement.style.transform = `translateX(${position}px)`;
//   }
// }