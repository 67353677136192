import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Event, NavigationStart, Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
// import { MusicControls } from '@awesome-cordova-plugins/music-controls/ngx';
import { ApiService } from 'src/app/shared/services/api.service';
import { UserService } from 'src/app/shared/services/user.service';
import { environment } from 'src/environments/environment';
import { CommonService } from 'src/app/shared/services/common.service';
import { CurriculumService } from 'src/app/shared/services/curriculum.service';
declare var jwplayer: any;

export interface ContemplationOptions {
  contemplations: any,
  contemplation: any,
  index: number
}
@Component({
  selector: 'app-contemplation-flow',
  templateUrl: './contemplation-flow.component.html',
  styleUrls: ['./contemplation-flow.component.scss'],
})
export class ContemplationFlowComponent implements OnInit {

  // @Output('close') close: EventEmitter<any> = new EventEmitter();
  @Output('markCompleted') markCompleted: EventEmitter<any> = new EventEmitter();
  @Output('toggleFavorite') triggerToggleFavorite: EventEmitter<any> = new EventEmitter();
  @Input('isFavorite') isFavorite ?: boolean = false;
  @Input('options') options?: any;
  @Input('contents') contents?: any = [];
  @Input('progress') progress?: any = {};

  previousContentOptions?: any | ContemplationOptions;
  nextContentOptions?: any | ContemplationOptions;

  loadingContent: boolean = true;
  completing: boolean = false;
  loading: boolean = false;

  currentContemplationIndex: any;

  previousContentTitle: string = "Previous";
  nextContentTitle: string = "Next";

  hasPrevious: boolean = false;
  hasNext: boolean = false;

  state: any;

  constructor(
    public audioService: CurriculumService,
    public modal: ModalController,
    public apiService: ApiService,
    public snackbar: MatSnackBar,
    public router: Router,
    public userService: UserService,
    public common: CommonService,
    // public musicControls: MusicControls
  ) {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        // Show loading indicator
        this.stop();
      }
    });
  }

  ngOnInit(): void {
    this.audioService.getState().subscribe(t => {
      this.state = t
    });

    // this.common.canUseNative().then((isNative: boolean) => {
    //   if (isNative) {
    //     this.musicControls.subscribe().subscribe(action => {

    //       const message = JSON.parse(action).message;
    //       switch (message) {
    //         case 'music-controls-next':
    //           // Do something
    //           break;
    //         case 'music-controls-previous':
    //           // Do something
    //           break;
    //         case 'music-controls-pause':
    //           this.pause();
    //           // Do something
    //           break;
    //         case 'music-controls-play':
    //           this.resume();
    //           // Do something
    //           break;
    //         case 'music-controls-destroy':
    //           this.stop();
    //           break;

    //         // External controls (iOS only)
    //         case 'music-controls-toggle-play-pause':
    //           // Do something
    //           if (this.state?.paused || this.state?.canplay) {
    //             this.resume();
    //           } else {
    //             this.pause();
    //           }
    //           break;
    //         case 'music-controls-seek-to':
    //           const seekToInSeconds = JSON.parse(action).position;
    //           this.musicControls.updateElapsed({
    //             elapsed: seekToInSeconds,
    //             isPlaying: true
    //           });
    //           // Do something
    //           break;
    //         case 'music-controls-skip-forward':
    //           // Do something
    //           break;
    //         case 'music-controls-skip-backward':
    //           // Do something
    //           break;

    //         // Headset events (Android only)
    //         // All media button events are listed below
    //         case 'music-controls-media-button':
    //           // Do something
    //           break;
    //         case 'music-controls-headset-unplugged':
    //           // Do something
    //           break;
    //         case 'music-controls-headset-plugged':
    //           // Do something
    //           break;
    //         default:
    //           break;
    //       }
    //     });
    //   }
    // });

    // this.musicControls.listen(); // activates the observable above

    this.contents.forEach((content: any, index: any) => {
      if (content.GuidedContemplationId == this.options?.GuidedContemplationId) {
        this.currentContemplationIndex = index;
        this.setContent();
      }
    });
  }

  ngOnDestroy() {
    this.audioService.pause();
    this.common.canUseNative().then((isNative: boolean) => {
      if (isNative) {
        // this.musicControls.destroy();
      }
    });
  }
  
  setContent() {
    this.loadingContent = true;
    this.audioService.stop();
    this.common.canUseNative().then((isNative: boolean) => {
      if (isNative) {
        // this.musicControls.destroy();
      }
    });

    setTimeout(() => {
      this.loadingContent = false;
      switch (this.options.Type) {
        case "AUDIO":
          this.openAudioFile(this.options);
          break;
      }
    }, 100);
  }

  previousContent() {
    if (this.currentContemplationIndex > 0) {
      this.currentContemplationIndex--;
      var base = '/contemplations/'+ this.contents[this.currentContemplationIndex].GuidedContemplationId;
      this.router.navigate([base]);
      // this.setContent();
    }
  }

  nextContent() {
    if (this.currentContemplationIndex < this.contents.length - 1) {
      this.currentContemplationIndex++;
      var base = '/contemplations/'+ this.contents[this.currentContemplationIndex].GuidedContemplationId;

      this.router.navigate([base]);
    }
  }

  //Play Audio File
  openAudioFile(t: any) {
    this.userService.getToken().then((token: any) => {
      this.loadStream(this.apiService.domain + this.apiService.apis.streamAudioContemplation + t.GuidedContemplationId + '?s=' + btoa(token));
    });
  }

  //Mark the content as completed or watched.
  markContentAsComplete(content: any) {
    if(this.completing){
      return false;
    }
    this.completing = true;
    this.apiService.contemplationCompleted(content.GuidedContemplationId).then((res: any) => {
      if (res.success) {
        this.completing = false;
        this.progress[res.data.guidedContemplationMeta.GuidedContemplationId] = res.data.guidedContemplationMeta;
        this.markCompleted.emit(res.data.guidedContemplationMeta);
        // this.contentProgress[res.data.contentMeta.ContentId] = res.data.contentMeta;
        // if (res.data.programLessonMeta) this.lessonProgress[res.data.programLessonMeta.ProgramLessonId] = res.data.programLessonMeta;
        // if (res.data.programChapterMeta) this.chapterProgress[res.data.programChapterMeta.ProgramChapterId] = res.data.programChapterMeta;
      } else {
        this.completing = false;
        this.snackbar.open("Unable to update your progress");
      }
    }).catch(e => {
      this.completing = false;
    });
  }

  //Get the video file url
  getVideoFile(content: any) {
    return environment.storagedomain + content.Url + environment.sas;
    // return this.apiService.domain + this.apiService.apis.streamVideoContent + content.ContentId + '?s=' + btoa(this.userService.getToken());
  }

  //Standard Audio Service Controls
  pause() {
    this.audioService.pause();
    this.common.canUseNative().then((isNative: boolean) => {
      if (isNative) {
        // this.musicControls.updateIsPlaying(false);
      }
    });
  }
  resume() {
    this.audioService.play();
    this.common.canUseNative().then((isNative: boolean) => {
      if (isNative) {
        // this.musicControls.create({
        //   track: this.options.Title,
        //   artist: 'Contemplate',
        //   isPlaying: true,
        //   dismissable: false,
        //   hasPrev: false,      // show previous button, optional, default: true
        //   hasNext: false,      // show next button, optional, default: true
        //   hasClose: false,       // show close button, optional, default: false

        //   hasSkipForward: false,  // show skip forward button, optional, default: false
        //   hasSkipBackward: false, // show skip backward button, optional, default: false
        //   hasScrubbing: false, // enable scrubbing from control center and lockscreen progress bar, optional

        //   // Android only, optional
        //   // text displayed in the status bar when the notification (and the ticker) are updated, optional
        //   ticker: this.options.Title,
        //   // All icons default to their built-in android equivalents
        //   playIcon: 'round_play_circle_black_48',
        //   pauseIcon: 'round_pause_circle_black_48',
        //   notificationIcon: 'ic_stat_app_icon',
        //   // prevIcon: 'media_prev',
        //   // nextIcon: 'media_next',
        //   // closeIcon: 'media_close',
        // });
      }
    });
  }
  stop() {
    this.audioService.stop();
    this.common.canUseNative().then((isNative: boolean) => {
      if (isNative) {
        // this.musicControls.destroy();
      }
    });
  }
  onSliderChangeEnd(t: any) {
    this.audioService.seekTo(t.value);
  }
  onVolumeChange(t: any) {
    this.audioService.setVolume(t.value)
  }

  loadStream(url: any) {
    this.audioService.loadStream(url, this.options).subscribe((t: any) => {
      var timeRemaining = 0;
      if (t.path && t.path.length > 0) {
        timeRemaining = t.path[0].duration - t.path[0].currentTime;
      } else if (t.target && t.target.duration) {
        timeRemaining = t.target.duration - t.target.currentTime;
      }
      
      if (t.type == "ended" || (t.type == "timeupdate" && this.state.playing && timeRemaining < 15)) {
        console.log("Content Completion called");
        this.markContentAsComplete(this.options);
      }
    });
  }

  playStream(url: any) {
    // this.common.canUseNative().then((isNative: boolean) => {
    //   if (isNative) {
    //     this.musicControls.create({
    //       track: this.options.Title,
    //       artist: 'Contemplate',
    //       isPlaying: true,
    //       dismissable: false,
    //       hasPrev: false,      // show previous button, optional, default: true
    //       hasNext: false,      // show next button, optional, default: true
    //       hasClose: false,       // show close button, optional, default: false

    //       hasSkipForward: false,  // show skip forward button, optional, default: false
    //       hasSkipBackward: false, // show skip backward button, optional, default: false
    //       hasScrubbing: false, // enable scrubbing from control center and lockscreen progress bar, optional
    //       playIcon: 'round_play_circle_black_48',
    //       pauseIcon: 'round_pause_circle_black_48',
    //       notificationIcon: 'ic_stat_app_icon',
    //     });
    //   }
    // });
    this.audioService.playStream(url, this.options).subscribe((t: any) => {
      var timeRemaining = 0;
      if (t.path && t.path.length > 0) {
        timeRemaining = t.path[0].duration - t.path[0].currentTime;
      } else if (t.target && t.target.duration) {
        timeRemaining = t.target.duration - t.target.currentTime;
      }
      console.log(t);
      if (t.type == "ended" || (t.type == "timeupdate" && this.state.playing && timeRemaining < 15)) {
        console.log("Content Completed");
        this.markContentAsComplete(this.options);
      }
    });
  }
  
  closeDialog() {
    this.audioService.stop();
    this.common.canUseNative().then((isNative: boolean) => {
      if (isNative) {
        // this.musicControls.destroy();
      }
    });
    this.audioService.resetState();
    this.modal.dismiss();
    // this.close.emit();
  }
  

  toggleFavorite() {
    this.triggerToggleFavorite.emit();
  }
}
