
<div class="currentContent-section beige-space flex-1">
    <div class="row m-0 dflex h-100">
        <div class="col-md-12 col-12 h-100 currentContent p-0">
            <ng-container *ngIf="!loadingContent">

                <div class="beige-space w-100 content-space text-left">
                    <div class="content-container">
                        <ng-container *ngIf="options.Type == 'AUDIO'">
                            <div class="media-footer">
                                <mat-toolbar>
                                    <mat-toolbar-row class="toolbar-row" cols="1" style="padding: 70px 0px !important;">
                                      <p class="text-center mb-0" *ngIf="state.seeking">
                                          <span class="spinner-border spinner-border" role="status"
                                              aria-hidden="true"></span>
                                      </p>
                                      <ng-container *ngIf="!state.seeking">
                                        <button mat-icon-button color="primary" class="play-icons"
                                            (click)="state?.paused || state?.canplay ? resume() : openAudioFile(options)"
                                            [disabled]="state?.loading" *ngIf="!state?.playing">
                                            <mat-icon mat-list-icon color="primary">play_circle_filled
                                            </mat-icon>
                                        </button>
                                        <button mat-icon-button color="primary" (click)="pause()" class="play-icons"
                                            [disabled]="state?.loading" *ngIf="state?.playing">
                                            <mat-icon mat-list-icon color="primary">pause_circle_filled
                                            </mat-icon>
                                        </button>
                                      </ng-container>
                                    </mat-toolbar-row>
                                    <mat-toolbar-row class="toolbar-row p-0" cols="1">

                                        <div class="slider-container" *ngIf="(state?.loading)">
                                            <p class="text-center mb-0">
                                                <span class="spinner-border spinner-border-lg" role="status"
                                                    aria-hidden="true"></span>
                                            </p>
                                        </div>
                                        <div class="slider-container"
                                            *ngIf="(state?.playing || state?.paused || state?.canplay) && !state?.loading">
                                            {{ state?.readableCurrentTime }}
                                            <mat-slider class="time-slider" min="0" color="primary"
                                                [max]="state?.duration" step="1"
                                                [value]="state?.currentTime"
                                                (input)="onSliderChangeEnd($event)"
                                                [disabled]="state?.error">
                                            </mat-slider>
                                            {{ state?.readableDuration }}
                                        </div>
                                    </mat-toolbar-row>
                                    <mat-toolbar-row class="toolbar-row p-0" cols="1">

                                        <button mat-icon-button (click)="toggleFavorite()" color="primary">
                                            <ion-icon name="heart" *ngIf="isFavorite" style="font-size:32px;" color="danger"></ion-icon>
                                            <ion-icon name="heart-outline" style="font-size:32px;" color="danger" *ngIf="!isFavorite"></ion-icon>
                                            <!-- <mat-icon *ngIf="isFavorite">favorite</mat-icon>
                                            <mat-icon *ngIf="!isFavorite">favorite_border</mat-icon> -->
                                        </button>

                                    </mat-toolbar-row>
                                </mat-toolbar>
                            </div>
                            <!-- <p class="actions">
                                <mat-chip-list class="align-right">
                                    <mat-chip *ngIf="isFavorite" (click)="toggleFavorite()" color="primary" class="text-white" selected>
                                        <ion-icon name="heart" class="text-white" style="margin-right:5px;"></ion-icon>
                                        Saved in your favorites
                                    </mat-chip>
                                    <mat-chip *ngIf="!isFavorite" (click)="toggleFavorite()" class="bg-white">
                                        <ion-icon name="heart-outline"  style="margin-right:5px;"></ion-icon> Favorite Contemplation
                                    </mat-chip>
                                </mat-chip-list>
                            </p> -->
                            <p class="description" *ngIf="options.Text != ''">{{ options.Text }}
                            </p>
                        </ng-container>
                    </div>
                </div>
                <div
                    class="row action-button ml-0 mr-0 pt-2 pb-2 mt-3 mb-0 flex justify-between beige-space m-0" style="box-shadow: 0px -1px 8px 0px #d0caca;">
                    <div class="col-6">
                        <app-back-btn class="dflex" *ngIf="currentContemplationIndex > 0" highlight="lillac"
                            [addmargin]="false" (click)="previousContent()">
                            PREVIOUS
                        </app-back-btn>
                    </div>
                    <div class="col-6 text-right">
                        <app-adios-btn class="dflex  place-end"
                            *ngIf="currentContemplationIndex < contents.length - 1" highlight="lillac"
                            [addmargin]="false" [addmarginleft]="false" (click)="nextContent()">
                            NEXT
                        </app-adios-btn>
                    </div>
                </div>
            </ng-container>
            <div class="align-items-center row" *ngIf="loadingContent">
                <div class="col-md-12 text-center p-5">
                    <div class="spinner-border"></div>
                </div>
            </div>
        </div>
    </div>
</div>