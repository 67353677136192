// src/app/auth/token.interceptor.ts
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse
} from '@angular/common/http';
import { Observable, BehaviorSubject, Subject } from "rxjs";
import { UserService } from './user.service';
import { tap } from 'rxjs/operators';
// import { Observable } from 'rxjs/Observable';
@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(public userService: UserService) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    var d = new Date()
    var timezoneOffset = d.getTimezoneOffset();
    // Get cookies from the document
    const cookies = document.cookie;

    request = request.clone({
      setHeaders: {
        Authorization: `Bearer ${this.userService.getToken()}`,
        offset: timezoneOffset.toString()
      },
      // withCredentials: true
    });
    // Clone the request and add the cookies to the headers
    // request = request.clone({
    //   headers: request.headers.set('Cookie', cookies || ''),
    // });


    // Handle the modified request
    // return next.handle(request).pipe(
    //   tap((event) => {
    //     // Check if the response is an HTTP response
    //     // if (event instanceof HttpResponse) {
    //     //   // Extract and save the cookies from the response
    //     //   const responseCookies = event.headers.getAll('Set-Cookie');
    //     //   console.log(event);
    //     //   console.log(responseCookies);
    //     //   if (responseCookies) {
    //     //     responseCookies.forEach((cookie) => {
    //     //       document.cookie = cookie;
    //     //     });
    //     //   }
    //     // }
    //   })
    // );
    
    return next.handle(request);
  }
}