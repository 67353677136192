<div class="align-items-center row" *ngIf="loadingContent">
    <div class="col-md-12 text-center p-5">
        <div class="spinner-border"></div>
    </div>
</div>


<div class="col-md-12 content pt-4" *ngIf="!loadingContent">
    <div class="grey-space w-100 login-form text-left">
        <ng-container *ngFor="let question of profileGroup.ProfileQuestions; let j = index">
            <!-- <p class="pb-4" *ngIf="question.Question" style="border-bottom: 1px solid #eee;" [innerText]="question.Question"></p> -->
            <!-- <ng-container *ngIf="question.Title"> -->
                <mat-label class="mt-4 mb-4 dflex place-start" style="opacity: 0.7;" [innerText]="question.Question"></mat-label>
                <mat-form-field class="w-100 mb-4" appearance="outline" *ngIf="question.Type == 'TEXT'">
                    <textarea matInput class="contemplation-space" style="resize: none; min-height: 150px;" rows="5"
                        name="question_{{ j }}" [disabled]="updatingAnswer"
                        [placeholder]="question.Placeholder"
                        (blur)="updateAnswer($event, question.ProfileQuestionId, answers[question.ProfileQuestionId])"
                        (ngModelChange)="answerChanged($event)"
                        [(ngModel)]="answers[question.ProfileQuestionId].Explanation"></textarea>
                    <mat-hint *ngIf="answers[question.ProfileQuestionId].Explanation != originalAnswers[question.ProfileQuestionId].Explanation
                    && !updatingAnswer">
                        Not yet saved. Please click outside the box to save.</mat-hint>
                    <mat-hint *ngIf="answers[question.ProfileQuestionId].Explanation != originalAnswers[question.ProfileQuestionId].Explanation
                    && updatingAnswer">Saving...</mat-hint>
                </mat-form-field>
                <mat-selection-list *ngIf="question.Type == 'MCQ'"
                (ngModelChange)="answerChanged($event, true, question.ProfileQuestionId, answers[question.ProfileQuestionId])"
                [(ngModel)]="answers[question.ProfileQuestionId].Explanation">
                    <mat-list-option *ngFor="let option of question.Options" checkboxPosition="before"
                        [value]="option.Text">{{ option.Text }}</mat-list-option>
                </mat-selection-list>
            <!-- </ng-container> -->
        </ng-container>

    </div>
</div>