import { Component, Input, OnInit } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ApiService } from 'src/app/shared/services/api.service';

@Component({
  selector: 'sb-profiling-questions',
  templateUrl: './profiling-questions.component.html',
  styleUrls: ['./profiling-questions.component.scss']
})
export class ProfilingQuestionsComponent implements OnInit {

  @Input('contentId') contentId: any;
  @Input('programParticipantId') programParticipantId: any;
  loadingContent: boolean = true;
  profileGroup: any;
  answers: any = {};
  originalAnswers: any = {};

  updatingAnswer: any;
  isAnswerModified: boolean = false;

  constructor(private apiService: ApiService, private snackbar: MatSnackBar) { }

  ngOnInit(): void {
    this.getContentQuestions();
  }

  getContentQuestions() {
    this.loadingContent = true;
    this.apiService.getProfileQuestions(this.contentId, this.programParticipantId).then((res: any) => {
      if (res.data) {
        this.profileGroup = res.data.profileGroup;
        this.answers = res.data.answers;
        var x = 0;
        this.profileGroup.ProfileQuestions.forEach((question: any) => {
          if (!this.answers[question.ProfileQuestionId.toString()]) {
            this.answers[question.ProfileQuestionId.toString()] = {};
          }

          try {  
            this.answers[question.ProfileQuestionId].Explanation = JSON.parse(this.answers[question.ProfileQuestionId].Explanation);  
          } catch (e) {  
            console.log('invalid json');  
          }
          x++;
          if(x == this.profileGroup.ProfileQuestions.length) {
            this.originalAnswers = JSON.parse(JSON.stringify(this.answers));
            this.loadingContent = false;
          }
        });

      } else {
        this.loadingContent = false;
      }
    }).catch(e => {
      if (!e.msg) {
        e.msg = "Something went wrong";
      }
      this.snackbar.open(e.msg);
      this.loadingContent = false;
    });
  }

  updateAnswer(event: any, profileQuestionId: any, answer: any) {
    if (this.isAnswerModified) {
      this.updatingAnswer = true;

      var data = answer;
      if (!answer.ProfileQuestionMetaId) {
        data = {
          ProgramParticipantId: this.programParticipantId,
          Explanation: answer.Explanation,
          ProfileQuestionId: profileQuestionId,
        };
      }
      data.User = undefined;
      this.apiService.updateProfileQuestionMeta(data).then((res: any) => {
        try {  
          res.data.Explanation = JSON.parse(res.data.Explanation);  
        } catch (e) {  
          console.log('invalid json');  
        }

        this.answers[profileQuestionId] = res.data;
        this.originalAnswers[profileQuestionId] = res.data;

        this.isAnswerModified = false;
        this.updatingAnswer = false;
        this.snackbar.open("Saved");
      }).catch(e => {
        this.updatingAnswer = false;
        this.isAnswerModified = true;
      });
    }
  }
  answerChanged(event: any, shouldSave? :boolean,  profileQuestionId?: any, answer?: any) {
    console.log(event);
    this.isAnswerModified = true;
    if(shouldSave) {
      var data = JSON.parse(JSON.stringify(answer));
      data.Explanation = JSON.stringify(event);
      console.log(data);
      this.updateAnswer(event, profileQuestionId, data);
    }
  }

}
