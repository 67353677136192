<ion-content class="ion-padding">
    <div class="fullscreen beige-space">
        <div class="box">
            <div class="back" (click)="onNoClick()">
                <mat-icon>close</mat-icon>
            </div>
            <h1 class="title flex">Enroll using an Affiliation Code
                <div class="ml-auto align-self-end pr-4"></div>
            </h1>


            <div class="currentContent-section grey-space flex-1">
                <form autocomplete="off" class="grey-space login-form" [formGroup]="form" (ngSubmit)="submitPassword()">
                    <mat-label class="mt-4 mb-4 dflex place-start" style="opacity: 0.7;">Enter your affiliation code</mat-label>
                    <mat-form-field  class="w-100 mb-4"  appearance="outline">
                        <input matInput formControlName="JoiningCode" type="text" [disabled]="loading"
                            placeholder="Enter your affiliation code">
                    </mat-form-field>
                    <div class="remember-me">
                        <div class="error-label" *ngIf="error">
                            <mat-error>{{ error }}</mat-error>
                        </div>
                    </div>

                    <div class="action mt-4 text-center">
                        <app-adios-btn class="action-button" highlight="teal" [addmargin]="false"
                            [addmarginleft]="false" (click)="submitPassword()">
                            <span *ngIf="loading" class="spinner-border spinner-border-sm" role="status"
                                aria-hidden="true"></span>&nbsp;
                            <span *ngIf="loading" class="sr-only">Loading...</span>
                            <span *ngIf="!loading">Join now</span>
                        </app-adios-btn>
                    </div>
                </form>
            </div>
        </div>
    </div>
</ion-content>