<div class="dialog-box bg-grey">
  <h1>Congratulations! Your Week 1 starts today.</h1>
  <div class="dialog-content">
    <p class="mt-4">Your program content will unlock on a daily basis, one day at a time, over the next 8 weeks.</p>
  </div>
  <div class="action mt-4 text-center">
    <app-adios-btn class="action-button" highlight="teal" [addmargin]="false" [addmarginleft]="false"
    (click)="onPositiveClick()">
      Start Week 1
    </app-adios-btn>
  
  </div>
</div>