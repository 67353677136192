import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import * as Hammer from 'hammerjs';

@Component({
  selector: 'app-situation-card',
  templateUrl: './situation-card.component.html',
  styleUrls: ['./situation-card.component.scss']
})
export class SituationCardComponent implements OnInit {

  @Input() situation?: any;
  @Output() analyzeSituation: EventEmitter<any> = new EventEmitter(); 

  @ViewChild('card') card: ElementRef;
  hammerManager: any;
  swipeBox: HTMLElement;
  startX: number;
  boxWidth: number;
  
  dateOffset: any;
  
  constructor() {
    var offset = new Date().getTimezoneOffset();
    if (offset > 0) {
      this.dateOffset = "-" + ('0' + Math.floor((offset / 60))).slice(-2) + "" + ('0' + Math.floor((offset % 60)).toFixed(0)).slice(-2);
    } else {
      this.dateOffset = "+" + ('0' + Math.floor(((offset * -1) / 60)).toFixed(0)).slice(-2) + "" + ('0' + Math.floor(((offset * -1) % 60)).toFixed(0)).slice(-2);
    }
  }

  ngOnInit() {
    setTimeout(() => {
      
    this.hammerManager = new Hammer.Manager(this.card.nativeElement);
    this.hammerManager.add(new Hammer.Pan({ direction: Hammer.DIRECTION_HORIZONTAL, threshold: 0 }));

    this.swipeBox = this.card.nativeElement.querySelector('.swipe-box');
    this.boxWidth = this.swipeBox.offsetWidth;

    this.hammerManager.on('panstart', (event: any) => {
      this.startX = event.center.x - this.card.nativeElement.offsetLeft;
      this.swipeBox.style.transition = '';
    });

    this.hammerManager.on('panmove', (event: any) => {
      const pos = event.center.x - this.card.nativeElement.offsetLeft - this.startX;
      if (pos >= 0 && pos <= this.boxWidth) {
        this.swipeBox.style.transform = `translateX(${pos}px)`;
      }
    });

    this.hammerManager.on('panend', () => {
      this.swipeBox.style.transition = 'transform 0.3s ease-out';
      if (this.swipeBox.offsetLeft >= this.boxWidth * 0.9) {
        this.resolveSituation();
      }
      this.swipeBox.style.transform = '';
    });
    }, 100);

    
  }

  onPan(event: any) {
    console.log(event);
  }

  analyseSituation(situation) {
    this.analyzeSituation.emit(situation);
  }

  resolveSituation() {
    // Code to mark the situation as resolved goes here
  }

}
