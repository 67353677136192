import { Component, Input, OnInit } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ApiService } from '../../services/api.service';

@Component({
  selector: 'sb-profile-questions',
  templateUrl: './profile-questions.component.html',
  styleUrls: ['./profile-questions.component.scss']
})
export class ProfileQuestionsComponent implements OnInit {

  @Input() profileGroup: any;
  @Input() answers: any = {};
  loadingContent: boolean = true;
  originalAnswers: any = {};

  updatingAnswer: any;
  isAnswerModified: boolean = false;

  constructor(private apiService: ApiService, private snackbar: MatSnackBar) { }

  ngOnInit(): void {
    this.getContentQuestions();
  }

  getContentQuestions() {
    this.loadingContent = true;
    var x = 0;
    if(this.profileGroup.ProfileQuestions.length > 0) {
      this.profileGroup.ProfileQuestions.forEach((question: any) => {
        if (!this.answers[question.ProfileQuestionId.toString()]) {
          this.answers[question.ProfileQuestionId.toString()] = {};
        }
  
        try {
          this.answers[question.ProfileQuestionId].Explanation = JSON.parse(this.answers[question.ProfileQuestionId].Explanation);
        } catch (e) {
          console.log('invalid json');
        }
        x++;
        if (x == this.profileGroup.ProfileQuestions.length) {
          this.originalAnswers = JSON.parse(JSON.stringify(this.answers));
          this.loadingContent = false;
        }
      });
    }
  }

  updateAnswer(event: any, profileQuestionId: any, answer: any) {
    if (this.isAnswerModified) {
      this.updatingAnswer = true;

      var data = answer;
      if (!answer.ProfileQuestionMetaId) {
        data = {
          Explanation: answer.Explanation,
          ProfileQuestionId: profileQuestionId,
        };
      }
      data.User = undefined;
      this.apiService.updateProfileQuestionMeta(data).then((res: any) => {
        try {
          res.data.Explanation = JSON.parse(res.data.Explanation);
        } catch (e) {
          console.log('invalid json');
        }

        this.answers[profileQuestionId] = res.data;
        this.originalAnswers[profileQuestionId] = res.data;

        this.isAnswerModified = false;
        this.updatingAnswer = false;
        this.snackbar.open("Saved");
      }).catch(e => {
        this.updatingAnswer = false;
        this.isAnswerModified = true;
      });
    }
  }
  answerChanged(event: any, shouldSave?: boolean, profileQuestionId?: any, answer?: any) {
    console.log(event);
    this.isAnswerModified = true;
    if (shouldSave) {
      var data = JSON.parse(JSON.stringify(answer));
      data.Explanation = JSON.stringify(event);
      console.log(data);
      this.updateAnswer(event, profileQuestionId, data);
    }
  }

}
