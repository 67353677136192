import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { ApiService } from '../../services/api.service';
import { AssistantContext, AssistantService, ContemplationRoomSetup, ContextType, Conversation, MessageType, UserAssistantContextType, SystemMessageAction, ProcessActionData, ContemplationRoomConversationKeyValue, ContemplationRoomSetupKeyValue, CreateSituationNextPropertyList, AnalyseNextPropertyList, UserAction } from '../../services/assistant.service';
import { UserService } from '../../services/user.service';
import { List } from 'immutable';
import { KeyValue } from '@angular/common';
import { MatSidenav } from '@angular/material/sidenav';
declare var $: any;
import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
// import * as moment from 'moment';
import { formatDistanceToNow, format } from 'date-fns';


@Pipe({
  name: 'orderPipe',
  pure: false
})
export class OrderPipe implements PipeTransform {
  transform(items: ContemplationRoomSetup[]): any {
    if (!items) {
      return items;
    }
    // filter items array, items which match and return true will be
    // kept, false will be filtered outx
    // return items;
    return items.sort((a, b) => new Date(b.UpdatedOn).getTime() - new Date(a.UpdatedOn).getTime());
  }
}

@Pipe({
  name: 'customDate'
})
export class CustomDatePipe implements PipeTransform {

  transform(time: any, _format?: any): string {
    if (!time || !_format) {
      return null;
    }

    const date = new Date(time);

    if (_format === 'fromNow') {
      const distance = formatDistanceToNow(date, { addSuffix: true });
      if (distance.indexOf('ago') === -1) {
        return distance;
      }
      if (distance.indexOf('day') === -1) {
        return format(date, 'dddd');
      }
      return format(date, 'dd MMM yyyy');
    }

    return format(date, _format);
  }
}

@Component({
  selector: 'sb-assistant-conversation',
  templateUrl: './assistant-conversation.component.html',
  styleUrls: ['./assistant-conversation.component.scss']
})
export class AssistantConversationComponent implements OnInit {


  @ViewChild('messagesList', { static: false }) messagesList?: ElementRef;
  @ViewChild('sidenav') sidenav: MatSidenav;

  @Output() showBadge: EventEmitter<any> = new EventEmitter();
  @Input() contemplationRoom?: KeyValue<string, ContemplationRoomSetup>;;

  createSituationDescriptionPlaceholder = `Did something happen that took away your peace of mind?

Do you have a question you need to find an answer to?`;

  messageType = MessageType;
  contextType = ContextType;
  systemMessageAction = SystemMessageAction;
  createSituationNextPropertyList = CreateSituationNextPropertyList;
  analyseNextPropertyList = AnalyseNextPropertyList;
  userAssistantContextType = UserAssistantContextType;

  loadingPreviousMessages?: boolean = false
  loading: boolean = false;
  loadingRoom: boolean = false;
  sendingTextMessage: boolean = false;
  userAction?: UserAction;  
  dateOffset: any;

  notes: any = [];
  noteTags: any = [];

  textMessage: {
    Text?: string
  } = {};

  username: any;
  conversations: Array<Conversation> = Array<Conversation>();

  currentContext?: AssistantContext;

  processingMessageKey: String;
  processingAction?: boolean = false;
  receivingMessage?: boolean = false;
  requestingMessage?: boolean = false;

  contemplationRoomConversations: ContemplationRoomConversationKeyValue = {};
  contemplationRoomSetups: KeyValue<number, ContemplationRoomSetup>[] = [];

  conversationSubscriptionObservable?: Subscription;
  contextObservable?: Subscription;
  systemActionObservable?: Subscription;
  contemplationRoomSetupObservable?: Subscription;

  settingUp?: boolean = true;

  constructor(
    private apiService: ApiService,
    private assistant: AssistantService,
    private router: Router,
    private user: UserService,
    private snackbar: MatSnackBar
  ) {
    this.dateOffset = this.apiService.getDateOffset();
    this.username = this.user.user.FirstName;
  }

  ngOnInit(): void {

    this.assistant
      .loading
      .subscribe((obs) => {
        this.loadingRoom = obs;
        if (!this.loadingRoom) {
          this.markConversationAsRead();
        }
      });

    this.contextObservable = this.assistant
      .context
      .subscribe((obs) => {
        this.currentContext = obs;
        console.log("This current context", this.currentContext);
        if (this.settingUp) {
          this.settingUp = false;
        }
      });

    this.systemActionObservable = this.assistant.systemAction.subscribe((message: Conversation) => {
      if (message != null) {        
        switch (message.SystemAction) {
          case "openFavorites":
            this.router.navigateByUrl('/favorites');
            break;
          case "reloadSituations":
            //reload situations
            break;
          case "captureDescription":
            break;
          case "analyseNow":
            break;
          case "openRoom":
            console.log("Open Contemplation room");
            if (message.SystemActionParam != null) {
              this.contemplationRoomSetups.forEach((element: KeyValue<number, ContemplationRoomSetup>) => {
                if (element.key == parseInt(message.SystemActionParam)) {
                  this.openContemplationRoom(element);
                }
              });
            }
            break;
        }
      }
    });

    this.contemplationRoomSetupObservable = this.assistant.contemplationRoomSetups.subscribe((contemplationRoomSetups: ContemplationRoomSetupKeyValue) => {

      if(this.contemplationRoom) {
        this.contemplationRoom.value = contemplationRoomSetups[this.contemplationRoom.key];
      }

      this.assistant.sortContemplationRoomSetupKeyValueByUpdatedOnDesc(contemplationRoomSetups).then(r => {
        this.contemplationRoomSetups = r;
        console.log(this.processingAction);
        console.log("contemplation rooms", r);
      });
    });

    this.subscribe();
  }

  ngOnDestroy() {
    console.log("Ng On Destroy Called");
    this.unsubscribe();
  }

  subscribe() {

    if (this.contemplationRoom) {
      this.conversationSubscriptionObservable = this.assistant.contemplationRoomConversations[parseInt(this.contemplationRoom.key)].subscribe((obs) => {

        this.conversations = obs.toArray();
        // console.log(this.conversations);
        this.showBadge.emit(this.conversations);
        if (!this.loadingPreviousMessages) {
          setTimeout(() => {
            this.scrollToBottom();
          }, 100);
        }
        if (this.conversations.length > 0) {
          console.log(this.conversations);
          this.userAction = this.conversations[this.conversations.length - 1].UserAction;
          this.userAction.MessageKey = this.conversations[this.conversations.length - 1].Key;
          if (this.conversations[this.conversations.length - 1].WaitNextMessage) {
            this.receivingMessage = true;
          } else {
            this.receivingMessage = false;
          }
          if (this.conversations[this.conversations.length - 1].RequestNextMessage) {
            this.requestNextMessage();
          } else {
            this.requestingMessage = false;
          }
        }

        this.markConversationAsRead();
      });

    } else {

      //This is zach's main room
      this.conversationSubscriptionObservable = this.assistant.conversations.subscribe((obs) => {
        this.conversations = obs.toArray();
        // console.log(this.conversations);
        this.showBadge.emit(this.conversations);
        if (!this.loadingPreviousMessages) {
          setTimeout(() => {
            this.scrollToBottom();
          }, 100);
        }

        if (this.conversations.length > 0) {
          console.log(this.conversations);
          this.userAction = this.conversations[this.conversations.length - 1].UserAction;
          if (this.conversations[this.conversations.length - 1].WaitNextMessage) {
            this.receivingMessage = true;
          } else {
            this.receivingMessage = false;
          }
          if (this.conversations[this.conversations.length - 1].RequestNextMessage) {
            this.requestNextMessage();
          } else {
            this.requestingMessage = false;
          }
        }

        this.markConversationAsRead();
      });
    }

  }

  markConversationAsRead() {
    if (this.contemplationRoom && this.conversations.length > 0 && !this.loadingRoom) {
      this.assistant.updateLastReadOnContext(this.contemplationRoom.value);
    }

    if (!this.contemplationRoom && this.conversations.length > 0 && !this.loadingRoom) {
      this.assistant.updateLastReadOnContext();
    }
  }

  unsubscribe() {
    this.conversationSubscriptionObservable?.unsubscribe();
    // this.systemActionObservable?.unsubscribe();
    // this.contextObservable?.unsubscribe();
  }

  openContemplationRoom(room?: any | KeyValue<number, ContemplationRoomSetup>) {
    this.loadingRoom = true;
    this.unsubscribe();
    if (room) {
      if (room.value.SetupRequired) {
        this.contemplationRoom = room;
        this.settingUp = true;
        setTimeout(() => {
          this.subscribe();
          this.requestNextMessage();
          this.settingUp = false;
          this.loadingRoom = false;
          this.markConversationAsRead();
        }, 3000);
      } else {
        this.contemplationRoom = room;
        this.settingUp = false;
        setTimeout(() => {
          this.subscribe();
          this.loadingRoom = false;
          this.markConversationAsRead();
        }, 3000);
      }
    } else {
      this.contemplationRoom = room;
      setTimeout(() => {
        this.subscribe();
        this.loadingRoom = false;
        this.markConversationAsRead();
      }, 2000);
    }

    this.closeMenu();
  }

  closeMenu() {
    this.sidenav.close();
  }


  fetchPreviousMessages() {
    // this.loadingPreviousMessages = true;
    if (this.conversations.length > 0) {
      var first_message_key = this.conversations[0].Key;
      // this.assistant.getPreviousMessages(first_message_key).then((messages: any) => {
      //   if(messages.length > 0) {
      //     this.loadingPreviousMessages = false;
      //   } else {
      //     this.snackbar.open("No more messages");
      //     this.loadingPreviousMessages = false;
      //   }
      // });
      // this.apiService.getUserJournal(first_message, this.contentId)
      //   .then((res: any) => {
      //     this.notes = res.data.notes.concat(this.notes);
      //     this.loadingPreviousMessages = false;
      //     document.getElementById(first_note_id)?.scrollIntoView();
      //   }).catch((error) => {
      //     if (!error.msg) {
      //       error.msg = "Something went wrong";
      //     }
      //     this.snackbar.open(error.msg);
      //     this.loadingPreviousMessages = false;
      //   });
    }
  }

  onUp() {
    console.log("Scrolled Up");
    if (!this.loadingPreviousMessages) this.fetchPreviousMessages();
  }

  onScrollDown() {
    console.log("Scrolled Down");
  }

  requestNextMessage() {
    console.log("requesting");
    if (this.requestingMessage) {
      return;
    }
    this.requestingMessage = true;
    if (this.contemplationRoom) {
      this.assistant.requestNextMessageInRoom(this.contemplationRoom.key);
    } else {
      this.assistant.requestNextMessageInMain();
    }
  }

  processActions(action?: any, message?: Conversation) {
    if (this.processingAction) {
      return;
    }

    if (this.contemplationRoom) {

      this.processingAction = true;
      this.processingMessageKey = message?.Key;
      var messageToSend = this.textMessage.Text;

      var analyseSituationActionData: ProcessActionData = {
        Action: action,
        MessageKey: message?.Key,
        ContemplationRoom: this.contemplationRoom.key,
        ContextNextAction: this.contemplationRoom.value.NextAction
      };

      if (
        (
          this.contemplationRoom.value.NextAction == this.analyseNextPropertyList.PrincipleAnalysis ||
          this.contemplationRoom.value.NextAction == this.analyseNextPropertyList.TriggerAnalysis
        )
        && this.textMessage.Text && this.textMessage.Text != "" && this.textMessage.Text.trim().length != 0
      ) {

        analyseSituationActionData.TextMessage = this.textMessage.Text;
        messageToSend = this.textMessage.Text;

      } else {
        if (message && message.Actions) {
          messageToSend = message.Actions[action];
        }
        if (message && message.Options) {
          messageToSend = message.Options[action];
        }
      }

      this.assistant.sendMessage(MessageType.text, messageToSend, this.contemplationRoom.value);
      this.assistant.processRoomAction(analyseSituationActionData).then((r) => {
        this.processingMessageKey = undefined;
        this.processingAction = false;
        this.textMessage = {};
        this.scrollToBottom();
      }).catch(e => {
        this.processingMessageKey = undefined;
        this.processingAction = false;
      });

    }
    else if (!this.currentContext || this.currentContext.ContextType == this.contextType.actionContext) {

      this.processingAction = true;
      this.processingMessageKey = message.Key;
      var messageToSend = "";

      if (message.Actions) {
        messageToSend = message.Actions[action];
      }
      if (message.Options) {
        messageToSend = message.Options[action];
      }

      this.assistant.sendMessage(MessageType.text, messageToSend);
      this.assistant.processAction({
        Action: action,
        MessageKey: message.Key
      }).then((r) => {
        console.log("Action Processed");
        this.processingMessageKey = undefined;
        this.processingAction = false;
      }).catch(e => {
        this.processingMessageKey = undefined;
        this.processingAction = false;
      });

    } else if (
      this.currentContext.Context &&
      this.currentContext.ContextType == this.contextType.createSituation
    ) {

      this.processingAction = true;
      this.processingMessageKey = message?.Key;
      var messageToSend = this.textMessage.Text;

      var createSituationActionData: ProcessActionData = {
        Action: action,
        MessageKey: message?.Key,
        ContextNextAction: this.currentContext.Context.NextAction
      };

      if (
        (
          this.currentContext.Context.NextAction == this.createSituationNextPropertyList.Description ||
          this.currentContext.Context.NextAction == this.createSituationNextPropertyList.Title
        )
        && this.textMessage.Text != "" && this.textMessage.Text.trim().length != 0
      ) {

        createSituationActionData.TextMessage = this.textMessage.Text;
        messageToSend = this.textMessage.Text;

      } else {
        if (message.Actions) {
          messageToSend = message.Actions[action];
        }
        if (message.Options) {
          messageToSend = message.Options[action];
        }
      }

      this.assistant.sendMessage(MessageType.text, messageToSend);
      this.assistant.processAction(createSituationActionData).then((r) => {
        this.processingMessageKey = undefined;
        this.processingAction = false;
        this.textMessage = {};
        this.scrollToBottom();
      }).catch(e => {
        this.processingMessageKey = undefined;
        this.processingAction = false;
      });

    } else if (
      this.currentContext.Context &&
      this.currentContext.ContextType == this.contextType.analyseSituation
    ) {

      this.processingAction = true;
      this.processingMessageKey = message?.Key;
      var messageToSend = this.textMessage.Text;

      var createSituationActionData: ProcessActionData = {
        Action: action,
        MessageKey: message?.Key,
        ContextNextAction: this.currentContext.Context.NextAction
      };

      if (message.Actions) {
        messageToSend = message.Actions[action];
      }
      if (message.Options) {
        messageToSend = message.Options[action];
      }

      this.assistant.sendMessage(MessageType.text, messageToSend);
      this.assistant.processAction(createSituationActionData).then((r) => {
        this.processingMessageKey = undefined;
        this.processingAction = false;
        this.textMessage = {};
        this.scrollToBottom();
      }).catch(e => {
        this.processingMessageKey = undefined;
        this.processingAction = false;
      });

    }



  }


  processUserActions(action?: any, message?: Conversation) {
    if (this.processingAction) {
      return;
    }

    if (this.contemplationRoom) {

      this.processingAction = true;
      this.processingMessageKey = message?.Key;
      var messageToSend = this.textMessage.Text;

      var analyseSituationActionData: ProcessActionData = {
        Action: action,
        MessageKey: message?.Key,
        ContemplationRoom: this.contemplationRoom.key,
        ContextNextAction: this.contemplationRoom.value.NextAction
      };

      if (
        (
          this.contemplationRoom.value.NextAction == this.analyseNextPropertyList.PrincipleAnalysis ||
          this.contemplationRoom.value.NextAction == this.analyseNextPropertyList.TriggerAnalysis
        )
        && this.textMessage.Text && this.textMessage.Text != "" && this.textMessage.Text.trim().length != 0
      ) {

        analyseSituationActionData.TextMessage = this.textMessage.Text;
        messageToSend = this.textMessage.Text;

      } else {
        if (message && message.Actions) {
          messageToSend = message.Actions[action];
        }
        if (message && message.Options) {
          messageToSend = message.Options[action];
        }
      }

      this.assistant.sendMessage(MessageType.text, messageToSend, this.contemplationRoom.value);
      this.assistant.processRoomAction(analyseSituationActionData).then((r) => {
        this.processingMessageKey = undefined;
        this.processingAction = false;
        this.textMessage = {};
        this.scrollToBottom();
      }).catch(e => {
        this.processingMessageKey = undefined;
        this.processingAction = false;
      });

    }
    else {

      this.processingAction = true;
      this.processingMessageKey = message?.Key;
      var messageToSend = this.textMessage.Text;

      if(action != null) {
        messageToSend = message.Actions[action];
        // this.assistant.sendMessage(MessageType.text, msg);
      }
      
      var processingData: ProcessActionData = {
        Action: action,
        MessageKey: message?.Key,
        TextMessage: messageToSend,
        ContextNextAction: this.currentContext.Context.NextAction
      };

      this.assistant.sendMessage(MessageType.text, messageToSend);

      this.assistant.processAction(processingData).then((r) => {
        this.processingMessageKey = undefined;
        this.processingAction = false;
        this.textMessage = {};
        this.scrollToBottom();
      }).catch(e => {
        this.processingMessageKey = undefined;
        this.processingAction = false;
      });

    }

  }


  asIsOrder(a, b) {
    return 1;
  }

  scrollToBottom(): void {
    setTimeout(() => {
      $("#messagesList").animate({ scrollTop: $('#messagesList').prop("scrollHeight") }, 100);
    }, 50);
  }
}
