import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '../../services/api.service';

@Component({
  selector: 'sb-basic-profile',
  templateUrl: './basic-profile.component.html',
  styleUrls: ['./basic-profile.component.scss']
})
export class BasicProfileComponent implements OnInit {

  @Input('basicProfile') basicProfile?: any;
  @Output('onupdate') onupdate: EventEmitter<any> = new EventEmitter();
  basicProfileForm?: FormGroup;
  saving: boolean = false;

  constructor(private _formBuilder: FormBuilder, private route: ActivatedRoute, private modal: NgbModal, private apiService: ApiService, private snackbar: MatSnackBar) {

    this.basicProfileForm = this._formBuilder.group({
      UserBasicId: new FormControl(0),

      About: new FormControl('', Validators.compose([])),

      City: new FormControl('', Validators.compose([])),
      HomeTown: new FormControl('', Validators.compose([])),
      Gender: new FormControl('', Validators.compose([])),
      DateOfBirth: new FormControl('', Validators.compose([])),
      IsReliguous: new FormControl(false, Validators.compose([])),

      Languages: new FormControl(),

      HighestEducation: new FormControl(Validators.compose([])),
      Profession: new FormControl(Validators.compose([])),

      CreatedOn: new FormControl(),
      CreatedBy: new FormControl(),
      ModifiedOn: new FormControl(),
      ModifiedBy: new FormControl(),
    });
  }

  ngOnInit(): void {
    if(this.basicProfile) {
      if(this.basicProfile.Languages && this.basicProfile.Languages.length > 0) {
        var languages: any = [];
        var x = 0;
        this.basicProfile.Languages.forEach((lang: any) => {
          languages.push(lang.Language);
          x++;
          if (x == this.basicProfile.Languages.length) {
            this.basicProfile.Languages = languages;
            this.basicProfileForm?.setValue(this.basicProfile);
          }
        });
      } else {
        this.basicProfileForm?.setValue(this.basicProfile);
      }
    }
  }

  saveProfile(){

    if (this.basicProfileForm?.valid) {
      this.saving = true;
      var data: any = this.basicProfileForm.value;
      this.processLanguages(data).then((r) => {
        this.apiService.saveBasicProfile(r).then((res: any) => {
          if (res.success) {
            this.onupdate.emit(res.data);
            this.saving = false;
            this.snackbar.open("Your profile is updated");
          } else {
            this.snackbar.open("Something went wrong. Please try again.");
          }
        }).catch(e => {
          if (!e.msg) {
            e.msg = "Something went wrong. Please try again.";
          }
          this.snackbar.open(e.msg);
          this.saving = false;
          this.modal.dismissAll();
        });
      });
    }
  }

  processLanguages(data: any){
    return new Promise((r, j) => {
      if(data.Languages && data.Languages.length > 0) {
        var languages: any = [];
        var x = 0;
        data.Languages.forEach((language: any) => {
          languages.push({
            UserBasicId: data.UserBasicId,
            Language: language,
            IsActive: true
          });
          x++;
          if(x == data.Languages.length) {
            data.Languages = languages;
            r(data);
          }
        });
      } else {
        data.Languages = [];
        r(data);
      }
    });
  }

}
