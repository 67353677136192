import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
// import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } 
// from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-start-program-popup',
  templateUrl: './start-program-popup.component.html',
  styleUrls: ['./start-program-popup.component.scss'],
})
export class StartProgramPopupComponent {

  negative: string = "Cancel";
  positive: string = "Ok";
  option?: string;

  delete: boolean = true;

  showNegative: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<StartProgramPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    if (data.positive) {
      this.positive = data.positive;
    }

    if (data.negative) {
      this.negative = data.negative;
    }

    if (data.option) {
      this.option = data.option;
      //console.log(this.option);
    }

    if (data.showNegative == false) {
      this.showNegative = false;
    } else {
      this.showNegative = true;
    }
  }

  onPositiveClick() {
    this.dialogRef.close(this.positive);
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  onOptionClick() {
    this.dialogRef.close(this.option);
  }

}
