<!-- <ion-content class="ion-padding">
    <div class="fullscreen beige-space">
        <div class="box">
            <div class="back" (click)="closeDialog()">
                <mat-icon>close</mat-icon>
            </div>
            <h1 class="title flex" *ngIf="currentContent">{{ currentContent.Title }}<br><small>({{ currentContent.chapterName }} - {{ currentContent.lessonName }})</small>
                <div class="ml-auto align-self-end pr-4">
                    <mat-icon matListIcon class="ml-4 mr-2" color="primary" *ngIf="progress[currentContent.Id] && progress[currentContent.Id].IsCompleted"> check_circle</mat-icon>
                </div>
            </h1>
        </div>
    </div>
</ion-content> -->


<div class="currentContent-section flex-1">
    <div class="row m-0 dflex h-100">
        <div class="col-md-12 col-12 h-100 currentContent p-0">
            <ng-container *ngIf="!loadingContent">

                <div class="w-100 content-space text-left">
                    <div class="content-container" [class.p-3]="currentContent.Type == 'ADIOSCOPE'" [class.venus]="currentContent.alias == 'venus'">
                        <ng-container *ngIf="currentContent.Type == 'REVIEW_ASSIGNMENT'">
                            <!-- <button type="button" class="btn btn-outline-primary"
                            (click)="reviewYourAssignments(reviewAssignments, currentContent)">
                            Review last week
                        </button> -->
                        </ng-container>
                        <ng-container *ngIf="currentContent.Type == 'BUYBOOK'">
                            <p class="description" *ngIf="currentContent.Text != ''">{{ currentContent.Text }}</p>
                            <div class="mt-4 d-flex w-100 place-center">
                                <button type="button" class="btn btn-primary text-white px-5 mt-3" (click)="buyBook(currentContent)">
                                    Buy on Amazon
                                </button>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="currentContent.Type == 'FILE'">
                            <!-- <div class="mt-4" *ngIf="contentProgress[currentContent.Id]">
                            <button type="button" class="btn btn-outline-primary"
                                (click)="viewFile(currentContent, contentProgress[currentContent.Id])">
                                View File
                            </button>
                        </div>
                        <div class="mt-4" *ngIf="!contentProgress[currentContent.Id]">
                            <button type="button" class="btn btn-outline-primary" (click)="uploadFile(currentContent)">
                                Upload File
                            </button>
                        </div> -->
                        </ng-container>
                        <ng-container *ngIf="currentContent.Type == 'VIDEO'">
                            <div id="jw-container">
                                <video
                                    class="w-100" oncontextmenu="return false;" id="myVideo" autoplay
                                    controls preload="auto" controlsList="nodownload"
                                    on-ended="videoEnded()" (ended)="markVideoAsComplete(currentContent)">
                                    <source [src]="getVideoFile(currentContent)" type="video/mp4">
                                </video>
                            </div>
                            <p class="description" *ngIf="currentContent.Text != ''">{{ currentContent.Text }}
                            </p>
                        </ng-container>
                        <ng-container *ngIf="currentContent.Type == 'YOUTUBE'">
                            <div id="jw-container">
                                <iframe class="w-100 aspect-16-9" [src]='currentContent.Url' frameborder="0" allowfullscreen></iframe>
                            </div>
                            <p class="description" *ngIf="currentContent.Text != ''">{{ currentContent.Text }}</p>
                        </ng-container>
                        <ng-container *ngIf="currentContent.Type == 'PODCAST'">
                            <div id="jw-container" class="p-3">
                                <iframe class="mt-5 mb-4" style="border-radius:12px" [src]="currentContent.Url  + '&theme=0' | safeUrl" width="100%" height="152" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
                            </div>
                            <p class="description" *ngIf="currentContent.Text != ''">{{ currentContent.Text }}</p>
                        </ng-container>
                        <ng-container *ngIf="currentContent.Type == 'AUDIOOLD'">
                            <div class="media-footer">
                                <mat-toolbar>
                                    <mat-toolbar-row class="toolbar-row" cols="1" style="padding: 70px 0px !important;">

                                        <p class="text-center mb-0" *ngIf="state.seeking">
                                            <span class="spinner-border spinner-border" role="status"
                                                aria-hidden="true"></span>
                                        </p>
                                        <ng-container *ngIf="!state.seeking">
                                            <button mat-icon-button color="primary" class="play-icons"
                                                (click)="state?.paused || state?.canplay ? resume() : openAudioFile(currentContent.IsIntroduction, currentContent)"
                                                [disabled]="state?.loading" *ngIf="!state?.playing && !state?.seeking">
                                                <mat-icon mat-list-icon color="primary">play_circle_filled
                                                </mat-icon>
                                            </button>
                                            <button mat-icon-button color="primary" (click)="pause()"
                                                class="play-icons" [disabled]="state?.loading"
                                                *ngIf="state?.playing">
                                                <mat-icon mat-list-icon color="primary">pause_circle_filled
                                                </mat-icon>
                                            </button>
                                        </ng-container>
                                    </mat-toolbar-row>
                                    <mat-toolbar-row class="toolbar-row p-0" cols="1">

                                        <div class="slider-container" *ngIf="(state?.loading)">
                                            <p class="text-center mb-0">
                                                <span class="spinner-border spinner-border-lg" role="status"
                                                    aria-hidden="true"></span>
                                            </p>
                                        </div>
                                        <div class="slider-container"
                                            *ngIf="(state?.playing || state?.paused || state?.canplay) && !state?.loading">
                                            {{ state?.readableCurrentTime }}
                                            <mat-slider class="time-slider" min="0" color="primary"
                                                [max]="state?.duration" step="1"
                                                [value]="state?.currentTime"
                                                (input)="onSliderChangeEnd($event)"
                                                [disabled]="state?.error">
                                            </mat-slider>
                                            {{ state?.readableDuration }}
                                        </div>
                                    </mat-toolbar-row>
                                    <mat-toolbar-row class="toolbar-row p-0" cols="1">
                                        <button mat-icon-button (click)="toggleFavorite()" color="primary">
                                            <ion-icon name="heart" *ngIf="isFavorite" style="font-size:32px;" color="danger"></ion-icon>
                                            <ion-icon name="heart-outline" style="font-size:32px;" color="danger" *ngIf="!isFavorite"></ion-icon>
                                            <!-- <mat-icon *ngIf="isFavorite">favorite</mat-icon>
                                            <mat-icon *ngIf="!isFavorite">favorite_border</mat-icon> -->
                                        </button>
                                    </mat-toolbar-row>
                                </mat-toolbar>
                            </div>
                            <p class="description" *ngIf="currentContent.Text != ''">{{ currentContent.Text
                                }}
                            </p>
                        </ng-container>
                        <ng-container *ngIf="currentContent.Type == 'AUDIO'">
                            <div class="media-footer mt-5">
                                <mat-toolbar>
                                    <mat-toolbar-row class="toolbar-row p-0" cols="2">
                                        <div class="">
                                            <p class="text-center mb-0" *ngIf="state.seeking">
                                                <span class="spinner-border spinner-border" role="status"
                                                    aria-hidden="true"></span>
                                            </p>
                                            <ng-container *ngIf="!state.seeking">
                                                <button mat-icon-button color="primary" class="play-icons"
                                                    (click)="state?.paused || state?.canplay ? resume() : openAudioFile(currentContent.IsIntroduction, currentContent)"
                                                    [disabled]="state?.loading" *ngIf="!state?.playing && !state?.seeking">
                                                    <mat-icon mat-list-icon color="primary">play_circle_filled
                                                    </mat-icon>
                                                </button>
                                                <button mat-icon-button color="primary" (click)="pause()"
                                                    class="play-icons" [disabled]="state?.loading"
                                                    *ngIf="state?.playing">
                                                    <mat-icon mat-list-icon color="primary">pause_circle_filled
                                                    </mat-icon>
                                                </button>
                                            </ng-container>
                                        </div>
                                        <ng-container>
                                            <div class="slider-container" *ngIf="(state?.loading)">
                                                <p class="text-center mb-0">
                                                    <span class="spinner-border spinner-border-lg" role="status"
                                                        aria-hidden="true"></span>
                                                </p>
                                            </div>
                                            <div class="slider-container"
                                                *ngIf="(state?.playing || state?.paused || state?.canplay) && !state?.loading">
                                                {{ state?.readableCurrentTime }}
                                                <mat-slider class="time-slider" min="0" color="primary"
                                                    [max]="state?.duration" step="1"
                                                    [value]="state?.currentTime"
                                                    (input)="onSliderChangeEnd($event)"
                                                    [disabled]="state?.error">
                                                </mat-slider>
                                                {{ state?.readableDuration }}
                                            </div>
                                        </ng-container>
                                    </mat-toolbar-row>
                                </mat-toolbar>
                            </div>
                            <div class="d-flex place-center align-center">
                                <button mat-icon-button (click)="toggleFavorite()" color="dark">
                                    <ion-icon name="heart" *ngIf="isFavorite" style="font-size:32px;" color="dark"></ion-icon>
                                    <ion-icon name="heart-outline" style="font-size:32px;" color="dark" *ngIf="!isFavorite"></ion-icon>
                                </button>
                            </div>
                            <p class="description" *ngIf="currentContent.Text != ''">{{ currentContent.Text
                                }}
                            </p>
                        </ng-container>
                        <ng-container *ngIf="currentContent.Type == 'ADIOSCOPE'">
                            <sb-view-situation class="w-100 p-4" #viewSituation [fullWidth]="true"
                                [situationId]="currentContent.SituationId" (loaded)="setSituation($event)">
                            </sb-view-situation>

                            <!-- <app-adios-btn *ngIf="adioscopeSituation" class="dflex place-center mt-5 mb-5" highlight="lillac"
                                [addmargin]="false" [addmarginleft]="false" (click)="createSituation()">
                                GO TO ADIOSCOPE
                            </app-adios-btn> -->
                        </ng-container>
                        <ng-container *ngIf="currentContent.Type == 'ADIOSCOPEEMBED'">
                            <app-personal-adioscope class="w-100 dflex place-center p-4" (emitPopupClose)="closeDialog($event)"></app-personal-adioscope>
                        </ng-container>
                        <ng-container *ngIf="currentContent.Type == 'ESSAY'">
                            <!-- ESSAYS - View Essay -->
                        </ng-container>
                        <ng-container *ngIf="currentContent.Type == 'CONTEMPLATION'">
                            <!-- CONTEMPLATION TIMER -->
                        </ng-container>
                        <ng-container
                            *ngIf="currentContent.Type == 'PROFILEGROUP' || currentContent.Type == 'PROFILEQUESTION'">
                            <p class="description" *ngIf="currentContent.Text != ''">{{ currentContent.Text
                                }}</p>
                            <sb-profiling-questions [contentId]="currentContent.Id" class="w-100 dflex place-center p-4"
                                [programParticipantId]="currentContent.programParticipantId">
                            </sb-profiling-questions>
                        </ng-container>
                        <ng-container *ngIf="currentContent.Type == 'SUMMARY'">
                            <!-- SESSION SUMMARY -->
                        </ng-container>
                        <ng-container *ngIf="currentContent.Type == 'JOURNALING' || currentContent.Type == 'COMMUNITY'">
                            <p class="description" *ngIf="currentContent.Text != ''">{{ currentContent.Text }}
                            </p>
                            <sb-journaling-questions [contentId]="currentContent.Id" class="w-100 dflex place-center p-4"
                                [programParticipantId]="currentContent.programParticipantId">
                            </sb-journaling-questions>
                        </ng-container>
                    </div>
                </div>
                <div
                    class="row action-button ml-0 mr-0 pt-2 pb-2 mt-3 mb-0 flex justify-between beige-space m-0">
                    <div class="col-6 ">
                        
                        <button class="btn bg-white text-dark btn-round" *ngIf="currentContentIndex > 0" (click)="previousContent()">
                            <img class="back-arrow" src="../../../../assets/images/icons/right-arrow.svg">
                            PREVIOUS
                        </button>

                        <!-- <app-back-btn class="dflex" *ngIf="currentContentIndex > 0" highlight="lillac"
                            [addmargin]="false" (click)="previousContent()">
                            PREVIOUS
                        </app-back-btn> -->
                    </div>
                    <div class="col-6 place-end d-flex">

                        <button class="btn bg-white text-dark btn-round" *ngIf="currentContentIndex < contents.length - 1" (click)="nextContent()">
                            NEXT
                            <img class="arrow" src="../../../../assets/images/icons/right-arrow.svg">
                        </button>

                        <button class="btn bg-white text-dark btn-round" *ngIf="currentContentIndex == contents.length - 1" (click)="doneForNow()">
                            DONE FOR NOW
                        </button>

                        <!-- <app-adios-btn class="dflex  place-end"
                            *ngIf="currentContentIndex < contents.length - 1" highlight="lillac"
                            [addmargin]="false" [addmarginleft]="false" (click)="nextContent()">
                            NEXT
                        </app-adios-btn> -->
                    </div>
                </div>
            </ng-container>
            <div class="align-items-center row" *ngIf="loadingContent">
                <div class="col-md-12 text-center p-5">
                    <div class="spinner-border"></div>
                </div>
            </div>
            
        </div>
    </div>
</div>