<div class="program-content">

  <!-- <img class="celebration" *ngIf="showCelebration" src="../../../../assets/images/ballon-beige.gif" style="position: fixed; top: 0px; left: 0px; width: 100%; height: 100%; object-fit: cover;"> -->
  <ng-container *ngIf="loading">
    <div class="row m-0 flex h-100" style="place-content: center; align-content: center">
      <div class="col-md-12 text-center" style="padding: 5rem">
        <p>
          <span class="spinner-border spinner-border-lg" role="status" aria-hidden="true"></span>
        </p>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="!loading">

    <ng-container>
      <ion-refresher slot="fixed" (ionRefresh)="refreshed($event)">
        <ion-refresher-content pullingIcon="chevron-down-circle-outline" pullingText="Pull to refresh"
          refreshingSpinner="circles" refreshingText="Refreshing...">
        </ion-refresher-content>
      </ion-refresher>

      <div class="list-box pt-0">
        <div class="row m-0 w-100">
          <div class="col-md-12 p-0">
            <!-- createSituation() -->
            <div class="box program-card bg-white analysis-card outline">
              <!-- <mat-list class="module-list p-0">
                <mat-list-item class="content-item">
                  <div mat-line class="content-title link">
                    Did something happen at home? Or work? Or...
                  </div>
                </mat-list-item>
              </mat-list> -->

              <h1 class="category-title text-start">Add New Situation</h1>
              <div class="module-list">
                <mat-form-field class="w-100" appearance="outline">
                  <textarea matInput class="situationDescription" name="situation_title" [(ngModel)]="newSituation.Description" placeholder="Please describe the situation in brief..."></textarea>
                </mat-form-field>
                <div class="action-buttons mt-0">
                  <div class="start border-0">
                    <div class="resolve-date">
                      <button class="cal-button" mat-icon-button type="button"#tooltip="matTooltip" (click)="picker.open()" matTooltip="Resolve by" matTooltipPosition="above">
                        <mat-icon>calendar_today</mat-icon>
                      </button>
                      <div class="w-0">
                        <input matInput [(ngModel)]="newSituation.ResolveBy" [matDatepicker]="picker">
                        <mat-datepicker #picker></mat-datepicker>
                      </div>
                      <small class="text-muted ps-2" *ngIf="newSituation.ResolveBy">Resolve by<br>{{ newSituation.ResolveBy | date : 'MMM d, y': dateOffset }}</small>
                    </div>
                    <div class="divider"></div>
  
                    <div class="criticality">
                      <small class="text-muted">Criticality</small>
                      <div (click)="setCriticality(criticality)" *ngFor="let criticality of criticalities"  class="bicon bg-importance-{{criticality}}" [class.active]="newSituation.Importance == criticality">{{ criticality }}</div>
                    </div>
                  </div>

                  <div class="end">
                    <button type="button" [disabled]="!newSituation.Description || creatingSituation" class="btn btn-default bg-white text-dark" (click)="saveSituation(1, false)">
                      <span *ngIf="creatingSituation && willAnalyse == false" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      <span *ngIf="!creatingSituation || (creatingSituation && willAnalyse == true)">Analyze Later</span>
                    </button>
                    <button type="button" [disabled]="!newSituation.Description || creatingSituation" class="btn btn-default" (click)="saveSituation(1, true)">
                      
                      <span *ngIf="creatingSituation && willAnalyse == true" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      <span *ngIf="!creatingSituation || (creatingSituation && willAnalyse == false)">Analyze Now</span>
                      
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ng-container *ngIf="loadingPersonalSituations">
        <div class="row m-0 flex h-100" style="place-content: center; align-content: center">
          <div class="col-md-12 text-center" style="padding: 5rem">
            <p>
              <span class="spinner-border spinner-border-lg" role="status" aria-hidden="true"></span>
            </p>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="!loadingPersonalSituations && personalSituations">
        <ion-segment class="mt-5" (ionChange)="changedTab($event)" [swipeGesture]="true" [value]="shown_tab">
          <ion-segment-button value="0">
            <ion-label>Ongoing</ion-label>
          </ion-segment-button>
          <ion-segment-button value="1">
            <ion-label>Resolved</ion-label>
          </ion-segment-button>
        </ion-segment>


        <ng-container *ngIf="shown_tab == 0">

          <div class="list-box pt-3">
            <div class="row m-0 w-100">



              <ng-container *ngIf="personalSituations.loading">
                <div class="row m-0 flex h-100" style="place-content: center; align-content: center">
                  <div class="col-md-12 text-center" style="padding: 5rem">
                    <p>
                      <span class="spinner-border spinner-border-lg" role="status" aria-hidden="true"></span>
                    </p>
                  </div>
                </div>
              </ng-container>

              <ng-container *ngIf="personalSituations.situations.length == 0 && !personalSituations.loading">
                <div class="col-md-12">
                  <div class="box content-center">
                    <p class="mt-5">No Situations Yet</p>
                  </div>
                </div>
              </ng-container>

              <ng-container *ngFor="let situation of personalSituations.situations; let i = index;">

                <div class="col-md-12 p-0">
                  <div class="box program-card white-space task-done" [class.cleared-space]="situation.IsCleared"
                    [class.grey-space]="situation.IsCleared" [class.uncleared-space]="!situation.IsCleared"
                    [class.is-done]="resolvedSituations[situation.Id]" [class.importance-5]="situation.Importance == 5" [class.importance-4]="situation.Importance == 4" [class.importance-2]="situation.Importance == 2" [class.importance-1]="situation.Importance == 1">
                    <div class="d-flex align-center status text-muted mt-0" *ngIf="situation.ResolveBy || situation.Importance">
                      <span *ngIf="situation.ResolveBy" class="d-flex align-center p-2 badge-warning me-3">
                        <mat-icon class="me-2" style="font-size: 18px; width: 18px; height: 18px;">calendar_today</mat-icon> <span style="padding-top: 3px;">{{ situation.ResolveBy | date: 'MMM d, y': dateOffset}}</span>
                      </span>
                      <!-- <span *ngIf="situation.Importance" class="badge bg-primary p-2 badge-warning">Criticality {{ situation.Importance }}</span> -->
                       <!-- <span *ngIf="situation.Importance">
                        <img class="importance-icon" src="../../../../assets/images/icons/importance-{{situation.Importance}}.png">
                       </span> -->
                    </div>
                    <h1>{{ situation.Title }}</h1>
                    <p class="margin-top-20">{{ situation.Description }}</p>
                    <div class="action-buttons place-end flex-row mt-0">

                      <!-- <app-knob-slider *ngIf="!situation.IsCleared && !isStateChanging[situation.Id]" class="w-100 mr-1" [isResolved]="situation.IsCleared"
                        (resolved)="onSituationResolved($event, situation, i)"></app-knob-slider> -->
                      <div class="w-100 mr-1 mt-3" *ngIf="!situation.IsCleared && !isStateChanging[situation.Id]">
                        <button type="button" class="btn btn-secondary text-muted" (click)="onSituationResolved($event, situation, i)">Mark as Resolved</button>
                      </div>

                      <p class="mr-4 text-center ng-star-inserted dflex flex-1 place-center align-center mb-0"
                        *ngIf="isStateChanging[situation.Id]">
                        <span class="spinner-border spinner-border text-primary" role="status"
                          aria-hidden="true"></span>
                      </p>

                      <app-adios-btn class="action-button" style="margin-right: 42px;" *ngIf="situation.IsCleared"
                        highlight="lillac" [hideArrow]="true" [addmarginleft]="false" [addmargin]="false"
                        (click)="onSituationResolved(false, situation, i)">REOPEN
                      </app-adios-btn>

                      <app-adios-btn class="action-button" *ngIf="!situation.IsCleared" highlight="lillac"
                        [addmarginleft]="false" [addmargin]="false" (click)="analyseSituation(situation)">
                        <span class="hide-xs">ANALYSE</span>
                      </app-adios-btn>
                    </div>
                    <!-- <img src="../../../../assets/images/resolved.png" class="done-image" [class.resolved]="situation.IsCleared" alt="Done"> -->
                  </div>
                </div>
              </ng-container>

              <ng-container
                *ngIf="personalSituations.currentPage < personalSituations.totalPages && !personalSituations.loading">
                <div class="col-md-12">
                  <div class="box content-center">
                    <app-adios-btn class="action-button" highlight="lillac" [addmarginleft]="false" [addmargin]="true"
                      (click)="getPersonalSituations(personalSituations.currentPage + 1 )">Load More
                    </app-adios-btn>
                  </div>
                </div>
              </ng-container>

              <ng-container *ngIf="personalSituations.situations.length > 0 && personalSituations.loading">
                <div class="col-md-12">
                  <div class="col-md-12 text-center" style="padding: 5rem">
                    <p>
                      <span class="spinner-border spinner-border-lg" role="status" aria-hidden="true"></span>
                    </p>
                  </div>
                </div>
              </ng-container>

            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="shown_tab == 1">

          <div class="list-box pt-5">
            <div class="row m-0 w-100">

              <ng-container *ngIf="clearedSituations.loading">
                <div class="row m-0 flex h-100" style="place-content: center; align-content: center">
                  <div class="col-md-12 text-center" style="padding: 5rem">
                    <p>
                      <span class="spinner-border spinner-border-lg" role="status" aria-hidden="true"></span>
                    </p>
                  </div>
                </div>
              </ng-container>

              <ng-container *ngIf="clearedSituations.situations.length == 0 && !clearedSituations.loading">
                <div class="col-md-12 p-0">
                  <div class="box content-center">
                    <p class="mt-5">No Situations Yet</p>
                  </div>
                </div>
              </ng-container>

              <ng-container *ngFor="let situation of clearedSituations.situations; let i = index;">

                <!-- <app-situation-card [situation]="situation" (analyzeSituation)="analyseSituation($event)"></app-situation-card> -->

                <div class="col-md-12 p-0">
                  <div class="box program-card white-space task-done" [class.cleared-space]="situation.IsCleared" [class.uncleared-space]="!situation.IsCleared"
                    [class.is-done]="resolvedSituations[situation.Id]">
                    <p class="status text-muted">
                      {{ situation.CreatedOn | date: 'MMM d, y, h:mm a':
                      dateOffset}}
                    </p>
                    <h1>{{ situation.Title }}</h1>
                    <p class="margin-top-20">{{ situation.Description }}</p>
                    <div class="action-buttons place-end flex-row mt-0">

                      <app-knob-slider *ngIf="!situation.IsCleared && !isStateChanging[situation.Id]" class="w-100 mr-1"
                        (resolved)="onSituationResolved($event, situation, i)"></app-knob-slider>

                      <p class="mr-4 text-center ng-star-inserted dflex flex-1 place-center align-center mb-0"
                        *ngIf="isStateChanging[situation.Id]">
                        <span class="spinner-border spinner-border text-primary" role="status"
                          aria-hidden="true"></span>
                      </p>

                      <!-- <p class="action-button" *ngIf="situation.IsCleared">
                        <span (click)="onSituationResolved(false, situation)" class="link"></span>
                      </p> -->

                      <app-adios-btn class="action-button" style="margin-right: 42px;" *ngIf="situation.IsCleared"
                        highlight="lillac" [hideArrow]="true" [addmarginleft]="false" [addmargin]="false"
                        (click)="onSituationResolved(false, situation, i)">REOPEN
                      </app-adios-btn>

                      <app-adios-btn class="action-button" *ngIf="!situation.IsCleared" highlight="lillac"
                        [addmarginleft]="false" [addmargin]="false" (click)="analyseSituation(situation)">
                        <span class="hide-xs">ANALYSE</span>
                      </app-adios-btn>
                    </div>
                    <!-- <img src="../../../../assets/images/resolved.png" class="done-image" [class.resolved]="situation.IsCleared" alt="Done"> -->
                  </div>
                </div>
              </ng-container>

              <ng-container
                *ngIf="clearedSituations.currentPage < clearedSituations.totalPages && !clearedSituations.loading">
                <div class="col-md-12">
                  <div class="box content-center">
                    <app-adios-btn class="action-button" highlight="lillac" [addmarginleft]="false" [addmargin]="true"
                      (click)="getClearedSituations(clearedSituations.currentPage + 1 )">Load More
                    </app-adios-btn>
                  </div>
                </div>
              </ng-container>

              <ng-container *ngIf="clearedSituations.situations.length > 0 && clearedSituations.loading">
                <div class="col-md-12">
                  <div class="col-md-12 text-center" style="padding: 5rem">
                    <p>
                      <span class="spinner-border spinner-border-lg" role="status" aria-hidden="true"></span>
                    </p>
                  </div>
                </div>
              </ng-container>

            </div>
          </div>
        </ng-container>

      </ng-container>

    </ng-container>

    <!-- <app-celebration></app-celebration> -->


    <!-- <ng-container *ngIf="introProgram && introProgramParticipant && !introProgramParticipant.IsCompleted">
            <div class="program-content">
              <div class="section w-100 mh-auto">
                <div class="list-box pt-0">
                  <div class="row m-0 w-100 content-center">
                    <div class="col-md-12 col-12 p-0" *ngIf="!loading && introProgram.ProgramChapters.length > 0">
                      <div class="box content-card grey-space intro-card">
                        <mat-list class="module-list">
                          <div mat-subheader class="module-name text-white">LET'S START HERE</div>
  
                          <mat-list-item class="content-item">
                            <div mat-line class="content-title text-white link" (click)="openContent(introProgram)">
                              {{ introProgram.Name }}
                            </div>
                            <mat-icon end mat-list-icon svgIcon="content_play" class="content-icon text-white"
                              (click)="openContent(introProgram)" class="link white"></mat-icon>
                          </mat-list-item>
                        </mat-list>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container> -->


    <!-- <div class="section w-100 mh-auto">
          <ng-container *ngIf="pending">
            <ng-container *ngIf="pending.Stage == 'START_INTRO'">
              <div class="list-box pt-4">
                <div class="row m-0 w-100 content-center">
                  <div #contentlist class="col-md-12 col-12 p-0">
                    <div class="box content-card grey-space">
                      <mat-list class="module-list">
                        <mat-list-item class="content-item">
                          <div mat-line class="content-title link text-center">
                            Over the next few days, you will learn some
                            fundamental principles that will help you think
                            deeply about things that matter to you.
                          </div>
                        </mat-list-item>
                        <mat-list-item class="content-item align-center">
                          <app-adios-btn type="button" (click)="getStarted()" highlight="lillac" [addmargin]="false"
                            [addmarginleft]="false">GET STARTED</app-adios-btn>
                        </mat-list-item>
                      </mat-list>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="pending.Stage == 'ENROLL_CORE'">
              <div class="list-box pt-4">
                <div class="row m-0 w-100 content-center">
                  <div #contentlist class="col-md-12 col-12 p-0">
                    <div class="box content-card grey-space lillac-space">
                      <h1>THE ADIOS APPROACH</h1>
                      <mat-list class="module-list">
                        <ng-container>
                          <div mat-subheader class="module-name">
                            How does Adios work?
                          </div>
                          <mat-list-item class="content-item">
                            <div mat-line class="content-title">
                              <p>
                                Adios is a one year membership that starts with
                                an 8-week Core program.
                              </p>

                              <p>
                                You go through the weekly sessions on this
                                platform at your convenience and also attend
                                live Zoom sessions every week. These weekly
                                sessions are a combination of audio lessons and
                                exercises you will do to improve your clarity of
                                thought.
                              </p>
                              <p>
                                You will use the Adioscope to analyse real life
                                situations and find answers to your crucial
                                questions. Along the way, you will also hear
                                from our alumni about their experience and their
                                journey.
                              </p>
                            </div>
                          </mat-list-item>
                          <div mat-subheader class="module-name">
                            ENROLL NOW
                          </div>
                          <mat-list-item class="content-item">
                            <div mat-line class="content-description text-center" style="margin: 0 auto">
                              <button class="btn btn-lg btn-primary mt-4 mb-4 action-button" type="button"
                                style="margin: 0px auto" (click)="joinBatch(false)">
                                <span>
                                  Join Now for
                                  <ng-container *ngIf="!store_prices[VENUS_CORE]">
                                    {{ pending.Batch.Price * multiplyFactor |
                                    currency: currency : 'symbol' : '1.0-0' }}
                                  </ng-container>
                                  <ng-container *ngIf="store_prices[VENUS_CORE]">
                                    {{ store_prices[VENUS_CORE].price }}
                                  </ng-container>
                                </span>
                              </button>
                            </div>
                          </mat-list-item>
                        </ng-container>
                      </mat-list>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>

            <ng-container
              *ngIf="(pending.Stage == 'INTRO_ONGOING' || pending.Stage == 'CORE_ONGOING') && pending.Contents.length > 0">
              <div class="list-box pt-4">
                <div class="row m-0 w-100 content-center">
                  <div #contentlist class="col-md-12 col-12 p-0">
                    <div class="box content-card grey-space">
                      <mat-list class="module-list">
                        <div mat-subheader class="module-name">
                          LET'S START HERE
                        </div>
                        <mat-list-item *ngFor="let content of pending.Contents; let c = index;" class="content-item">
                          <div mat-line class="content-title link" (click)="openContent(content)">
                            {{ content.Title }}
                          </div>
                          <mat-icon end mat-list-icon svgIcon="content_play" class="content-icon"
                            (click)="openContent(content)" class="link">
                          </mat-icon>
                        </mat-list-item>
                      </mat-list>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </ng-container>
        </div> -->
  </ng-container>
</div>