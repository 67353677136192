import { ChangeDetectorRef, Component, Input, NgZone, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Router } from '@angular/router';
// import { IAPProduct, InAppPurchase2 } from '@awesome-cordova-plugins/in-app-purchase-2/ngx';
import { AlertController, IonModal, ModalController, Platform } from '@ionic/angular';
import { OverlayEventDetail } from '@ionic/core';
import { environment } from 'src/environments/environment';
import { ApiService } from '../../services/api.service';
import { CommonService } from '../../services/common.service';
import { UserService } from '../../services/user.service';
declare var google: any;
@Component({
  selector: 'app-google-connect',
  templateUrl: './google-connect.component.html',
  styleUrls: ['./google-connect.component.scss'],
})
export class GoogleConnectComponent implements OnInit {

  scriptLoaded: boolean = false;

  constructor(
    private apiService: ApiService,
    public userService: UserService,
    private changeRef: ChangeDetectorRef,
    private zone: NgZone
  ) {
    
    let googleid = 'google-client-script';
    let script = document.createElement("script");
    script.setAttribute('src', 'https://accounts.google.com/gsi/client');
    script.setAttribute('id', googleid);
    script.setAttribute('sync', '');
    document.body.appendChild(script);
    script.onload = () => {
      // this.calendarConsent();
      this.scriptLoaded = true;
    }

  }

  ngOnInit() {
    
  }

  calendarConsent() {
    if(!this.userService.user.AuthCode || !this.userService.user.RefreshToken) {
      let client = google.accounts.oauth2.initCodeClient({
        client_id: environment.googleProvider,
        scope: 'https://www.googleapis.com/auth/calendar.events',
        callback: (response) => {
          this.setupCodes(response);
        },
      });
      client.requestCode();
    }
  }

  setupCodes(response: any) {
    this.apiService.setAuthCode(response.code).then((c_res: any) => {
      this.userService.user.AuthCode = c_res.data.code;
      this.userService.user.RefreshToken = c_res.data.refreshToken;
      this.changeRef.detectChanges();
    }).catch(e => {
      console.log(response);
      console.log(e);
    });
  }

}
