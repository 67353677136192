import { Component, EventEmitter, OnInit, Output, ElementRef, ViewChild, Input, Injector } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { speedDialFabAnimations } from './speed-dial-fab.animations';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ApiService } from '../../services/api.service';
import { UserService } from '../../services/user.service';
import { ContemplationRoomSetup, ContemplationRoomSetupKeyValue } from '../../services/assistant.service';
import { KeyValue } from '@angular/common';
declare var $: any;

@Component({
  selector: 'sb-speed-dial-fab',
  templateUrl: './speed-dial-fab.component.html',
  styleUrls: ['./speed-dial-fab.component.scss'],
  animations: speedDialFabAnimations
})
export class SpeedDialFabComponent implements OnInit {

  @ViewChild('notesList') private notesList!: ElementRef;
  @Output() selected: EventEmitter<any> = new EventEmitter<any>();

  @Input() openJournal?: boolean = false;

  
  fabButtons = [
    {
      icon: 'description',
      title: 'Open Journal'
    },
    {
      icon: 'view_headline',
      title: 'Add new situation'
    }
  ];

  buttons: any = [];
  fabTogglerState = 'inactive';

  savingNote: boolean = false;

  journalTogglerState = 'inactive';
  loadingJournal: boolean = false;
  journalExpanded: boolean = false;
  notes: any = [];
  noteTags: any = [];
  newNote: any = {
    UserJournalTags: []
  };

  isLoggedIn: boolean = false;

  seenConversations: any = [];
  newConversations: any = [];
  
  contemplationRoomSetups: ContemplationRoomSetupKeyValue = {};
  assistant?: any;

  loadingRoom: boolean = false;
  currentContemplationRoom?: KeyValue<string, ContemplationRoomSetup>;

  constructor(
    private apiService: ApiService, 
    public userService: UserService, 
    private snackbar: MatSnackBar, 
    private dialog: MatDialog,
    private injector: Injector,
    // private assistant: AssistantService
  ) {

    // this.filteredTags = this.tagCtrl.valueChanges.pipe(
    //   startWith(null),
    //   map((tag: string | null) => tag ? this._filter(tag) : this.noteTags.slice()));
  }

  ngOnInit() {
    if (this.openJournal) {
      this.onToggleJournal();
    }

    this.userService.authChanged.subscribe((ob) => {
      if(ob) {
        // this.assistant = <AssistantService>this.injector.get(AssistantService);
        setTimeout(() => {
          this.subscribeSetups();
        }, 100);
      }
    });
  }
  
  ngOnDestroy(){
    console.log("Ng Destroy Called");
  }

  subscribeSetups() {
    // this.assistant.contemplationRoomSetups.subscribe((contemplationRoomSetups: ContemplationRoomSetupKeyValue) => {
    //   this.contemplationRoomSetups = contemplationRoomSetups;
    //   // console.log(this.contemplationRoomSetups);
    //   // this.listenToContemplationRoom();
    // });
  }

  openContemplationRoom(room: KeyValue<string, ContemplationRoomSetup>) {
    console.log("Opening contemplation room: ", room);
    this.loadingRoom = true;
    this.currentContemplationRoom = room;
    setTimeout(() => {
      this.loadingRoom = false;
    }, 1000);
  }

  showItems() {
    this.fabTogglerState = 'active';
    this.buttons = this.fabButtons;
  }

  hideItems() {
    this.fabTogglerState = 'inactive';
    this.buttons = [];
  }

  onToggleFab() {
    this.buttons.length ? this.hideItems() : this.showItems();
  }

  onToggleJournal() {
    this.expandJournal();
    if (this.journalTogglerState == 'active') {
      this.journalTogglerState = 'inactive';
    } else {
      this.journalTogglerState = 'active';
      this.seenConversations = this.newConversations;
      // this.loadJournal();
    }
  }

  expandJournal() {
    this.journalExpanded = !this.journalExpanded;
  }

  showBadge(event: any) {
    if (event) {
      this.newConversations = event;
      if (this.journalTogglerState == 'active') {
        this.seenConversations = this.newConversations;
      }
    }
  }

  select(btn: any) {
    this.selected.emit(btn);
    this.onToggleFab();
  }

  loadJournal() {
    this.loadingJournal = true;
    this.apiService
      .getUserJournal()
      .then((res: any) => {
        this.notes = res.data.notes;
        this.noteTags = res.data.tags;
        this.loadingJournal = false;
        this.scrollToBottom();
      })
      .catch(e => {
        if (!e.msg) {
          e.msg = 'Something went wrong. Please try again';
        }
        this.snackbar.open(e.msg);
        this.loadingJournal = false;
      });
  }


  separatorKeysCodes: number[] = [ENTER, COMMA];
  tagCtrl = new FormControl();
  // filteredTags!: Observable<any[]>;

  @ViewChild('tagInput') tagInput!: ElementRef<HTMLInputElement>;
  // @ViewChild('auto') matAutocomplete!: MatAutocomplete;

  // add(event: MatChipInputEvent): void {
  //   const input = event.input;
  //   const value: any = event.value;
  //   // Add our fruit
  //   if ((value || '').trim()) {
  //     this.newNote.UserJournalTags.push({
  //       UserTagId: 0,
  //       UserTag: {
  //         Label: value
  //       }
  //     });
  //   }

  //   // Reset the input value
  //   if (input) {
  //     input.value = '';
  //   }

  //   this.tagCtrl.setValue(null);
  // }

  removeNoteTag(tag: any, index: number): void {
    if (index >= 0) {
      this.newNote.UserJournalTags.splice(index, 1);
    }
  }

  // selectTag(event: MatAutocompleteSelectedEvent): void {
  //   console.log(event);
  //   this.newNote.UserJournalTags.push({
  //     UserTagId: event.option.value.UserTagId,
  //     UserTag: event.option.value
  //   });
  //   this.tagInput.nativeElement.value = '';
  //   this.tagCtrl.setValue(null);
  // }


  private _filter(value: string): any[] {
    console.log(value);
    const filterValue = value.toLowerCase();
    return this.noteTags.filter((tag: any) => tag.Label.toLowerCase().indexOf(filterValue) === 0);
  }

  saveNote() {
    if (!this.newNote.Text || this.newNote.Text.trim().length == 0) {
      return;
    }
    this.savingNote = true;
    this.processtags(this.newNote.UserJournalTags).then((res: any) => {
      var data = JSON.parse(JSON.stringify(this.newNote));
      data.UserJournalTags = res;
      this.apiService.addToJournal(data).then((res: any) => {
        // this.noteTags.push(res.data.newUserTags);
        this.notes.push(res.data.userJournal);
        this.savingNote = false;
        this.newNote = {
          UserJournalTags: []
        };
        this.scrollToBottom();
      }).catch(e => {
        if (!e.msg) {
          e.msg = 'Something went wrong. Please try again';
        }
        this.snackbar.open(e.msg);
        this.savingNote = false;
      });
    });
  }

  processtags(tags: any) {
    var journaltags = JSON.parse(JSON.stringify(tags));
    return new Promise((res, rej) => {
      res(journaltags);
      // if(journaltags.length == 0){
      //   res(journaltags);
      // } else {
      //   var x = 0;
      //   journaltags.forEach((userTag: any) => {
      //     if(userTag.UserTagId && userTag.UserTagId != 0){
      //       userTag.UserTag = undefined;
      //     } else {
      //       userTag.UserTagId = 0;
      //     }
      //     x++;
      //     if (x == journaltags.length){
      //       res(journaltags);
      //     }
      //   });
      // }
    });
  }

  createNewUserTag() {

    // const subjectDialogRef = this.dialog.open(PromptDialogComponent, {
    //   width: '450px',
    //   data: {
    //     title: "Create new tag",
    //     positive: "Create",
    //     isrequired: true,
    //     error: "Tag is required",
    //     label: "Enter tag label",
    //   }
    // });

    // subjectDialogRef.afterClosed().subscribe(result => {
    //   console.log(result);
    //   if (result) {
    //     this.apiService.addUserTag(result.label).then((res: any) => {
    //       this.noteTags.push(res.data);
    //       this.snackbar.open("Tag added");
    //     }).catch(e => {
    //       if (!e.msg) {
    //         e.msg = "Unable to add new tag. Please try again";
    //       }
    //       this.snackbar.open(e.msg);
    //     });
    //   }
    // });
  }

  scrollToBottom(): void {
    setTimeout(() => {
      $("#notesList").animate({ scrollTop: $('#notesList').prop("scrollHeight") }, 100);
    }, 50);
    // try {
    //   this.notesList.nativeElement.scrollTop = this.notesList.nativeElement.scrollHeight;
    // } catch (err) {
    //   console.log(err);
    // }
  }
}
