<div class="dialog-box bg-grey">
  <h1>{{ data.title }}</h1>
  <div mat-dialog-content class="dialog-content overflow-auto" *ngIf="!data.message">
    <p class="p">Congratulations on getting started with Contemplate.</p>
    <p class="p">We will do a lot of work together over the next few months and you will see a conspicuous positive change in yourself in just a few days. I assure you.</p>
    <p class="p">Please go through the weekly modules of the program. Spend ten minutes on your Adioscope each day. </p>
    <p class="p">Do not forget to join the live Contemplation practice sessions. During these sessions, I will review your Contemplation practice and your progress on your Adioscope situations. </p>
    <p class="p">These sessions keep you charged up and ensure things get done.</p>
    <p class="p">If you need any help, please write to me on tarun@contemplate.life</p>
    <p class="p">See you at the next live Contemplation session. We will send you an email about it.</p>
    <p class="p"><br>Warmly,<br>Tarun <br> Founder & CEO</p>
  </div>
  <div mat-dialog-content class="dialog-content overflow-auto" *ngIf="data.message" [innerHtml]="data.message"></div>
  <div class="action mt-4 text-center">
    <app-adios-btn class="action-button" highlight="teal" [addmargin]="false" [addmarginleft]="false"
    (click)="onPositiveClick()">
    GET STARTED
    </app-adios-btn>
  
  </div>
</div>