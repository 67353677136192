import { Injectable } from '@angular/core';
// import { HTTP } from '@awesome-cordova-plugins/http/ngx';
import { UserService } from '../user.service';
import { CapacitorHttp } from '@capacitor/core';

@Injectable({
  providedIn: 'root'
})
export class NativeHttpService {

  constructor(
    // private http: HTTP,
    private userService: UserService
  ) {
  }

  public getFile(url: string, contentType: string) {
    return new Promise((resolve, reject) => {
      this.getTokenHeader()
        .then((token: any) => {
          const httpOptions = {
            headers: {
              'Content-Type': contentType,
              'Token': token,
              'Origin': 'com.adios.core',
              'offset': this.getTimeZoneOffset()
            },
            responseType: 'blob' as 'json'
          };
          CapacitorHttp.request({
            method: 'GET',
            url,
            headers: httpOptions.headers,
            responseType: httpOptions.responseType
          }).then((response: any) => {
            console.log('No Error in get ', response);
            resolve(response);
          }).catch((error: any) => {
            console.log('Error in get ', error);
            reject(error);
          });
        })
        .catch((error: any) => {
          console.log('Error getting token header: ', error);
          reject(error);
        });
    });
  }
  public getDataFromUrl(url: string) {
    return new Promise((res, rej) => {
      this.getTokenHeader().then((token: any) => {
        const httpOptions = {
          headers: {
            'Content-Type': 'application/json',
            'Token': token,
            'Origin': 'com.adios.core',
            'offset': this.getTimeZoneOffset()
          },
          withCredentials: true,
          responseType: 'blob' as 'json'
        };

        CapacitorHttp.request({
          method: 'GET',
          url: url,
          headers: httpOptions.headers,
          responseType: 'json'
        }).then((r: any) => {
          res(r.data);
        }).catch((e: any) => {
          rej(e);
        });
      });
    });
  }

  public postFormDataToUrl(url: string, data: any) {
    return new Promise((res, rej) => {
      this.getTokenHeader().then((token: any) => {
        const httpOptions = {
          headers: {
            'Token': token,
            'Origin': 'com.adios.core',
            'offset': this.getTimeZoneOffset()
          },
          responseType: 'blob' as 'json'
        };
        CapacitorHttp.post({
          method: 'POST',
          url: url,
          data: data,
          headers: httpOptions.headers,
          // responseType: 'json'
        }).then((r: any) => {
          res(r.data);
        }).catch((e: any) => {
          rej(e);
        });
      });
    });
  }


  public postDataToUrl(url: string, data: any) {
    return new Promise((res, rej) => {
      this.getTokenHeader().then((token: any) => {
        const httpOptions = {
          headers: {
            'Content-Type': 'application/json',
            'Token': token,
            'Origin': 'com.adios.core',
            'offset': this.getTimeZoneOffset()
          },
          responseType: 'blob' as 'json'
        };
        CapacitorHttp.post({
          method: 'POST',
          url: url,
          data: data,
          headers: httpOptions.headers,
          // responseType: 'json'
        }).then((r: any) => {
          res(r.data);
        }).catch((e: any) => {
          rej(e);
        });
      });
    });
  }

  public deleteDataFromUrl(url: string) {
    return new Promise((res, rej) => {
      this.getTokenHeader().then((token: any) => {
        const httpOptions = {
          headers: {
            'Content-Type': 'application/json',
            'Token': token,
            'Origin': 'com.adios.core',
            'offset': this.getTimeZoneOffset()
          }
        };
        CapacitorHttp.delete({
          method: 'DELETE',
          url: url,
          headers: httpOptions.headers,
          responseType: 'json'
        }).then((r: any) => {
          res(r.data);
        }).catch((e: any) => {
          rej(e);
        });
      });
    });
  }

  public putDataOnUrl(url: string, data: any) {
    return new Promise((res, rej) => {
      this.getTokenHeader().then((token: any) => {
        const httpOptions = {
          headers: {
            'Content-Type': 'application/json',
            'Token': token,
            'Origin': 'com.adios.core',
            'offset': this.getTimeZoneOffset()
          }
        };
        CapacitorHttp.put({
          method: 'PUT',
          url: url,
          data: data,
          headers: httpOptions.headers,
          responseType: 'json'
        }).then((r: any) => {
          res(r.data);
        }).catch((e: any) => {
          rej(e);
        });
      });
    });
  }




  // public getFile(url: string, contentType: string) {
  //   return new Promise((res, rej) => {
  //     this.getTokenHeader().then((token: any) => {
  //       var httpOptions = {
  //         'Content-Type': contentType,
  //         responseType: 'blob' as 'json',
  //         'Token': token,
  //         'Origin': 'com.adios.venus',
  //         'offset': this.getTimeZoneOffset()
  //       };

  //       this.http.setDataSerializer('json');


  //       this.http.get(url, {}, httpOptions).then((r: any) => {
  //         console.log("No Error in get ", r);
  //         res(r);
  //         // res({ success: true, data: r.body, response: r });
  //       }).catch(e => {
  //         rej(e);
  //         // //console.log("Error in get", e);
  //         // if (!e.ok && (e.status < 200 || e.status >= 300)) {
  //         //   if (e.status == 422) {
  //         //     rej({ success: false, data: e, msg: "helper not found" });
  //         //   } else if (e.status == 401) {
  //         //     rej({ success: false, data: e, msg: "Session Expired" });
  //         //   } else if (e.status == 404) {
  //         //     rej({ success: false, data: e, msg: "Unable to complete your request" });
  //         //   } else if (e.status == 403) {
  //         //     rej({ success: false, data: e, msg: "Unauthorized access" });
  //         //   } else if (e.status == 406) {
  //         //     rej({ success: false, data: e, msg: "Unable to update the record as its been linked somewhere" });
  //         //   } else {
  //         //     rej({ success: false, data: e });
  //         //   }
  //         // }
  //       });
  //     });
  //   });
  // }

  // public getDataFromUrl(url: string) {
  //   return new Promise((res, rej) => {
  //     this.getTokenHeader().then((token: any) => {
  //       var httpOptions = {
  //         'Content-Type': 'application/json',
  //         'Token': token,
  //         'Origin': 'com.adios.venus',
  //         'offset': this.getTimeZoneOffset()
  //       };

  //       this.http.setDataSerializer('json');
  //       this.http.get(url, {}, httpOptions).then((r: any) => {
  //         // console.log("No Error in get ", r);
  //         // res({ success: true, data: r.body, response: r });
  //         res(JSON.parse(r.data));
  //       }).catch(e => {
  //         rej(e);
  //         //console.log("Error in get", e);
  //         // if (!e.ok && (e.status < 200 || e.status >= 300)) {
  //         //   if (e.status == 422) {
  //         //     rej({ success: false, data: e, msg: "helper not found" });
  //         //   } else if (e.status == 401) {
  //         //     rej({ success: false, data: e, msg: "Session Expired" });
  //         //   } else if (e.status == 404) {
  //         //     rej({ success: false, data: e, msg: "Unable to complete your request" });
  //         //   } else if (e.status == 403) {
  //         //     rej({ success: false, data: e, msg: "Unauthorized access" });
  //         //   } else if (e.status == 406) {
  //         //     rej({ success: false, data: e, msg: "Unable to update the record as its been linked somewhere" });
  //         //   } else {
  //         //     rej({ success: false, data: e });
  //         //   }
  //         // }
  //       });
  //     });
  //   });

  // }

  // public postDataToUrl(url: string, data: any) {
  //   return new Promise((res, rej) => {

  //     this.getTokenHeader().then((token: any) => {

  //       var httpOptions = {
  //         'Content-Type': 'application/json',
  //         'Token': token,
  //         'Origin': 'com.adios.venus',
  //         'offset': this.getTimeZoneOffset()
  //       };

  //       this.http.setDataSerializer('json');
  //       this.http.post(url, data, httpOptions).then((r: any) => {
  //         console.log(JSON.stringify(r));
  //         res(JSON.parse(r.data));
  //       }).catch(e => {
  //         rej(e);
  //         // if (!e.ok && (e.status < 200 || e.status >= 300)) {
  //         //   if (e.status == 422) {
  //         //     rej({ success: false, data: e, msg: "helper not found" });
  //         //   } else if (e.status == 401) {
  //         //     rej({ success: false, data: e, msg: "Session Expired" });
  //         //   } else if (e.status == 404) {
  //         //     rej({ success: false, data: e, msg: "Unable to complete your request" });
  //         //   } else if (e.status == 403) {
  //         //     rej({ success: false, data: e, msg: "Unauthorized access" });
  //         //   } else if (e.status == 406) {
  //         //     rej({ success: false, data: e, msg: "Unable to update the record as its been linked somewhere" });
  //         //   } else {
  //         //     rej({ success: false, data: e });
  //         //   }
  //         // }
  //       });
  //     });
  //   });
  // }

  // public deleteDataFromUrl(url: string) {
  //   return new Promise((res, rej) => {
  //     this.getTokenHeader().then((token: any) => {
  //       var httpOptions = {
  //         'Content-Type': 'application/json',
  //         'Token': token,
  //         'Origin': 'com.adios.venus',
  //         'offset': this.getTimeZoneOffset()
  //       };

  //       this.http.setDataSerializer('json');
  //       this.http.delete(url, {}, httpOptions).then((r: any) => {
  //         // res({ success: true, data: r._body });
  //         res(r);
  //       }).catch(e => {
  //         rej(e);
  //         //console.log("Error in get", e);
  //         // if (!e.ok && (e.status < 200 || e.status >= 300)) {
  //         //   if (e.status == 422) {
  //         //     rej({ success: false, data: e, msg: "helper not found" });
  //         //   } else if (e.status == 401) {
  //         //     rej({ success: false, data: e, msg: "Session Expired" });
  //         //   } else if (e.status == 404) {
  //         //     rej({ success: false, data: e, msg: "Unable to complete your request" });
  //         //   } else if (e.status == 403) {
  //         //     rej({ success: false, data: e, msg: "Unauthorized access" });
  //         //   } else if (e.status == 406) {
  //         //     rej({ success: false, data: e, msg: "Unable to update the record as its been linked somewhere" });
  //         //   } else {
  //         //     rej({ success: false, data: e.error });
  //         //   }
  //         // }
  //       });
  //     });
  //   });
  // }

  // public putDataOnUrl(url: string, data: any) {
  //   return new Promise((res, rej) => {
  //     this.getTokenHeader().then((token: any) => {
  //       var httpOptions = {
  //         'Content-Type': 'application/json',
  //         'Token': token,
  //         'Origin': 'com.adios.venus',
  //         'offset': this.getTimeZoneOffset()
  //       };

  //       this.http.setDataSerializer('json');
  //       this.http.put(url, data, httpOptions).then((r: any) => {
  //         console.log(r);
  //         // res({ success: true, data: r });
  //         res(r);
  //       }).catch(e => {
  //         rej(e);
  //         //console.log("Error in get", e);
  //         // if (!e.ok && (e.status < 200 || e.status >= 300)) {
  //         //   if (e.status == 422) {
  //         //     rej({ success: false, data: e, msg: "helper not found" });
  //         //   } else if (e.status == 401) {
  //         //     rej({ success: false, data: e, msg: "Session Expired" });
  //         //   } else if (e.status == 404) {
  //         //     rej({ success: false, data: e, msg: "Unable to complete your request" });
  //         //   } else if (e.status == 403) {
  //         //     rej({ success: false, data: e, msg: "Unauthorized access" });
  //         //   } else if (e.status == 406) {
  //         //     rej({ success: false, data: e, msg: "Unable to update the record as its been linked somewhere" });
  //         //   } else {
  //         //     rej({ success: false, data: e });
  //         //   }
  //         // }
  //       });
  //     });
  //   });
  // }

  getTokenHeader() {
    return new Promise((r, j) => {
      this.userService.getToken().then((token: any) => {
        if (token) {
          r(token);
        } else {
          r('n');
        }
      }).catch(e => {
        r('n');
      });
    });
  }

  getTimeZoneOffset() {
    var d = new Date();
    return d.getTimezoneOffset().toString();
  }

}
