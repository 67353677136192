<div class="notes-box">
    <ng-container *ngIf="loadingJournal">
        <div class="box-container content-center">
            <div class="spinner-border" role="status" *ngIf="loadingJournal">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="!loadingJournal">
        <div class="list-box p-0" *ngIf="notes.length == 0">
            <div class="row m-0 w-100">
                <div class="col-md-12 col-md-12 dflex content-center">
                    <div class="box content-center">
                        <p>No notes yet</p>
                    </div>
                </div>
            </div>
        </div>
        <div #notesList id="notesList" class="message-list scrollbox" *ngIf="notes.length > 0" infiniteScroll
            [infiniteScrollUpDistance]="2" [infiniteScrollDistance]="2" [scrollWindow]="false" [alwaysCallback]="true"
            (scrolledUp)="onUp()" (scrolled)="onScrollDown()">

            <div class="spinner-border inline-center" role="status" *ngIf="loadingPreviousNotes">
                <span class="sr-only">Loading...</span>
            </div>
            <ng-container *ngFor="let note of notes; let i = index;">
                <div class="message-card" [id]="note.UserJournalId">
                    <p>{{ note.Text }}</p>
                    <p class="timestamp">
                        <small *ngIf="note.CreatedOn.indexOf('Z') == -1">{{ note.CreatedOn + 'Z' | date: 'MMM d, y, h:mm a': dateOffset}}</small>
                        <small *ngIf="note.CreatedOn.indexOf('Z') != -1">{{ note.CreatedOn | date: 'MMM d, y, h:mm a': dateOffset}}</small>
                    </p>
                </div>
            </ng-container>
        </div>

        <div class="message-input-box w-100">
            <mat-form-field appearance="outline" class="mr-2" style="flex: 1;">
                <textarea [disabled]="savingNote" matInput class="message-input contemplation-space"
                    placeholder="Start typing..." [(ngModel)]="newNote.Text"></textarea>
            </mat-form-field>
            <div class="action-box">
                <div class="spinner-border p-2" *ngIf="savingNote" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
                <ng-container *ngIf="!savingNote">
                    <button mat-icon-button aria-label="Save"
                        [disabled]="!newNote.Text || newNote.Text.trim().length == 0" (click)="saveNote()">
                        <mat-icon>send</mat-icon>
                    </button>
                </ng-container>
            </div>
        </div>

    </ng-container>

</div>