import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { AngularFireDatabase, DatabaseSnapshot } from '@angular/fire/compat/database';
import { UserService } from './user.service';
import { ApiService } from './api.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { DataSnapshot } from '@angular/fire/compat/database/interfaces';
import { KeyValue } from '@angular/common';
import { List } from 'immutable';

export enum MessageType {
  text,
  image,
  action,
  systemMessage,
}
export enum ContextAction {
  captureSituation,
  analyseSituation
}
export enum SystemMessageAction {
  openFavorites,
  captureDescription,
  captureText,
  reloadSituations,
  analyseNow,
  openRoom,
}
export enum UserAssistantContextType {
  actionContext,
  createSituation,
  analyseSituation,
  allFavorites,
  knowAdios,
  addToContemplationCalendar
}
export enum MessageStatus {
  sent,
  delivered,
  read,
  processed
}

export interface UserAction {
  ResponseReference?: string,
  MessageKey?: string,
  UserActionType ?: 'large-text',
  Title?: string,
  Placeholder?: string
}
export interface Conversation {
  Key: string,
  Type: MessageType,
  Message: String,
  SystemAction?: String,
  SystemActionParam?: string,
  Processed?:Boolean,
  Actions?: Map<String, String>,
  Options?: any,
  RepliedOn?: Conversation,
  RepliedOnKey?: string,
  Status: MessageStatus,
  IsZach: boolean,
  UserAction?: UserAction,
  SystemMessageAction?: Map<String, String>,
  CreatedOn?: Date | string,
  ValidTill?: Date,
  WaitNextMessage?: boolean,
  RequestNextMessage?: boolean,
};

export enum ContextType {
  actionContext,
  createSituation,
  analyseSituation,
  allFavorites,
  knowAdios
}

export interface AssistantContext {
  ContextType?: ContextType,
  ZacContextType?: string,
  UpdatedOn?: Date,
  Message?: string,
  Context?: any,
  LastSeenOn?: Date,
  LastMessage?: String,
  IsLastMessageByZach?: boolean
}

export enum AnalyseNextPropertyList {
  SituationV2Id,
  Title,
  Description,
  Importance,
  Urgency,
  PrincipleAnalysis,
  TriggerAnalysis,
}

export enum CreateSituationNextPropertyList {
  Title,
  Description,
  AnalyseNow,
}

export interface ProcessActionData {
  Action?: String,
  MessageKey?: String,
  TextMessage?: String,
  ContemplationRoom?: String,
  ContextNextAction?: AnalyseNextPropertyList | SystemMessageAction | CreateSituationNextPropertyList
}

export interface ContemplationRoomSetup {
  SetupRequired?: boolean,
  Type: UserAssistantContextType,
  NextAction: AnalyseNextPropertyList,
  Situations: any,
  SituationPage: Number,
  SituationSize: Number,
  TextBoxTitle: String,
  TextBoxPlaceholder: String,
  UserId?: Number,
  FirstName: String,
  LastName: String,
  SituationV2Id: Number,
  Title: String,
  Description: String,
  IsCleared?: Boolean,
  Importance?: Boolean,
  Urgency?: Number,
  SituationCreatedOn: Date,
  RoomStartedOn: Date,
  PrincipleV2Id: Number,
  PrincipleTriggerV2Id: Number,
  NextProperty?: String,
  UpdatedOn?: Date,
  Cycles?: Number,
  LastSeenOn?: Date,
  LastMessage?: String,
  IsLastMessageByZach?: boolean

}
export interface ContemplationRoomSetupKeyValue {
  [key: string]: ContemplationRoomSetup;
}

export interface ContemplationRoomConversationKeyValue {
  [key: string]: BehaviorSubject<List<Conversation>>;
}
@Injectable({
  providedIn: 'root'
})
export class AssistantService {

  isSetup: boolean = false;

  userId?: number;

  private _contemplationRoomSetups: BehaviorSubject<ContemplationRoomSetupKeyValue> = new BehaviorSubject({});
  public readonly contemplationRoomSetups: Observable<ContemplationRoomSetupKeyValue> = this._contemplationRoomSetups.asObservable();

  public contemplationRoomConversations: ContemplationRoomConversationKeyValue = {};

  private _conversations: BehaviorSubject<List<Conversation>> = new BehaviorSubject(List([]));
  public readonly conversations: Observable<List<Conversation>> = this._conversations.asObservable();

  private _context: BehaviorSubject<AssistantContext> = new BehaviorSubject({});
  public readonly context: Observable<AssistantContext> = this._context.asObservable();

  private _systemAction: BehaviorSubject<Conversation> = new BehaviorSubject(null);
  public readonly systemAction: Observable<Conversation> = this._systemAction.asObservable();

  private _loading: BehaviorSubject<boolean> = new BehaviorSubject(true);
  public readonly loading: Observable<boolean> = this._loading.asObservable();

  listeners: any = [];

  databaseBase: string;
  conversationBase: string;
  contextBase: string;
  contemplationRoomSetupUrl: string;
  contemplationRoomMessageUrl: string;

  apis: {
    setupAssistant?: string,
    requestMessageInMain?: string,
    requestMessageInRoom?: string,
    processAction?: string,
    processRoomAction?: string,
  } = {
      setupAssistant: 'api/Assistant/SetupAssistant',
      requestMessageInMain: 'api/Assistant/RequestMessageInMain',
      requestMessageInRoom: 'api/Assistant/RequestMessageInRoom/',
      processAction: 'api/Assistant/ProcessAction',
      processRoomAction: 'api/Assistant/ProcessContemplationRoomAction',
    }

  constructor(
    private platform: Platform,
    private user: UserService,
    private db: AngularFireDatabase,
    private apiService: ApiService,
  ) {

    if (this.user.isloggedin && this.user.user.IsBeta) {
      setTimeout(() => {
        this.userId = this.user?.user?.UserId;
        this.databaseBase = "/assistant/" + this.userId + '/';
        this.conversationBase = this.databaseBase + "messages/";
        this.contextBase = this.databaseBase + "context/";
        this.contemplationRoomSetupUrl = this.databaseBase + "/contemplation-rooms/setup/";
        this.contemplationRoomMessageUrl = this.databaseBase + "/contemplation-rooms/messages/";
  
        //Setup Listeners
        this.setupAssistant();
  
      }, 1000);
    }

    this.user.authChanged.subscribe((ob) => {
      if (!ob) {
        this.unsubscribe();
      }
    });

  }

  unsubscribe(){
    this.listeners.forEach((query) => {
      
    });
  }

  //Setup the assistant for this user
  setupAssistant() {
    this._loading.next(true);
    this.apiService.getDataFromUrl(this.apiService.domain + this.apis.setupAssistant).then((res: any) => {
      this.getConversation();
      // setTimeout(() => {
        this._loading.next(false);
      // }, 1000);
    }).catch(e => {
      console.log(e);
      this._loading.next(false);
    });
  }

  requestNextMessageInMain() {
    this.apiService.getDataFromUrl(this.apiService.domain + this.apis.requestMessageInMain).then((res: any) => {
      
    }).catch(e => {
      console.log(e);
    });
  }

  requestNextMessageInRoom(roomId: any) {
    this.apiService.getDataFromUrl(this.apiService.domain + this.apis.requestMessageInRoom + roomId).then((res: any) => {
      
    }).catch(e => {
      console.log(e);
    });
  }

  //Start Listening to the conversation
  getConversation() {
    this.db.database.ref(this.conversationBase).orderByKey().limitToLast(20).once('value', (data: DataSnapshot) => {
      var messages = data.val();
      if (messages) {
        console.log("Messages: ", messages);
        Object.keys(messages).forEach(element => {
          messages[element].Key = element;
        });
        this._conversations.next(this._conversations.getValue().concat(Object.values(messages)));

        // this.conversations = this.conversations.concat(Object.values(messages));
        var keys = Object.keys(messages);
        if (keys.length > 0) {
          this.startListeningToConversation(keys[keys.length - 1]);
        } else {
          this.startListeningToConversation();
        }
      } else {
        console.log("No messages found");
        this.startListeningToConversation();
      }
      this.listenToCurrentContext();
      this.listenToContemplationRoomSetups();
    });
  }

  listenToCurrentContext() {
    this.db.database.ref(this.contextBase).on('value', (data: DataSnapshot) => {
      var context: AssistantContext = data.val();
      console.log("Context: ", context);
      if (context != null) {
        this._context.next(context);
      }
    });
  }

  listenToContemplationRoomSetups() {
    this.db.database.ref(this.contemplationRoomSetupUrl)
      .on('child_added', (data: DataSnapshot) => {
        var situationId = parseInt(data.key);
        var context: ContemplationRoomSetup = data.val();
        if (context != null) {
          this._contemplationRoomSetups.getValue()[situationId] = context;
          this._contemplationRoomSetups.next(this._contemplationRoomSetups.getValue());

          // start listening to situation messages; 
          if (!this.contemplationRoomConversations[situationId]) {
            this.contemplationRoomConversations[situationId] = new BehaviorSubject<List<Conversation>>(List([]));
            this.getRoomConversation(situationId);
          }
        }
      });

    this.db.database.ref(this.contemplationRoomSetupUrl)
      .on('child_changed', (data: DataSnapshot) => {
        var situationId = data.key;
        var context: ContemplationRoomSetup = data.val();
        console.log("contemplation room updated: " + situationId + ": ", context);
        if (context != null) {
          this._contemplationRoomSetups.getValue()[situationId] = context;
          this._contemplationRoomSetups.next(this._contemplationRoomSetups.getValue());
        }
      });
  }

  getRoomConversation(situationId: any) {
    // console.log("Getting contemplation room conversation messages");
    this.db.database.ref(this.contemplationRoomMessageUrl + situationId)
      .orderByKey()
      .limitToLast(20)
      .once('value', (data: DataSnapshot) => {
        var messages = data.val();
        if (messages) {
          // console.log("Messages: ", messages);
          Object.keys(messages).forEach(element => {
            messages[element].Key = element;
          });
          this.contemplationRoomConversations[situationId].next(this.contemplationRoomConversations[situationId].getValue().concat(Object.values(messages)));

          var keys = Object.keys(messages);
          if (keys.length > 0) {
            this.startListeningToContemplationRoomConversation(situationId, keys[keys.length - 1]);
          } else {
            this.startListeningToContemplationRoomConversation(situationId);
          }
        } else {
          // console.log("No messages found");
          this.startListeningToContemplationRoomConversation(situationId);
        }
      });
  }

  startListeningToConversation(afterKey?: string) {
    console.log(this.conversationBase);
    var query = this.db.database.ref(this.conversationBase).orderByKey();
    if (afterKey) {
      query = query.startAfter(afterKey)
    }
    var listener = query.on('child_added', (data: DataSnapshot) => {
      var message: Conversation = data.val();
      if (message != null) {
        console.log("Added message: ", message);
        message.Key = data.key;
        if (message.Type != MessageType.systemMessage) {
          this.db.database.ref(this.conversationBase + data.key).update({ Key: data.key });
          this._conversations.next(this._conversations.getValue().push(message));
        } else {
          this.processSystemMessage(message);
        }
      } else {
        console.log("Message not found");
      }
    });
    // listener.
  }

  getPreviousMessages(firstMessageKey: string) {
    return new Promise((res, rej) => {
      this.db.database.ref(this.conversationBase)
        .endAt(firstMessageKey)
        .orderByKey()
        .limitToLast(20).once('value', (data: DataSnapshot) => {
          var messages = data.val();
          if (messages) {
            console.log("Previous Messages: ", messages);
            Object.keys(messages).forEach(element => {
              messages[element].Key = element;
            });
            var messageArr: any = Object.values(messages).concat(this._conversations.getValue());
            // this._conversations.next(messageArr);
            res(messageArr);
            // // this.conversations = this.conversations.concat(Object.values(messages));
            // var keys = Object.keys(messages);
            // if (keys.length > 0) {
            //   this.startListeningToConversation(keys[keys.length - 1]);
            // } else {
            //   this.startListeningToConversation();
            // }
          } else {
            res([]);
          }
        });
    });
  }

  startListeningToContemplationRoomConversation(situationId: number, afterKey?: string) {
    var query = this.db.database.ref(this.contemplationRoomMessageUrl + situationId).orderByKey();
    if (afterKey) {
      query = query.startAfter(afterKey)
    }
    query.on('child_added', (data: DataSnapshot) => {
      var message: Conversation = data.val();
      // console.log("Message added in contemplation room: ",message);
      if (message != null) {
        message.Key = data.key;
        if (message.Type != MessageType.systemMessage) {
          this.db.database.ref(this.contemplationRoomMessageUrl + situationId + '/' + data.key).update({ Key: data.key });
          this.contemplationRoomConversations[situationId].next(this.contemplationRoomConversations[situationId].getValue().push(message));
        }
      } else {
        // console.log("Message not found");
      }
    });
  }

  getPreviousContemplationRoomConversations(situationId: number, firstMessageKey: string) {
    return new Promise((res, rej) => {
      this.db.database.ref(this.contemplationRoomMessageUrl + situationId)
        .endAt(firstMessageKey)
        .orderByKey()
        .limitToLast(20)
        .once('value', (data: DataSnapshot) => {
          var messages = data.val();
          if (messages) {
            Object.keys(messages).forEach(element => {
              messages[element].Key = element;
            });
            var messageArr: any = Object.values(messages).concat(this._contemplationRoomSetups[situationId].getValue());
            // this._conversations.next(messageArr);
            res(messageArr);
          } else {
            res([]);
          }
        });
    });
  }

  processSystemMessage(message: Conversation) {
    console.log("Processing system message: ", message);
    if (message.Type == MessageType.systemMessage) {
      if(!message.Processed) {
        this.db.database.ref(this.conversationBase + message.Key).update({ Processed: true });
        this._systemAction.next(message);
      }
    }
  }

  //Send Message 
  sendMessage(type: MessageType, message: string, room?: any) {
    if (!room) {

      var conversation: Conversation = {
        Key: null,
        Type: type,
        Message: message,
        Status: 1,
        CreatedOn: new Date().toISOString(),
        IsZach: false
      };
      var c = this.db.database.ref(this.conversationBase).push(conversation);

      this.db.database.ref(this.contextBase).update({
        LastSeenOn: new Date().toISOString(),
        LastMessage: message,
        IsLastMessageByZach: false
      });

    } else {
      //send message in the room
      var conversation: Conversation = {
        Key: null,
        Type: type,
        Message: message,
        Status: 1,
        CreatedOn: new Date().toISOString(),
        IsZach: false
      };
      var c = this.db.database.ref(this.contemplationRoomMessageUrl + room.SituationV2Id).push(conversation);

      this.db.database.ref(this.contemplationRoomSetupUrl + room.SituationV2Id).update({
        LastSeenOn: new Date().toISOString(),
        LastMessage: message,
        IsLastMessageByZach: false
      });
    }
  }

  updateLastReadOnContext(room?: ContemplationRoomSetup) {
    if (!room) {
      this.db.database.ref(this.contextBase).update({
        LastSeenOn: new Date().toISOString()
      });
    } else {
      this.db.database.ref(this.contemplationRoomSetupUrl + room.SituationV2Id).update({
        LastSeenOn: new Date().toISOString()
      });
    }
  }

  //Setup the assistant for this user
  processAction(action: ProcessActionData) {
    return new Promise((result, reject) => {
      this.apiService.postDataToUrl(this.apiService.domain + this.apis.processAction, action).then((res: any) => {
        result(res);
      }).catch(e => {
        console.log(e);
        reject(e);
      });
    });
  }

  //Setup the assistant for this user
  processRoomAction(action: ProcessActionData) {
    return new Promise((result, reject) => {
      this.apiService.postDataToUrl(this.apiService.domain + this.apis.processRoomAction, action).then((res: any) => {
        result(res);
      }).catch(e => {
        console.log(e);
        reject(e);
      });
    });
  }


  sortContemplationRoomSetupKeyValueByUpdatedOnDesc(contemplationRoomSetupKeyValue: ContemplationRoomSetupKeyValue): any {
    return new Promise((res, rej) => {
      var keyValueArr: KeyValue<number, ContemplationRoomSetup>[] = Object.entries(contemplationRoomSetupKeyValue).map(([key, value]) => ({ key: +key, value }));
      // sort the array based on the UpdatedOn property in descending order
      keyValueArr = keyValueArr.sort((a, b) => {
        return new Date(b.value.UpdatedOn).getTime() - new Date(a.value.UpdatedOn).getTime()
      });
      setTimeout(() => {
        // create a new object with the same keys as the original object, but with the sorted values
        // console.log(keyValueArr);
        res(keyValueArr);
        // var sortedContemplationRoomSetupKeyValue: ContemplationRoomSetupKeyValue = {};
        // keyValueArr.forEach(({ key, value }) => {
        //   sortedContemplationRoomSetupKeyValue[key] = value;
        // });
        // setTimeout(() => {
        //   console.log(sortedContemplationRoomSetupKeyValue);
        //   res(sortedContemplationRoomSetupKeyValue);
        // }, 100);
      }, 100);
    })
  }
}
