import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { HttpClient } from '@angular/common/http';
import { UserService } from 'src/app/shared/services/user.service';
import { ApiService } from './api.service';
import { CommonService } from './common.service';

@Injectable()
export class AudioService {
  cachedPlayer: any = {};
  currentPlaylist: any;
  currentPlaylistContent: any = [];
  currentIndex: any;
  type: any;
  currentPlayer: HTMLAudioElement;

  currentTrack: any = {};
  progress: any = {};

  playbackSpeed: any = 1;

  private state: any = {
    loading: false,
    loaded: false,
    playing: false,
    paused: false,
    readableCurrentTime: '',
    readableRemainingTime: '',
    readableDuration: '',
    duration: undefined,
    currentTime: undefined,
    remainingTime: undefined,
    volume: 0.5,
    canplay: false,
    playbackSpeed: 1,
    error: false,
  };
  private stop$ = new Subject();
  private audioObj = new Audio();
  audioEvents = [
    "ended",
    "error",
    "play",
    "playing",
    "pause",
    "timeupdate",
    "canplay",
    "loadedmetadata",
    "loadstart",
    "seeking",
    "ratechange",
    "seeked"
  ];
  
  

  private stateChange: BehaviorSubject<any> = new BehaviorSubject(
    this.state
  );
  
  private eventChange: BehaviorSubject<any> = new BehaviorSubject({});

  private currentTrackObservable: BehaviorSubject<any> = new BehaviorSubject(
    this.currentTrack
  );

  private progressObservable: BehaviorSubject<any> = new BehaviorSubject(
    this.progress
  );

  constructor(
    private userService: UserService,
    private apiService: ApiService,
    private common: CommonService,
    private http: HttpClient
  ) {}

  setPlaybackSpeed(speed) {
    this.playbackSpeed = speed;
    if (this.audioObj) {
      this.audioObj.playbackRate = this.playbackSpeed;
    }
  }

  setPlayer(type: any, playlist: any, content: any, index: any) {
    this.type = type;
    this.currentPlaylist = playlist;
    this.currentPlaylistContent = content;
    this.currentIndex = index;

    this.currentTrack = {
      currentPlaylistTitle: playlist.Title,
      currentPlaylistId: playlist.Id,
      currentPlaylistContentTitle: content[index].Title,
      currentContentId: content[index].Id,
      currentIndex: index,
      contents: content,
      type: this.type,
      playlist: playlist,
      hasPrevious: index > 0 ? true : false,
      hasNext: index < content.length - 1 ? true : false, 
    }

    if (this.currentPlaylist && this.currentPlaylist.PlaylistId == playlist.PlaylistId) {
      this.skipTo(index);
    } else {
      this.play(index);
    }
  }

  play(index) {
    this.currentIndex = index;
    // if(!this.cachedPlayer[this.type]) {
    //   this.cachedPlayer[this.type] = {};
    // }
    // console.log("Checking from existing");
    // if (this.cachedPlayer[this.type][this.currentPlaylistContent[this.currentIndex].Id]) {
    //   this.currentPlayer = this.cachedPlayer[this.type][this.currentPlaylistContent[this.currentIndex].Id];
    // } else {
    //   this.currentPlayer = this.cachedPlayer[this.type][this.currentPlaylistContent[this.currentIndex].Id] = this.getAudioFor(this.currentIndex);
    // }

    const handler = (event: Event) => {
      this.updateStateEvents(event);
    };

    if(this.audioObj){
      this.audioObj.pause();
      this.audioObj.currentTime = 0;
      // remove event listeners
      this.removeEvents(this.audioObj, this.audioEvents, handler);
      // reset state
      this.resetState();
    }

    var data = this.currentPlaylistContent[index];
    this.currentTrack = {
      currentPlaylistTitle: this.currentPlaylist.Title,
      currentPlaylistId: this.currentPlaylist.Id,
      currentPlaylistContentTitle: data.Title,
      currentContentId: data.Id,
      currentIndex: index,
      type: this.type,
      contents: this.currentPlaylistContent,
      playlist: this.currentPlaylist,
      hasPrevious: index > 0 ? true : false,
      hasNext: index < this.currentPlaylistContent.length - 1 ? true : false, 
    }
    // this.preload();
    this.setMediaSessionInfo(index);
    this.setTrack(this.currentTrack, this.type);

    this.audioObj = new Audio(data.Url);
    this.audioObj.preload = "auto";
    this.audioObj.playbackRate = this.playbackSpeed;
    // this.audioObj.src = this.currentPlayer.src;
    this.audioObj.load();
    this.audioObj.play();
    // Show the pause button.
    console.log(this.audioObj.readyState);
    this.state.playbackSpeed = this.playbackSpeed;
    if (this.audioObj.readyState === 4) {
      console.log("player loaded");
      this.state.loading = false;
      this.state.loaded = true;
      this.state.seeking = false;
      this.state.duration = this.audioObj.duration;
      this.state.readableDuration = this.formatTime(this.state.duration);
      if (this.state.currentTime) {
        this.state.remainingTime = this.audioObj.duration - this.state.currentTime;
        this.state.readableRemainingTime = this.formatTime(this.state.remainingTime);
        this.state.readableCurrentTime = this.formatTime(this.state.currentTime);
      } else {
        this.state.readableCurrentTime = "00:00"
      }
      this.state.canplay = true;
    } else {
      this.state.loading = true;
      console.log("player loading");
    }
    this.addEvents(this.audioObj, this.audioEvents, handler);
    // this.stateChange.next(this.state);
    this.currentIndex = index;
  }

  preload() {
    if(!this.cachedPlayer[this.type]) {
      this.cachedPlayer[this.type] = {};
    }

    if(this.currentIndex > 0) {
      //Preload Previous
      if (!this.cachedPlayer[this.type][this.currentPlaylistContent[this.currentIndex - 1].Id]) {
        this.cachedPlayer[this.type][this.currentPlaylistContent[this.currentIndex - 1].Id] = this.getAudioFor(this.currentIndex - 1, true);
      }
    }

    if (this.currentIndex < this.currentPlaylistContent.length - 1) {
      //Preload next
      if (!this.cachedPlayer[this.type][this.currentPlaylistContent[this.currentIndex + 1].Id]) {
        this.cachedPlayer[this.type][this.currentPlaylistContent[this.currentIndex + 1].Id] = this.getAudioFor(this.currentIndex + 1, true);
      }
    }
  }

  markCompleted(contentId, meta) {
    this.progress[contentId] = meta;
    this.progressObservable.next(this.progress);
  }

  setProgress(progress){
    this.progress = progress;
    this.progressObservable.next(this.progress);
  }

  getAudioFor(index, preload ?: any) {
    var data = this.currentPlaylistContent[index];
    var audio = new Audio(data.Url);
    audio.preload = "auto";
    // audio.crossOrigin = "use-credentials";
    console.log("Loading audio" , index);
    if(preload) {
      // var currentVolume = this.currentPlayer.volume;
      // // audio.volume = 0;
      // // audio.play();
      // // audio.pause();
      // audio.volume = currentVolume;
    }

    return audio;
  }


  private addEvents(obj: any, events: any, handler: any) {
    events.forEach((event: any) => {
      obj.addEventListener(event, handler);
    });
  }

  private removeEvents(obj: any, events: any, handler: any) {
    events.forEach((event: any) => {
      obj.removeEventListener(event, handler);
    });
  }

  setMediaSessionInfo(index){
    // this.common.canUseNative().then((isNative: boolean) => {
    //   if (!isNative) {
        var data = this.currentPlaylistContent[index];
        console.log(this.currentTrack);
        /* Implementation of the Media Session API */
        if('mediaSession' in navigator) {
          navigator.mediaSession.metadata = new MediaMetadata({
              title: data.Title,
              album: this.currentPlaylist ? this.currentPlaylist.Title : '',
              // artwork: [
              //     { src: 'https://assets.codepen.io/4358584/1.300.jpg', sizes: '96x96', type: 'image/png' },
              //     { src: 'https://assets.codepen.io/4358584/1.300.jpg', sizes: '128x128', type: 'image/png' },
              //     { src: 'https://assets.codepen.io/4358584/1.300.jpg', sizes: '192x192', type: 'image/png' },
              //     { src: 'https://assets.codepen.io/4358584/1.300.jpg', sizes: '256x256', type: 'image/png' },
              //     { src: 'https://assets.codepen.io/4358584/1.300.jpg', sizes: '384x384', type: 'image/png' },
              //     { src: 'https://assets.codepen.io/4358584/1.300.jpg', sizes: '512x512', type: 'image/png' }
              // ]
          });
          if(this.currentTrack.hasNext) {
            navigator.mediaSession.setActionHandler('nexttrack', (details) => {
              this.skip('next')
            });
          } else {
            navigator.mediaSession.setActionHandler('nexttrack', null);
          }
          if(this.currentTrack.hasPrevious) {
            navigator.mediaSession.setActionHandler('previoustrack', (details) => {
              this.skip('prev');
            });
          } else {
            navigator.mediaSession.setActionHandler('previoustrack', null);
          }

          navigator.mediaSession.setActionHandler('play', () => {
            this.resume();
          });
          navigator.mediaSession.setActionHandler('pause', () => {
            console.log("play");
            this.pause();
          });
          // navigator.mediaSession.setActionHandler('seekbackward', (details) => {
          //   this.currentPlayer.seek(this.currentPlayer.seek() - (details.seekOffset || 10));
          // });
          // navigator.mediaSession.setActionHandler('seekforward', (details) => {
          //   this.currentPlayer.seek(this.currentPlayer.seek() + (details.seekOffset || 10));
          // });
          navigator.mediaSession.setActionHandler('seekto', (details) => {
            this.seek(details.seekTime);
          });
          navigator.mediaSession.setActionHandler('stop', () => {
              this.stop();
          });
        }
    //   }
    // });
  }

  resume() {
    if(this.audioObj)
      this.audioObj.play();
  }

  pause() {
    if(this.audioObj)
      this.audioObj.pause();
  }

  skip(direction) {
    // Get the next track based on the direction of the track.
    var index = 0;
    if (direction === 'prev') {
      index = this.currentIndex - 1;
      if (index < 0) {
        index = this.currentPlaylistContent.length - 1;
      }
    } else {
      index = this.currentIndex + 1;
      if (index >= this.currentPlaylistContent.length) {
        index = 0;
      }
    }

    this.skipTo(index);
  }

  skipTo(index) {
    if (this.cachedPlayer[this.type] && this.cachedPlayer[this.type][this.currentPlaylistContent[this.currentIndex].Id]) {
      this.cachedPlayer[this.type][this.currentPlaylistContent[this.currentIndex].Id].pause();
    }
    this.play(index);
  }

  volume(val) {
    if (this.audioObj) {
      this.audioObj.volume = val;
    }
  }

  seek(per) {
    if (this.audioObj) {
      this.audioObj.currentTime = per;
      // this.audioObj.currentTime = this.audioObj.duration * per;
    }
  }

  step = () => {
    if (this.audioObj) {
      this.state.currentTime = this.audioObj.currentTime;
      this.state.duration = this.audioObj.duration;
      this.state.remainingTime = this.audioObj.duration - this.state.currentTime;
      this.state.readableDuration = this.formatTime(this.state.duration);
      this.state.readableRemainingTime = this.formatTime(this.state.remainingTime);
      this.state.readableCurrentTime = this.formatTime(this.state.currentTime);
      this.stateChange.next(this.state);

      if (this.audioObj.paused || this.audioObj.ended) {
        return;
      }
      requestAnimationFrame(this.step);
    }
  }

  togglePlaylist() {
    // var self = this;
    // var display = (playlist.style.display === 'block') ? 'none' : 'block';

    // setTimeout(function() {
    //   playlist.style.display = display;
    // }, (display === 'block') ? 0 : 500);
    // playlist.className = (display === 'block') ? 'fadein' : 'fadeout';
  }

  toggleVolume() {
    // var self = this;
    // var display = (volume.style.display === 'block') ? 'none' : 'block';

    // setTimeout(function() {
    //   volume.style.display = display;
    // }, (display === 'block') ? 0 : 500);
    // volume.className = (display === 'block') ? 'fadein' : 'fadeout';
  }

  public resetState() {
    this.state = {
      playing: false,
      paused: false,
      readableCurrentTime: '',
      readableDuration: '',
      duration: undefined,
      currentTime: undefined,
      volume: 1,
      canplay: false,
      error: false,
      seeking: false
    };
    this.stateChange.next(this.state);
  }

  getState(): Observable<any> {
    return this.stateChange.asObservable();
  }

  getEvents(): Observable<any> {
    return this.eventChange.asObservable();
  }

  getCurrentTrack(): Observable<any> {
    return this.currentTrackObservable.asObservable();
  }

  getProgress(): Observable<any> {
    return this.progressObservable.asObservable();
  }

  public unsetTrack() {
    this.pause();
    this.resetState();
    this.currentTrackObservable.next(null);
  }

  public setTrack(track, type) {
    track.Type = type;
    this.currentTrackObservable.next(track);
  }

  stop() {
    console.log("Stop called");
    this.stop$.next(true);
  }

  seekTo(seconds: any) {
    if (this.audioObj) this.audioObj.currentTime = seconds;
  }

  setVolume(volume: any) {
    if (this.audioObj) {
      this.audioObj.volume = volume;
    }
  }

  private updateStateEvents(event: Event): void {
    switch (event.type) {
      case "loadstart":
        this.state.loading = true;
        break;
      case "seeking":
        this.state.seeking = true;
        break;
      case "seeked":
        this.state.seeking = false;
        break;
      case "ratechange":
        this.state.playbackSpeed = this.audioObj.playbackRate;
        break;
      case "canplay":
        this.state.loading = false;
        this.state.loaded = true;
        this.state.seeking = false;
        this.state.duration = this.audioObj.duration;
        this.state.readableDuration = this.formatTime(this.state.duration);
        if (this.state.currentTime) {
          this.state.readableCurrentTime = this.formatTime(this.state.currentTime);
        } else {
          this.state.readableCurrentTime = "00:00"
        }
        this.state.canplay = true;
        break;
      case "playing":
        this.state.playing = true;
        this.state.paused = false;
        break;
      case "pause":
        this.state.playing = false;
        this.state.paused = true;
        break;
      case "timeupdate":
        this.state.currentTime = this.audioObj.currentTime;
        this.state.remainingTime = this.audioObj.duration - this.state.currentTime;
        this.state.readableDuration = this.formatTime(this.state.duration);
        this.state.readableRemainingTime = this.formatTime(this.state.remainingTime);
        this.state.readableCurrentTime = this.formatTime(this.state.currentTime);
        break;
      case "error":
        this.resetState();
        this.state.error = true;
        break;
    }
    this.stateChange.next(this.state);
    this.eventChange.next(event);
  }



  formatTime(secs) {
    var minutes = parseInt(Math.floor(secs / 60).toFixed(0)) || 0;
    var seconds = parseInt((secs - minutes * 60).toFixed(0)) || 0;

    return minutes + ':' + (seconds < 10 ? '0' : '') + seconds;
  }
}

