import { Injectable } from '@angular/core';
export interface Session {
    StartDate: Date;
    StartTime: Number;
    Duration: number;
  }

@Injectable({
  providedIn: 'root'
})
export class SessionSchedulerService {

  calculateSessions(importance: number, resolveByDate?: Date): Session[] {
    let numberOfSessions: number;
    let interval: number; // in days
    let startDate = new Date();

    startDate = this.addDays(startDate, 1)

    switch (importance) {
      case 1:
        numberOfSessions = 2;
        resolveByDate = resolveByDate || this.addDays(startDate, 21);
        interval = 10.5;
        break;
      case 2:
        numberOfSessions = 3;
        resolveByDate = resolveByDate || this.addDays(startDate, 14);
        interval = 4.67;
        break;
      case 4:
        numberOfSessions = 3;
        resolveByDate = resolveByDate || this.addDays(startDate, 14);
        interval = 4.67;
        break;
      case 5:
        numberOfSessions = 4;
        resolveByDate = resolveByDate || this.addDays(startDate, 7);
        interval = 1.75;
        break;
      default:
        throw new Error('Invalid importance value');
    }

    return this.generateSessions(startDate, numberOfSessions, interval);
  }

  private generateSessions(startDate: Date, numberOfSessions: number, interval: number): Session[] {
    const sessions: Session[] = [];

    for (let i = 0; i < numberOfSessions; i++) {
      const sessionDate = this.addDays(startDate, i * interval);
      sessions.push({
        StartDate: sessionDate,
        StartTime: 1020, // Assuming sessions start at 9:00 AM
        Duration: 30 // Duration in minutes
      });
    }

    return sessions;
  }

  private addDays(date: Date, days: number): Date {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }
}