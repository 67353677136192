import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { NavController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { ApiService } from '../services/api.service';
import { UserService } from '../services/user.service';

@Injectable({
  providedIn: 'root'
})
export class AssessmentGuard implements CanActivate {

  constructor(
    private navController: NavController,
    private userService: UserService,
    private apiService: ApiService
  ) {

  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return new Promise((res, rej) => {

      var s = route.queryParams["s"];
      
      this.setToken(s).then((setToken: any) => {
        if (setToken) {
          this.userService.isloggedin = false;
        }
        var params: any = [];
        Object.keys(route.queryParams).forEach(e => {
          params.push(e + "=" + route.queryParams[e]);
        });

        var para = "";
        if (params.length > 0) {
          para = "?" + params.join('&');
        }

        if (this.userService.isloggedin && this.userService.user) {
          res(true);
        } else {
          this.userService.getUserToken().then((token: any) => {
            if (token) {
              this.userService.isTokenValid(setToken).then((isValid: any) => {

                this.apiService.validateToken(token).then((rData: any) => {
                  if (rData) {
                    this.userService.isloggedin = true;
                    this.userService.isUserVerified = rData.data.Auth.IsEmailVerified;
                    this.userService.email = rData.data.Auth.Email;
                    this.userService.organisation = rData.data.Organisation;
                    this.userService.organisationToIntroduce = rData.data.OrganisationIntro;
                    this.userService.setToken(rData.data.Auth.Token).then((tokenSet: any) => {
                      this.userService.setValidity(rData.data.Auth.Validity).then((validityState: any) => {
                        this.userService.setUser(rData.data.User).then((userSet: any) => {
                          if (this.userService.isloggedin && rData.data.User.UserBasicId) {
                            res(true);
                          // } else if (this.userService.isloggedin && !rData.data.User.UserBasicId) {
                          //   console.log("navigating to login because token not found");
                          //   this.navController.navigateRoot("/onboarding" + para);
                          //   rej(false);
                          } else {
                            res(true);
                          }
                        }).catch(e => {
                          res(true);
                        });
                      }).catch(e => {
                        res(true);
                      });
                    }).catch(e => {
                      res(true);
                    });
                  } else {
                    res(true);
                  }
                }).catch(e => {
                  res(true);
                });
              }).catch(e => {
                res(true);
              });
            } else {
              res(true);
            }
          });
        }

      });

    });
  }

  setToken(s: any) {
    return new Promise((r, j) => {
      if (s) {
        var token: any = atob(s);
        // this.userService.setToken()
        this.userService.setToken(token).then((rbody: any) => {
          r(true);
        }).catch(e => {
          r(false);
        });
      } else {
        r(false);
      }
    });
  }

}
