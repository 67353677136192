import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import * as Hammer from 'hammerjs';
import { ReferralThankYouDialogComponent } from 'src/app/shared/components/recommend-box/referral-thank-you-dialog.component';
import { ApiService } from '../../services/api.service';
import { UserService } from '../../services/user.service';
import { CommonService } from '../../services/common.service';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Location } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { CanShareResult, Share } from '@capacitor/share';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { FormControl, Validators } from '@angular/forms';
import * as EmailValidator from 'email-validator';

@Component({
  selector: 'app-recommend-box',
  templateUrl: './recommend-box.component.html',
  styleUrls: ['./recommend-box.component.scss']
})
export class RecommendBoxComponent implements OnInit {

  program: any = 'cl1';

  // program: any;
  shown_tab: any = 0;
  programMessage: any | {
    cl1: string,
    contemplator: string,
  } = {
      cl1: "",
      contemplator: ""
    }

  selectedProgramMessage: any;

  error: any;
  emailError: any;
  isloading: boolean = false;

  isNative: boolean = false;

  navigatorShare: boolean = false;

  constructor(
    public apiService: ApiService,
    public userService: UserService,
    private common: CommonService,
    public snackbar: MatSnackBar,
    private location: Location,
    private dialog: MatDialog
  ) {
    this.programMessage.cl1 = "Hey, hope you are doing alright.\n\nI have recently been through this Adios Contemplation program. It has helped me introspect and think more clearly in a few areas. I thought you should try it too.\n\nThe program details are here.\n\nhttps://app.contemplate.life/\n\nPlease speak to me anytime if you need to know more about my experience with the program.\n\nSee you.\n" + this.userService.user.FirstName;

    this.programMessage.venus = "Hey, hope you are doing alright.\n\nI have recently been through this Venus program. It has helped me introspect and think more clearly about how to choose the right life partner. I think you should try it too.\n\nYou can read more about the program here:\n\nhttps://contemplate.life/venus/\n\nLet’s talk if you want to and I can share my experience in detail.\n\nSpeak soon.\n" + this.userService.user.FirstName;

    this.programMessage.contemplator = "Hey, hope you are doing alright.\n\nI have recently been through this Adios Contemplator program. It has helped me introspect and think more clearly about the values, vision and culture I would like to implement in my organization. I think you should try it too.\n\nYou can read more about the program here:\n\nhttps://contemplate.life/contemplator/\n\nLet’s talk if you want to and I can share my experience in detail.\n\nSpeak soon.\n" + this.userService.user.FirstName;
    // this.program = 'cl1';
  }

  ngOnInit(): void {
    this.common.canUseNative().then((isNative) => {
      this.isNative = isNative;

      // if (!isNative) {

      //   //Not native app
      //   if (navigator.share) {
      //     this.navigatorShare = true;
      //   } else {
      //     this.navigatorShare = false;
      //   }

      // }

      Share.canShare().then((navigatorShare: CanShareResult) => {
        this.navigatorShare = navigatorShare.value;
      }).catch(e => {
        this.navigatorShare = false;
      });

    });
  }

  shareUsingNavigator() {

    Share.share({
      title: 'Find answers to their important questions in life and achieve peace of mind using Contemplate',
      text: this.programMessage[this.program],
    })
      .then(() => {


        this.successConfirmation();
      
      })
      .catch((error) => {
        console.log('Error sharing' + JSON.stringify(error))
      });

  }

  showTab(tab: any) {
    this.shown_tab = tab;
  }

  copyMessage() {
    this.snackbar.open("Message copied.");
  }

  shareOnWhatsapp() {
    var message = this.programMessage[this.program];
    var url = 'https://wa.me/?text=' + encodeURIComponent(message);
    this.common.openLink(url);

    this.successConfirmation();

  }

  sendEmail() {

    var subject = "Find answers to their important questions in life and achieve peace of mind using Contemplate";

    var message = encodeURIComponent(this.programMessage[this.program]);
    var mailText = "mailto:?subject="+ subject +"&body="+message; // add the links to body

    this.common.openLink(mailText);

    this.successConfirmation();

    if(this.isNative) {

    } else {

      // var subject = "Find answers to their important questions in life and achieve peace of mind using Contemplate";

      // var message = encodeURIComponent(this.programMessage[this.program]);
      // var mailText = "mailto:?subject="+ subject +"&body="+message; // add the links to body

      // this.common.openLink(mailText);

      // if (this.emails.length == 0) {
      //   this.error = "Please enter at least one email id";
      // } else {
      //   this.emailError = undefined;
      //   this.error = undefined;
      //   //Send emails
      //   this.isloading = true;
      //   this.apiService
      //     .recommendUsers({
      //       emails: this.emails,
      //       message: this.programMessage[this.program]
      //     }, this.program).then((res: any) => {
      //       this.snackbar.open("Recommendation emails sent");
      //       this.isloading = false;
      //     }).catch(e => {
      //       console.log(e);
      //       if (!e.msg) {
      //         e.msg = 'Something went wrong. Please try again';
      //       }
      //       this.snackbar.open(e.msg);
      //       this.isloading = false;
      //     });
  
      // }
    }

  }


  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  emails: string[] = [];
  emailControl = new FormControl(['', Validators.email]);

  add(event: any): void {
    const input = event.input;
    const value = event.value;

    if (value == '') {
      return;
    }

    if (EmailValidator.validate(value)) {
      if ((value || '').trim()) {
        this.emails.push(value.trim());
      }

      // Reset the input value
      if (input) {
        input.value = '';
      }
      this.emailError = undefined;
    } else {
      this.emailError = "Please enter a valid email address";
    }

  }

  remove(email: string): void {
    const index = this.emails.indexOf(email);

    if (index >= 0) {
      this.emails.splice(index, 1);
    }
  }


  back() {
    this.location.back();
    // this.router.navigateByUrl('/dashboard/programs');
  }

  successConfirmation(){

    const dialogRef = this.dialog.open(ReferralThankYouDialogComponent);

    dialogRef.afterClosed().subscribe(() => {
      
    });
  }

}
