<div class="program-content flex-column dflex w-100 h-100  mw-100">
  <div class="section dflex flex-column w-100 h-100">
    <ng-container *ngIf="loading">
      <div class="row m-0 flex w-100 h-100" style="place-content: center; align-content: center;">
        <div class="col-md-12 text-center" style="padding: 5rem;">
          <p>
            <span class="spinner-border spinner-border-lg" role="status" aria-hidden="true"></span>
          </p>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="!loading && program">
      <div class="list-box overflow-auto pt-0 pb-0 align-start p-4 flex-1" style="max-width: 800px; align-self: center;"
        [class.venus]="program.Alias == 'venus'">
        <div class="row m-0 w-100 content-center">

          <div class="box content-card intro-card grey-space" *ngIf="!isOngoing && batch">
            <h1 class="text-white">Welcome!</h1>
            <mat-list class="module-list">
              <mat-list-item class="content-item">
                <div mat-line *ngIf="batch" class="content-title link text-white text-center">This cohort will start on <strong>{{
                    batch.StartDate | date: 'MMM d, y':
                    dateOffset}} at {{ batch.StartDate | date: 'h:mm a': dateOffset}}</strong>.<br>
                  <ng-container *ngIf="programParticipant">Your learning material will start to unlock when the cohort
                    begins.</ng-container>
                  <ng-container *ngIf="!programParticipant">Please join the cohort to get access to the
                    program.</ng-container>
                  <br>
                </div>
              </mat-list-item>
            </mat-list>
          </div>

          <div #contentlist class="col-md-12 col-12 p-0" *ngIf="program.ProgramChapters.length > 0">
            <ng-container *ngFor="let chapter of program.ProgramChapters; let ci = index;">
              <div class="box content-card grey-space">
                <h1>{{ chapter.Name }}</h1>
                <mat-list class="module-list">
                  <ng-container *ngFor="let module of chapter.ProgramLessons; let li = index;">
                    <ng-container>
                      <div *ngIf="module.Order > 1" mat-subheader class="module-name">{{ module.Name }} <small
                        class="ml-1 text-muted"
                        *ngIf="!isMember && !isOngoing && !program.IsCategoryIndroduction && !chapter.IsIntroduction">
                        (MEMBERS ONLY)</small>
                      </div>

                      <ng-container *ngFor="let contentGroup of module.ContentGroups; let cgi = index;">
                        
                        <div *ngIf="module.Order == 1" mat-subheader class="module-name" id="content-{{ contentGroup.ContentGroupId }}">
                          {{ module.Name }}
                          <small class="ml-1 text-muted" *ngIf="!isMember && !isOngoing && !program.IsCategoryIndroduction && !chapter.IsIntroduction">(MEMBERS ONLY)</small>
                        </div>

                        <ng-container *ngFor="let content of contentGroup.Contents; let c = index;">
                          <mat-list-item class="content-item" >
                            <div mat-line class="content-title link"
                              (click)="startContentFlow(contentFlowPopupbox, content, ci, li, cgi, c, program)">
                              {{ content.Title }} 
                              <small>
                                <span *ngIf="content.IsLocked && lockedContent && content.ContentId == lockedContent.ContentId && contentGroup.UnlockOn && (isSubscribed || (lastTrial == chapter.ProgramChapterId && isMember && !isSubscribed))" class="text-white px-2 mt-2 badge bg-info">Unlocks {{ content.UnlockOnRel }}</span>
                              </small>
                              <!-- <small class="badge badge bg-info" *ngIf="content.IsLocked && contentGroup.UnlockOn">Will unlock {{content.UnlockOnRel}} at {{ contentGroup.UnlockOn | date: 'h:mm a'}}</small> -->

                              <!-- <small class="badge bg-danger" color="primary" *ngIf="content.IsLocked && contentGroup.UnlockOn">(Unlocks on {{ contentGroup.UnlockOn | date: 'MMM d, y'}} at {{ contentGroup.UnlockOn | date: 'h:mm a'}})</small> -->
                            </div>

                            <mat-icon end
                              *ngIf="!content.IsLocked && (contentMetas[content.ContentId] && contentMetas[content.ContentId].IsCompleted)"
                              mat-list-icon svgIcon="content_completed"
                              (click)="startContentFlow(contentFlowPopupbox, content, ci, li, cgi, c, program)"
                              class="link">
                            </mat-icon>

                            <mat-icon end
                              *ngIf="(content.Type == 'AUDIO' || content.Type == 'VIDEO') && !content.IsLocked && (!contentMetas[content.ContentId] || !contentMetas[content.ContentId].IsCompleted)"
                              mat-list-icon svgIcon="content_play"
                              (click)="startContentFlow(contentFlowPopupbox, content, ci, li, cgi, c, program)"
                              class="link">
                            </mat-icon>

                            <mat-icon end
                              *ngIf="(content.Type == 'ADIOSCOPE' || content.Type == 'ADIOSCOPEEMBED') && !content.IsLocked && (!contentMetas[content.ContentId] || !contentMetas[content.ContentId].IsCompleted)"
                              mat-list-icon svgIcon="content_adioscope"
                              (click)="startContentFlow(contentFlowPopupbox, content, ci, li, cgi, c, program)"
                              class="link">
                            </mat-icon>

                            <mat-icon end
                              *ngIf="(content.Type == 'JOURNALING' || content.Type == 'ESSAY') && 
                              !content.IsLocked &&
                              (!contentMetas[content.ContentId] || !contentMetas[content.ContentId].IsCompleted)"
                              mat-list-icon svgIcon="content_journaling"
                              (click)="startContentFlow(contentFlowPopupbox, content, ci, li, cgi, c, program)"
                              class="link">
                            </mat-icon>

                            <mat-icon end
                              *ngIf="content.IsLocked"
                              mat-list-icon svgIcon="content_locked">
                            </mat-icon>
                          </mat-list-item>
                        </ng-container>

                        <mat-list-item *ngIf="contentGroup.Contents.length == 0" class="content-card">
                          <div mat-line class="content-title">No content for this day. Please contemplate on
                            things that matter</div>
                        </mat-list-item>

                      </ng-container>
                    </ng-container>

                  </ng-container>
                </mat-list>
              </div>

              <!-- How Program Works -->
              <ng-container *ngIf="chapter.Order == 1 && !programParticipant && (!isMember || (isMember && !isSubscribed))">
                <div class="box content-card grey-space" *ngIf="program.Alias.indexOf('core') != -1">
                  <mat-list class="module-list">
                    <ng-container>
                      <div mat-subheader class="module-name">How does Contemplate work?</div>
                      <mat-list-item class="content-item">
                        <div mat-line class="content-title flex-column align-items-start">
                          <ol>
                            <li>Start the 8-week Adios program.</li>
                            <li>Learn the Adios principles weekly.</li>
                            <li>Resolve critical life situations using your decision making journal - Adioscope.</li>
                            <li>Attend online live Contemplation sessions to improve your practice.</li>
                            <li>Make decisions that keep you peaceful.</li>
                          </ol>
                        </div>
                      </mat-list-item>
                    </ng-container>

                  </mat-list>
                </div>
                <div class="box content-card grey-space lillac-space" *ngIf="program.Alias.indexOf('venus') != -1">
                  <h1>ENROLL NOW TO JOIN VENUS</h1>
                  <mat-list class="module-list">
                    <ng-container>
                      <div mat-subheader class="module-name">How does Venus work?</div>
                      <mat-list-item class="content-item">
                        <div mat-line class="content-title">
                          <p>Venus is a one-year membership that starts with an 8-week program.</p>
                          <p>You go through the weekly sessions on the Venus platform at your convenience and also
                            attend live Zoom check-in sessions. The Venus sessions are a combination of audio lessons
                            and exercises you will do each week to improve your clarity of thought.</p>
                          <p>You will use the Adioscope to analyse real-life situations and find answers to your crucial
                            questions. Along the way, you will also hear from our alumni about their experience and
                            their journey.
                          </p>
                        </div>
                      </mat-list-item>
                      <div mat-subheader class="module-name">PROGRAM SCHEDULE</div>
                      <mat-list-item class="content-item">
                        <div mat-line class="content-title">Over the next 8 weeks, each session is divided into the
                          following:</div>
                        <div mat-line class="content-description">
                          <ul class="offer-list">
                            <li>Two Venus Principles relating to understanding your mind</li>
                            <li>Contemplation Practice and Exercises</li>
                            <li>Analyse a real-life situation in our decision-making tool Adioscope - your private
                              microscope for life</li>
                            <li>Summary and things to remember during the coming week</li>
                            <li>During the week, you will receive short audios and emails to keep you focused.</li>
                            <li>You can also use the Guided Contemplations on the platform to practice certain
                              principles or situations.</li>
                          </ul>
                        </div>
                      </mat-list-item>
                      <mat-list-item class="content-item">
                        <div mat-line class="content-title">During the 8 week</div>
                        <div mat-line class="content-description">
                          <ul class="offer-list">
                            <li>Weeks 1 and 2 - Start finding yourself</li>
                            <li>Weeks 3 to 5 - Build your Venus profile</li>
                            <li>Weeks 6 to 8 - Build your ideal partner profile</li>
                          </ul>
                        </div>
                      </mat-list-item>
                      <mat-list-item class="content-item">
                        <div mat-line class="content-title">After Week 8</div>
                        <div mat-line class="content-description">
                          <ul class="offer-list">
                            <li>You have set the foundation for a strong meaningful relationship</li>
                            <li>You learn to look beyond the superficial and find a deeper connection with your partner.
                            </li>
                            <li>You learn how to find out whether the other person is aligned with your values or not.
                            </li>
                            <li>You approach your relationship with your partner the right way.</li>
                          </ul>
                        </div>
                      </mat-list-item>
                    </ng-container>

                  </mat-list>
                </div>
              </ng-container>
              <!-- How Program Works - End -->

              <!-- Subscribe Checkpoint -->
              <div class="box content-card intro-card grey-space" [class.lillac-space]="program.Alias == 'venus'"
                *ngIf="lastTrial == chapter.ProgramChapterId && isMember && !isSubscribed">
                <ng-container *ngIf="!upgradingTrial">
                  <mat-list class="module-list">
                    <mat-list-item class="content-item">
                      <div mat-line class="content-title link text-white text-center">
                        Please subscribe to continue the program.
                      </div>
                    </mat-list-item>
                  </mat-list>
                </ng-container>
                <ng-container *ngIf="upgradingTrial">
                  <div class="row m-0 flex w-100 h-100" style="place-content: center; align-content: center;">
                    <div class="col-md-12 text-center" style="padding: 5rem;">
                      <p>
                        <span class="spinner-border spinner-border-lg" role="status" aria-hidden="true"></span>
                      </p>
                    </div>
                  </div>
                </ng-container>
              </div>
              <!-- Subscribe Checkpoint - End -->
              
            </ng-container>
          </div>
        </div>
      </div>

      <div class="dflex flex-column">
        <button *ngIf="isMember && !programParticipant" class="btn btn-success w-100 text-white" type="button" (click)="startProgram()">
          <span *ngIf="startingProgram" class="spinner-border spinner-border-lg" role="status"
            aria-hidden="true"></span>
          <span *ngIf="!startingProgram">TRY WEEK 1 FOR FREE</span>
        </button>
        <ng-container *ngIf="currentContent && isMember && this.programParticipant" class="box content-card intro-card grey-space">
          <div class="d-flex w-100">
            <ion-footer class="py-2 px-0 bg-beige">
              <ion-toolbar class="rounding">
                  <div class="d-flex">
                      <ion-label>
                        <small class="text-muted mb-3">{{ currentContent.SectionName }}</small>
                        <h2>{{ currentContent.Title }}</h2>
                        <small>
                          <span *ngIf="currentContent.IsLocked" class="text-white px-2 mt-2 badge bg-info">Unlocks {{ currentContent.UnlockOnRel }}</span>
                        </small>
                        <!-- <p class="text-muted"><small>{{ currentContent.Text }}</small></p> -->
                      </ion-label>
                      <div class="flex-1">
          
                      </div>
                      <ion-buttons slot="end">
                          <ng-container>

                            <mat-icon end
                              *ngIf="!currentContent.IsLocked && (contentMetas[currentContent.ContentId] && contentMetas[currentContent.ContentId].IsCompleted)"
                              mat-list-icon svgIcon="content_completed"
                              (click)="startContentFlowV2(currentContent)"
                              class="link">
                            </mat-icon>

                            <mat-icon end
                              *ngIf="(currentContent.Type == 'AUDIO' || currentContent.Type == 'VIDEO') && !currentContent.IsLocked && (!contentMetas[currentContent.ContentId] || !contentMetas[currentContent.ContentId].IsCompleted)"
                              mat-list-icon svgIcon="content_play"
                              (click)="startContentFlowV2(currentContent)"
                              class="link">
                            </mat-icon>

                            <mat-icon end
                              *ngIf="(currentContent.Type == 'ADIOSCOPE' || currentContent.Type == 'ADIOSCOPEEMBED') && !currentContent.IsLocked && (!contentMetas[currentContent.ContentId] || !contentMetas[currentContent.ContentId].IsCompleted)"
                              mat-list-icon svgIcon="content_adioscope"
                              (click)="startContentFlowV2(currentContent)"
                              class="link">
                            </mat-icon>

                            <mat-icon end
                              *ngIf="(currentContent.Type == 'JOURNALING' || currentContent.Type == 'ESSAY') && 
                              !currentContent.IsLocked &&
                              (!contentMetas[currentContent.ContentId] || !contentMetas[currentContent.ContentId].IsCompleted)"
                              mat-list-icon svgIcon="content_journaling"
                              (click)="startContentFlowV2(currentContent)"
                              class="link">
                            </mat-icon>

                            <mat-icon end
                              *ngIf="currentContent.IsLocked"
                              mat-list-icon svgIcon="content_locked">
                            </mat-icon>
                          </ng-container>
                      </ion-buttons>
                  </div>
              </ion-toolbar>
            </ion-footer>
          
            <!-- {{ currentContent | json }} -->
          </div>
        </ng-container>
      </div>
    </ng-container>
  </div>
</div>

<ion-modal #joincohortbox trigger="open-modal">
  <ng-template>
    <div class="modal-container w-100 h-100">
      <sb-join-program class="dflex w-100 h-100" (onsubmit)="batchJoined($event)"></sb-join-program>
    </div>
  </ng-template>
</ion-modal>

<ion-modal #contentFlowPopupbox trigger="open-modal" (willDismiss)="closeCurrentContent($event, contentFlowPopupbox)">
  <div class="modal-container">
    <!-- <sb-content-flow class="dflex w-100 h-100" *ngIf="!modalsetup" #contentFlow [options]="currentContent"
      [contents]="contents" (markCompleted)="markCompleted($event)">
    </sb-content-flow> -->
  </div>
</ion-modal>