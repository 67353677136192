import { DatePipe } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
// import {
//   IAPProduct,
//   InAppPurchase2,
// } from '@awesome-cordova-plugins/in-app-purchase-2/ngx';
import { AlertController, ModalController, Platform } from '@ionic/angular';
import { filter, skip, take } from 'rxjs/operators';
import { ConfirmDialogComponent, StartProgramPopupComponent } from 'src/app/shared/components';
import { ApiService } from 'src/app/shared/services/api.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { UserService } from 'src/app/shared/services/user.service';
import { environment } from 'src/environments/environment';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import * as moment from 'moment';
import { AudioService } from '../../services/audio.service';
import { CurriculumService } from '../../services/curriculum.service';

@Component({
  selector: 'app-program-details',
  templateUrl: './program-details.component.html',
  styleUrls: ['./program-details.component.scss'],
})
export class ProgramDetailsComponent implements OnInit {
  loading: boolean = false;

  @ViewChild('joincohortbox') joincohortbox: any;
  @ViewChild('contentFlowPopupbox') contentFlowPopup: any;

  currency: any = 'USD';
  multiplyFactor: any = 1;
  dateOffset: any;

  contentboxclosed: boolean = true;

  @Input() clearProgramId?: any = false;
  @Input() program: any;
  @Output() programLoaded: EventEmitter<any> = new EventEmitter<any>();
  @Output() closeDialog: EventEmitter<any> = new EventEmitter<any>();

  isNative: boolean = false;
  isMember: boolean = false;
  isSubscribed: boolean = false;
  consumedTrial: boolean = false;
  programParticipant: any;

  subscriptionStatus : any;
  isTrial: boolean = false;
  lastTrial: any;
  order: any;
  batch: any;
  contentMetas: any = {};
  currentHour: any = 0;
  currentDay: any = 0;
  membership: any;

  store_Programs: any = [];
  store_prices: any = {};

  isOngoing: boolean = false;

  // @Output() purchase: EventEmitter<any> = new EventEmitter<any>();
  @Output() getCategoryContent: EventEmitter<any> = new EventEmitter<any>();
  @Output() checkMembership: EventEmitter<any> = new EventEmitter<any>();

  queryParams: any;
  firstQueryParamsSubscription: any;
  queryParamsSubscription: any;
  organisation?: any;

  stripePromise = loadStripe(environment.stripe_key);
  
  public subscription_name = 'adios_core_subscription';

  constructor(
    private dialog: MatDialog,
    private snackbar: MatSnackBar,
    private common: CommonService,
    private router: Router,
    private apiService: ApiService,
    private userService: UserService,
    private datePipe: DatePipe,
    private route: ActivatedRoute,
    private plt: Platform,
    // private store: InAppPurchase2,
    private modal: ModalController,
    private zone: NgZone,
    private alertController: AlertController,
    private ref: ChangeDetectorRef,
    private audioService: AudioService,
    private curriculumService: CurriculumService
  ) {
    this.organisation = this.userService.getOrganisation();
  }

  ngOnInit() {
    var offset = new Date().getTimezoneOffset();
    if (offset > 0) {
      this.dateOffset =
        '-' +
        ('0' + Math.floor(offset / 60)).slice(-2) +
        '' +
        ('0' + Math.floor(offset % 60).toFixed(0)).slice(-2);
    } else {
      this.dateOffset =
        '+' +
        ('0' + Math.floor((offset * -1) / 60).toFixed(0)).slice(-2) +
        '' +
        ('0' + Math.floor((offset * -1) % 60).toFixed(0)).slice(-2);
    }
    this.currency = this.apiService.getCurrency();
    if (this.currency == 'INR') {
      this.multiplyFactor = 32;
    }

    this.audioService.unsetTrack();
    this.curriculumService.unsetTrack();
    // if (this.program && this.program.Alias == 'venus-couple') {
    //   this.VENUS_CORE = 'venus_couple_entire';
    //   this.VENUS_CORE_TRIAL = 'venus_couple_trial';
    //   this.VENUS_CORE_UPGRADE = 'venus_couple_upgrade';
    //   this.VENUS_CORE_UPGRADE_IOS = 'venus_couple_upgrade_ios';
    // } else {
    //   this.VENUS_CORE = 'venus_core_entire';
    //   this.VENUS_CORE_TRIAL = 'venus_core_trial';
    //   this.VENUS_CORE_UPGRADE = 'venus_core_upgrade';
    //   this.VENUS_CORE_UPGRADE_IOS = 'venus_core_upgrade_ios';
    // }

    // if (
    //   !isNaN(this.queryParams["pId"]) &&
    //   !isNaN(this.queryParams["ci"]) &&
    //   !isNaN(this.queryParams["l"]) &&
    //   !isNaN(this.queryParams["cg"]) &&
    //   !isNaN(this.queryParams["c"])
    // ) {
    //   var programId = parseInt(this.queryParams["pId"]);
    //   var chapterIndex = parseInt(this.queryParams["ci"]) - 1;
    //   var lessonIndex = parseInt(this.queryParams["l"]) - 1;
    //   var contentGroupIndex = parseInt(this.queryParams["cg"]) - 1;
    //   var contentIndex = parseInt(this.queryParams["c"]) - 1;

    //   // console.log(programId);
    //   // console.log(chapterIndex);
    //   // console.log(lessonIndex);
    //   // console.log(contentGroupIndex);
    //   // console.log(contentIndex);

    //   if (this.program != undefined && this.program.ProgramId == programId) {
    //     this.startContentFlow(this.contentFlowPopup,
    //       this.program.ProgramChapters[chapterIndex].ProgramLessons[lessonIndex].ContentGroups[contentGroupIndex].Contents[contentIndex],
    //       chapterIndex, lessonIndex, contentGroupIndex, contentIndex, this.program);

    //     document.getElementById('content-' + chapterIndex + '-' + lessonIndex)?.scrollIntoView();
    //   }
    // } else {
    //   this.contentFlowPopup?.dismiss();
    // }

    this.getProgramDetails();
  }

  ngOnDestroy() {
    // this.contentFlowPopup.dismiss();
    // this.firstQueryParamsSubscription.unsubscribe();
    // this.queryParamsSubscription.unsubscribe();
  }

  getProgramDetails() {
    this.loading = true;
    console.log(this.program);
    this.apiService
      .getProgramDetailsV2(
        this.program.Alias,
        this.program.ParticipantId,
        this.program.BatchId
      )
      .then((res: any) => {
        if (res.success) {
          this.processProgramData(res, true);
          // if(this.program.ProgramId == 23) {
          //   this.program.ProgramChapters[0].ProgramLessons[0].ContentGroups[0].Contents[1] = JSON.parse(JSON.stringify(
          //     this.program.ProgramChapters[0].ProgramLessons[0].ContentGroups[0].Contents[0]));
          //     this.program.ProgramChapters[0].ProgramLessons[0].ContentGroups[0].Contents[1].Title = 'Adioscope';
          //     this.program.ProgramChapters[0].ProgramLessons[0].ContentGroups[0].Contents[1].Text = 'Adioscope';
          //     this.program.ProgramChapters[0].ProgramLessons[0].ContentGroups[0].Contents[1].Type = 'ADIOSCOPEEMBED';
          // }

          if (
            this.queryParams &&
            !isNaN(this.queryParams['pId']) &&
            !isNaN(this.queryParams['ci']) &&
            !isNaN(this.queryParams['l']) &&
            !isNaN(this.queryParams['cg']) &&
            !isNaN(this.queryParams['c'])
          ) {
            var programId = parseInt(this.queryParams['pId']);
            var chapterIndex = parseInt(this.queryParams['ci']) - 1;
            var lessonIndex = parseInt(this.queryParams['l']) - 1;
            var contentGroupIndex = parseInt(this.queryParams['cg']) - 1;
            var contentIndex = parseInt(this.queryParams['c']) - 1;

            if (
              this.program != undefined &&
              this.program.ProgramId == programId
            ) {
              this.startContentFlow(
                this.contentFlowPopup,
                this.program.ProgramChapters[chapterIndex].ProgramLessons[
                  lessonIndex
                ].ContentGroups[contentGroupIndex].Contents[contentIndex],
                chapterIndex,
                lessonIndex,
                contentGroupIndex,
                contentIndex,
                this.program
              );

              document
                .getElementById('content-' + chapterIndex + '-' + lessonIndex)
                ?.scrollIntoView();
            }
          } else {
            console.log("Callint contnet flow dismiss")
            this.contentFlowPopup?.dismiss();
          }
        }
        this.loading = false;
      })
      .catch((error) => {
        console.log(error);
        if (!error.msg) {
          error.msg = 'Something went wrong';
        }
        this.snackbar.open(error.msg);
        this.loading = false;
        this.contentFlowPopup?.dismiss();
      });
  }

  processProgramData(res: any, scroll?: boolean){
    this.isMember = res.data.isMember;
    this.isTrial = res.data.isTrial;
    this.isSubscribed = res.data.isSubscribed;
    this.consumedTrial = res.data.subscriptionState.ConsumedTrial;
    this.lastTrial = res.data.lastTrialChapter;
    this.subscriptionStatus = res.data.subscriptionStatus;
    this.order = res.data.order;
    this.userService.setSubscriptionState(res.data.subscriptionState);
    // res.data.program.ParticipantId = this.program.ParticipantId;
    // res.data.program.ProgramName = this.program.ProgramName;
    // res.data.program.BatchId = this.program.BatchId;

    this.program = res.data.program;

    this.programParticipant = res.data.programParticipant;
    this.batch = res.data.batch;
    if (this.programParticipant) {
      this.batch = this.programParticipant.Batch;
    }

    this.contentMetas = res.data.contentMetas;
    this.currentHour = res.data.currentHour;
    this.currentDay = res.data.currentDay;
    this.membership = res.data.membership;
    this.isOngoing = res.data.isOngoing;

    this.processProgramChapters(scroll);

    this.programLoaded.emit(this.program);
    console.log("Emitting program: ", this.program);
  }

  processProgramChapters(scroll?: boolean) {

    this.currentContent = undefined;

    if(this.program) {
      
      var date = null;
      if(this.batch) {
        date = 
        moment(this.batch.StartDate);
        date.hour(0);
        date.minute(0);
        date.second(0);
      }
      console.log(this.batch);
      var possibleContent: any = [];
      var lockedContentPushed = false;
      this.program.ProgramChapters.forEach((chapter, ci) => {
        chapter.ProgramLessons.forEach((module, li) => {
            module.ContentGroups.forEach((contentGroup, cgi) => {
              // var lessons = [];
              // var exercises = [];
              var x = 0;
              if(this.programParticipant) {
                var unlockDate = moment(date).add('days', (module.UnlockAfterDays - 1));
                unlockDate = unlockDate.add(contentGroup.UnlockHour, 'hours');
                contentGroup.UnlockOn = unlockDate.toISOString();
                contentGroup.UnlockOnRel = unlockDate.fromNow();
              }
              contentGroup.Contents.forEach((content: any) => {
                if(!this.currentContent) this.currentContent = content;
                if(!chapter.IsIntroduction && (!this.programParticipant || module.UnlockAfterDays > this.currentDay || (module.UnlockAfterDays == this.currentDay && contentGroup.UnlockHour > this.currentHour))) {
                  content.IsLocked = true;
                } else {
                  content.IsLocked = false;
                }

                if((chapter.IsIntroduction && !this.programParticipant) || (this.programParticipant && (module.UnlockAfterDays == this.currentDay && contentGroup.UnlockHour <= this.currentHour) || content.IsLocked)) {
                  if(content.IsLocked == true) {
                    if(!lockedContentPushed) possibleContent.push(content);
                    lockedContentPushed = true;
                  } else {
                    possibleContent.push(content);
                  }
                }

                content.SectionName = chapter.Name + " - " + module.Name
                content.UnlockOn = contentGroup.UnlockOn;
                content.UnlockOnRel = contentGroup.UnlockOnRel;
                // if (content.Type == 'AUDIO' || content.Type == 'VIDEO') {
                //   lessons.push(content);
                // } else {
                //   lessons.push(content);
                // }
                x++;
                if(x == contentGroup.Contents.length) {
                  // contentGroup.Contents = lessons;
                  // contentGroup.Exercises = exercises;
                }

              });
            });
        });
      });
      console.log(possibleContent);
      this.currentContent = undefined;
      this.lockedContent = undefined;
      if(possibleContent.length > 0) {
        possibleContent.forEach((content) => {
          if(!this.contentMetas[content.ContentId] || !this.contentMetas[content.ContentId].IsCompleted) {
            if(!this.currentContent) {
              this.currentContent = content;
              if(scroll) {
                document.getElementById('content-' + content.ContentGroupId)?.scrollIntoView();
                // id="content-{{ contentGroup.ContentGroupId }}"
              }
            }
          }
        });
        this.lockedContent = possibleContent[possibleContent.length - 1];
      }

    }
  }

  subscribing: boolean = false;
  subscribeToMembership() {
    this.router.navigateByUrl('/programs/'+ this.program.Alias +'/subscribe');
    // this.subscribing = true;
    // this.apiService
    //   .subscribeMembershipV2(
    //     this.membership.Offers[0].MembershipOfferId,
    //     environment.category
    //   )
    //   .then((res: any) => {
    //     if (res.success) {
    //       this.router.navigateByUrl(
    //         '/payment/finish/' + res.data.orderRef + '?t=' + res.data.token
    //       );
    //     }
    //     this.subscribing = false;
    //   })
    //   .catch((e) => {
    //     if (e.msg) {
    //       e.msg = 'Something went wrong';
    //     }
    //     this.snackbar.open(e.msg);
    //     this.subscribing = false;
    //   });
  }

  joiningBatch: boolean = false;
  joiningBatchId: any;
  joiningBatchCode: any;
  joiningtrial?: boolean = false;
  joinBatch(cohort: any, trial?: boolean) {
    if (this.joiningBatch) {
      return;
    }
    this.joiningtrial = trial;
    if (cohort.IsPublic) {
      this.joiningBatch = true;
      this.joiningBatchId = cohort.BatchId;
      // Private Cohort - Joining Batch Paid
      this.apiService
        .useAffiliationCode(cohort.Code, trial)
        .then((res: any) => {
          if (res.data) {
            if (res.data.orderRef) {
              this.router.navigateByUrl(
                '/payment/finish/' + res.data.orderRef + '?t=' + res.data.token
              );
            } else {
              // this.checkMembership(this.categoryAlias);
              this.getCategoryContent.emit();
            }
          }
          this.joiningBatch = false;
          this.joiningtrial = false;
          this.joiningBatchId = undefined;
          this.joiningBatchCode = undefined;
        })
        .catch((e) => {
          console.log(e);
          if (!e.msg) {
            e.msg = 'Something went wrong. Please try again';
          }
          this.snackbar.open(e.msg);
          this.joiningBatch = false;
          this.joiningBatchId = undefined;
        });

      // if (this.isMember) {
      //   this.joiningBatch = true;
      //   this.joiningBatchId = cohort.BatchId;
      //   this.apiService
      //     .joinCategoryBatch(cohort.BatchId, trial)
      //     .then((res: any) => {
      //       // this.joinedCohorts.push(cohort.BatchId);
      //       this.snackbar.open(res.data.Message);
      //       this.joiningBatch = false;
      //       this.joiningBatchId = undefined;
      //     })
      //     .catch((error) => {
      //       console.log(error);
      //       if (!error.msg) {
      //         error.msg = 'Something went wrong';
      //       }
      //       this.snackbar.open(error.msg);
      //       this.joiningBatch = false;
      //       this.joiningBatchId = undefined;
      //     });
      // } else {
      //   //Show Membership Popup
      //   // this.startMembership();
      // }
    } else {
      this.common.canUseNative().then((usernative: boolean) => {
        if (usernative) {
          this.joiningBatch = true;
          this.joiningBatchId = cohort.BatchId;
          this.joiningBatchCode = cohort.Code;
          this.userService.setJoiningCohort(cohort).then((r: any) => {
            // if (trial) {
            //   this.purchase.emit(this.store_prices[this.VENUS_CORE_TRIAL]);
            // } else {
            //   this.purchase.emit(this.store_prices[this.VENUS_CORE]);
            // }
          });
        } else {
          this.joiningBatch = true;
          this.joiningBatchId = cohort.BatchId;
          // Private Cohort - Joining Batch Paid
          this.apiService
            .enrollBatch(cohort.Code, trial)
            .then((res: any) => {
              if (res.data) {
                if (res.data.orderRef) {
                  this.router.navigateByUrl(
                    '/payment/finish/' +
                      res.data.orderRef +
                      '?t=' +
                      res.data.token
                  );
                } else {
                  // this.checkMembership(this.categoryAlias);
                  this.getCategoryContent.emit();
                }
              }
              this.joiningBatch = false;
              this.joiningtrial = false;
              this.joiningBatchId = undefined;
              this.joiningBatchCode = undefined;
            })
            .catch((e) => {
              console.log(e);
              if (!e.msg) {
                e.msg = 'Something went wrong. Please try again';
              }
              this.snackbar.open(e.msg);
              this.joiningBatch = false;
              this.joiningBatchId = undefined;
            });
        }
      });
    }
  }

  joinCohortUsingCode(content: any) {
    this.joincohortbox.present();
  }

  batchJoined(event: any) {
    this.snackbar.open('You are now a member of ' + event.batch.Name);

    if (event.batch.StartDate.indexOf('Z') == -1) {
      event.batch.StartDate += 'Z';
    }

    var date = this.datePipe.transform(
      event.batch.StartDate,
      'MMM d, y, h:mm a',
      this.dateOffset
    );
    // const dialogRef = this.dialog.open(ConfirmDialogComponent, {
    //   width: '450px',
    //   data: {
    //     title: "You are now a member of " + event.batch.Name + ".",
    //     message: "The cohort starts on " + date + ".  You will find all your learning material inside the " + event.batch.Name + " card under the My Cohorts tab. It will start to unlock after the cohort begins.",
    //     positive: "Go to Cohort",
    //     showNegative: false,
    //   }
    // });

    // dialogRef.afterClosed().subscribe(result => {
    //   //console.log(result);
    //   if (result) {
    //     this.viewProgramSpace(event.programParticipant);
    //   }
    // });
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '450px',
      data: {
        title: 'Thank you for joining  ' + event.batch.Name + '.',
        message:
          'You will find all your learning material under the Programs tab inside the ' +
          event.batch.Name +
          ' card.',
        positive: 'Go to Program',
        showNegative: false,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      //console.log(result);
      if (result) {
        // this.viewProgramSpace(event.programParticipant);
      }
    });

    this.checkMembership.emit();
    this.joincohortbox.dismiss();
  }

  startingTrial: boolean = false;
  startTrial() {
    if(this.startingTrial) {
      return false;
    }
    this.startingTrial = true;
    this.apiService
      .startFreeTrial()
      .then((res: any) => {
        this.startingTrial = false;
        this.router.navigateByUrl('/dashboard/programs?st=1');
      })
      .catch((error) => {
        console.log(error);
        if (!error.msg) {
          error.msg = 'Something went wrong';
        }
        this.snackbar.open(error.msg);
        this.startingTrial = false;
      });
  }

  startingProgram: boolean = false;
  startProgram() {
    if(this.startingProgram) {
      return false;
    }
    this.startingProgram = true;
    this.apiService
      .startProgram(this.program.Alias)
      .then((res: any) => {
        this.processProgramData(res);

        var message = "You go through the weekly sessions on this platform at your convenience and also attend live Zoom sessions online. These weekly sessions are a combination of audio lessons and exercises you will do to improve your clarity of thought.";
        const dialogRef = this.dialog.open(StartProgramPopupComponent, {
          width: '80%',
          maxWidth: '600px',
          // maxHeight: '600px',
          panelClass: 'round-confirm-box',
          data: {
            title: "Congratulations! Your Contemplate Training begins today.",
            message: message,
            positive: "Start Week 1",
            showNegative: false,
          }
        });
    
        this.startingProgram = false;
    
        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            //TODO: Start Week one popup
            if (this.currentContent) {
              this.startContentFlowV2(this.currentContent);
            }
            this.currentContent
          }
        });
      })
      .catch((error) => {
        console.log(error);
        if (!error.msg) {
          error.msg = 'Something went wrong';
        }
        this.snackbar.open(error.msg);
        this.startingProgram = false;
      });
  }

  upgradeCoreTrial() {
    if (this.upgradingTrial) {
      return;
    }
    this.upgradingTrial = true;
    this.common.canUseNative().then((usernative: boolean) => {
      if (usernative) {
        
      } else {
        this.upgradingTrial = true;
        // Private Cohort - Joining Batch Paid
        this.apiService
          .upgradeCoreTrial(this.order.Order.OrderReference)
          .then((res: any) => {
            if (res.data) {
              if(res.data.session) {

                // this.stripePromise.then((stripe: Stripe | null) => {
                //   stripe?.redirectToCheckout({ sessionId: res.data.id }).then((result: any) => {

                //     // If redirectToCheckout fails due to a browser or network
                //     // error, you should display the localized error message to your
                //     // customer using error.message.
                //     if (result.error) {
                //       alert(result.error.message);
                //     }
                //   }).catch(function (error) {
                //     console.error("Error:", error);
                //   });
                // }).catch(e => {
                //   console.log(e);
                // });
                // // this.router.navigateByUrl('/payment/verify?ao=' + this.order.Order.OrderReference + '&order_id='+res.data.id + "&subscription=1");

                this.router.navigateByUrl(
                  '/payment/finish/' + this.order.Order.OrderReference + '?t=' + res.data.token
                );
                // this.router.navigateByUrl('/payment/finish?ao=' + this.order.Order.OrderReference + '&order_id='+res.data.id + "&subscription=1");
              } else {
                this.router.navigateByUrl('/payment/verify?ao=' + this.order.Order.OrderReference + '&order_id='+res.data.id + "&subscription=1");
              }
            }
            this.upgradingTrial = false;
          })
          .catch((e) => {
            console.log(e);
            if (!e.msg) {
              e.msg = 'Something went wrong. Please try again';
            }
            this.snackbar.open(e.msg);
            this.upgradingTrial = false;
          });
      }
    });
  }

  upgradingTrial: boolean = false;
  upgradeTrial(programParticipant: any) {
    if (this.upgradingTrial) {
      return;
    }
    this.upgradingTrial = true;
    this.common.canUseNative().then((usernative: boolean) => {
      if (usernative) {
        this.userService
          .setUpgradingParticipant(programParticipant.ProgramParticipantId)
          .then((r: any) => {
            // this.purchase.emit(this.store_prices[this.VENUS_CORE_UPGRADE]);
          });
      } else {
        // this.joiningBatch = true;
        // this.joiningBatchId = programParticipant.BatchId;
        this.upgradingTrial = true;
        // Private Cohort - Joining Batch Paid
        this.apiService
          .upgradeTrial(programParticipant.ProgramParticipantId)
          .then((res: any) => {
            if (res.data) {
              if (res.data.orderRef) {
                this.router.navigateByUrl(
                  '/payment/finish/' +
                    res.data.orderRef +
                    '?t=' +
                    res.data.token
                );
              } else {
                this.checkMembership.emit();
              }
            }
            this.upgradingTrial = false;
          })
          .catch((e) => {
            console.log(e);
            if (!e.msg) {
              e.msg = 'Something went wrong. Please try again';
            }
            this.snackbar.open(e.msg);
            this.upgradingTrial = false;
          });
      }
    });
  }

  //current content variables
  currentContent?: any;
  lockedContent?: any;
  modalsetup: boolean = false;
  contents: any = [];
  startContentFlow(
    contentFlowPopup: any,
    content: any,
    chapterIndex: any,
    lessonIndex: any,
    contentGroupIndex: any,
    contentIndex: any,
    program: any
  ) {
    if (
      program.ProgramChapters[chapterIndex].ProgramLessons[lessonIndex]
        .UnlockAfterDays < this.currentDay ||
      (program.ProgramChapters[chapterIndex].ProgramLessons[lessonIndex]
        .UnlockAfterDays == this.currentDay &&
        program.ProgramChapters[chapterIndex].ProgramLessons[lessonIndex]
          .ContentGroups[contentGroupIndex].UnlockHour <= this.currentHour)
    ) {
      console.log(program);
      if (this.batch) {
        this.router.navigateByUrl(
          'content/' +
            program.Alias +
            '/' +
            program.ProgramId +
            '/' +
            this.batch.BatchId +
            '/' +
            content.ContentId
        );
      } else if (this.programParticipant) {
        this.router.navigateByUrl(
          'content/' +
            program.Alias +
            '/' +
            program.ProgramId +
            '/' +
            this.batch.BatchId +
            '/' +
            content.ContentId
        );
      } else {
        this.router.navigateByUrl(
          'content/' +
            program.Alias +
            '/' +
            program.ProgramId +
            '/' +
            content.ContentId
        );
      }
    } else {
      this.snackbar.open(content.Title + ' will unlock on ' + moment(content.UnlockOn).format('MMM d, y') + ' at ' + moment(content.UnlockOn).format('h:mm a'));
    }
  }


  startContentFlowV2(content: any) {
    if (!content.IsLocked) {
      if (this.batch) {
        this.router.navigateByUrl(
          'content/' +
            this.program.Alias +
            '/' +
            this.program.ProgramId +
            '/' +
            this.batch.BatchId +
            '/' +
            content.ContentId
        );
      } else if (this.programParticipant) {
        this.router.navigateByUrl(
          'content/' +
            this.program.Alias +
            '/' +
            this.program.ProgramId +
            '/' +
            this.batch.BatchId +
            '/' +
            content.ContentId
        );
      } else {
        this.router.navigateByUrl(
          'content/' +
            this.program.Alias +
            '/' +
            this.program.ProgramId +
            '/' +
            content.ContentId
        );
      }
    } else {
      this.snackbar.open(content.Title + ' will unlock on ' + moment(content.UnlockOn).format('MMM d, y') + ' at ' + moment(content.UnlockOn).format('h:mm a'));
    }
  }

  markCompleted = (event: any) => {
    this.contentMetas[event.ContentId] = event;
  };

  closeCurrentContent(event: any, d: any) {
    console.log(event);
    this.contentboxclosed = true;

    if (
      event &&
      event.detail &&
      event.detail.data &&
      event.detail.data.closeParent
    ) {
      this.closeDialog.emit();
    } else {
      setTimeout(() => {
        if (this.clearProgramId) {
          this.router.navigate([], {
            relativeTo: this.route,
            queryParams: {
              pId: null,
              bId: null,
              ci: null,
              l: null,
              cg: null,
              c: null,
            },
            queryParamsHandling: 'merge',
          });
        } else {
          this.router.navigate([], {
            relativeTo: this.route,
            queryParams: {
              ci: null,
              l: null,
              cg: null,
              c: null,
            },
            queryParamsHandling: 'merge',
          });
        }
        setTimeout(() => {
          this.currentContent = undefined;
          this.modalsetup = false;
        }, 100);
      }, 100);
    }
  }

  registerProducts() {
    // this.store.register({
    //   id: this.subscription_name,
    //   type: this.store.PAID_SUBSCRIPTION,
    // });
    // // this.store.refresh();
  }

  // setupListeners() {
  //   // General query to all products
  //   this.store
  //     .when('product')
  //     .approved((p: any) => {
  //       if (p.id == this.subscription_name) {
  //         p.verify();
  //         this.apiService
  //           .coreOrderAndroidApproved(p.additionalData.orderId, {
  //             PlaySubscriptionState: p.state,
  //             PlayDeveloperPayload: p.transaction.developerPayload,
  //             PlayPurchaseState: p.transaction.purchaseState,
  //             PlayPurchaseToken: p.transaction.purchaseToken,
  //             PlayReceipt: p.transaction.receipt,
  //             PlaySignature: p.transaction.signature,
  //             PlayType: p.transaction.type,
  //             PlayTransactionId: p.transaction.id,
  //           })
  //           .then((res: any) => {
  //             if (res.success) {
  //               p.verify();
  //             }
  //           })
  //           .catch((e) => {
  //             console.log('Error');
  //             this.resetPurchaseLoaders();
  //           });
  //       }
  //     })
  //     .verified((p: any) => {
  //       console.log('Verified : ', p);

  //       this.apiService
  //         .coreOrderAndroidApproved(p.additionalData.orderId, {
  //           PlaySubscriptionState: p.state,
  //           PlayDeveloperPayload: p.transaction.developerPayload,
  //           PlayPurchaseState: p.transaction.purchaseState,
  //           PlayPurchaseToken: p.transaction.purchaseToken,
  //           PlayReceipt: p.transaction.receipt,
  //           PlaySignature: p.transaction.signature,
  //           PlayType: p.transaction.type,
  //           PlayTransactionId: p.transaction.id,
  //         })
  //         .then((res: any) => {
  //           if (res.success) {
  //             p.finish();
  //           }
  //         })
  //         .catch((e) => {
  //           console.log('Error');
  //           this.resetPurchaseLoaders();
  //         });

  //       // var data: any = {
  //       //   BaseAmount: p.priceMicros / 1000000,
  //       //   Total: p.priceMicros / 1000000,
  //       //   Currency: p.currency,
  //       //   PaymentTxMsg: JSON.stringify(p.transaction),
  //       //   Trial: false,
  //       // };

  //       // if (p.id === this.VENUS_CORE_TRIAL) {
  //       //   data.Trial = true;
  //       //   this.joiningtrial = true;
  //       // }

  //       // if (p.id == this.subscription_name) {
  //       //   this.apiService
  //       //     .processJoiningBatch(data, this.joiningBatchCode, this.joiningtrial)
  //       //     .then((res: any) => {
  //       //       this.loading = false;
  //       //       if (res.success) {
  //       //         p.finish();
  //       //       }
  //       //     })
  //       //     .catch((error) => {
  //       //       if (!error.msg) {
  //       //         error.msg = 'Something went wrong';
  //       //       }
  //       //       this.snackbar.open(error.msg);
  //       //       this.loading = false;
  //       //     });
  //       // }
  //     })
  //     .finished((p: any) => {
  //       this.zone.run(() => {
  //         this.apiService
  //           .coreOrderAndroidAcknowledged(p.additionalData.orderId, {
  //             PlaySubscriptionState: p.state,
  //             PlayDeveloperPayload: p.transaction.developerPayload,
  //             PlayPurchaseState: p.transaction.purchaseState,
  //             PlayPurchaseToken: p.transaction.purchaseToken,
  //             PlayReceipt: p.transaction.receipt,
  //             PlaySignature: p.transaction.signature,
  //             PlayType: p.transaction.type,
  //             PlayTransactionId: p.transaction.id,
  //           })
  //           .then((res: any) => {
  //             const dialogRef = this.dialog.open(ConfirmDialogComponent, {
  //               width: '450px',
  //               data: {
  //                 title: 'Thank you for starting your journey with Adios',
  //                 message:
  //                   'Thank you for subscribing to Adios. We have emailed you the subscription details. You will receive the details of the program in a separate email.\n\n Please go through the weekly sessions and attend the live Zoom sessions to get your questions answered and keep yourself motivated.',
  //                 positive: 'GET STARTED',
  //                 showNegative: false,
  //               },
  //             });

  //             dialogRef.afterClosed().subscribe((result) => {
  //               //console.log(result);
  //               if (result) {
  //                 // this.viewProgramSpace(event.programParticipant);
  //               }
  //             });

  //             this.resetPurchaseLoaders();
  //             this.ngOnInit();
  //           })
  //           .catch((e) => {
  //             console.log('Error');
  //             this.resetPurchaseLoaders();
  //           });
  //       });
  //     })
  //     .error((p: any) => {
  //       this.zone.run(() => {
  //         this.resetPurchaseLoaders();
  //       });
  //     })
  //     .invalid((p: any) => {
  //       this.zone.run(() => {
  //         this.resetPurchaseLoaders();
  //       });
  //     })
  //     .cancelled((p: any) => {
  //       this.zone.run(() => {
  //         this.resetPurchaseLoaders();
  //       });
  //     });

  //   // Specific query for one ID
  //   this.store.when(this.subscription_name).owned((p: IAPProduct) => {
  //     console.log('Venus Core ', p);
  //   });
  //   this.store.error((e) => {
  //     console.log('ERROR ' + e.code + ': ' + e.message);
  //     this.zone.run(() => {
  //       this.resetPurchaseLoaders();
  //     });
  //   });
  // }

  resetPurchaseLoaders() {
    // this.joiningBatch = false;
    // this.joiningtrial = false;
    // this.joiningBatchId = undefined;
    // this.joiningBatchCode = undefined;
    // this.upgradingTrial = false;
    // this.ref.detectChanges();
    this.userService.clearUpgradingParticipant();
    this.userService.clearJoiningCohort();
    this.subscribing = false;
  }

  // purchase(product: IAPProduct) {
  //   this.subscribing = true;

  //   this.apiService
  //     .createAndroidInAppOrder({
  //       BaseAmount: product.priceMicros / 1000000,
  //       Total: product.priceMicros / 1000000,
  //       FinalAmount: product.priceMicros / 1000000,
  //       Amount: product.priceMicros / 1000000,
  //       TotalWithTax: product.priceMicros / 1000000,
  //       Currency: product.currency,
  //       ProgramCategoryId: this.program.ProgramCategoryId,
  //       MembershipOfferId: this.membership.Offers[0].MembershipOfferId,
  //       PlaySubscriptionId: this.subscription_name,
  //       PlaySubscriptionState: 'initiated',
  //       Trial: false,
  //     })
  //     .then((res: any) => {
  //       if (res.success) {
  //         this.store
  //           .order(product, {
  //             orderId: res.data.OrderId,
  //             orderReference: res.data.OrderReference,
  //             category: environment.category,
  //             userId: this.userService.user.UserId,
  //             email: this.userService.user.Email,
  //             applicationUsername:
  //               this.userService.user.FirstName +
  //               ' ' +
  //               this.userService.user.LastName,
  //           })
  //           .then(
  //             (p) => {
  //               // Purchase in progress!=
  //               console.log('Purchase in progress! ', p);
  //             },
  //             (e) => {
  //               this.subscribing = false;
  //               this.presentAlert('Failed', `Failed to purchase: ${e}`);
  //             }
  //           );
  //       } else {
  //         this.subscribing = false;
  //       }
  //     })
  //     .catch((e) => {
  //       console.log('Error');
  //       if (!e.msg) {
  //         e.msg = 'Something went wrong';
  //       }
  //       this.snackbar.open(e.msg);
  //     });
  // }

  // // To comply with AppStore rules
  // restore() {
  //   this.store.refresh();
  // }

  async presentAlert(header, message) {
    const alert = await this.alertController.create({
      header,
      message,
      buttons: ['OK'],
    });

    await alert.present();
  }
}
