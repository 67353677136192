import { Location } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/shared/services/api.service';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'sb-view-situation',
  templateUrl: './view-situation.component.html',
  styleUrls: ['./view-situation.component.scss']
})
export class ViewSituationComponent implements OnInit {
  
  @Input() situationId: any;
  @Input() fullWidth?: boolean = false;
  @Output() loaded?: EventEmitter<any> = new EventEmitter<any>();

  programParticipant: any;
  // userId: any;
  loading: boolean = false;

  situation: any;
  recommendedSituations: any = [];
  recentSituations: any = [];
  principles: any = [];
  principlesMap: any = {};

  analysis: any = {
    SituationPrincipleMaps: [
    ],
    SituationPrincipleMapsDict: {}
  };
  detailPrinciple: any;
  loadingDetails: boolean = false;

  constructor(
    public route: ActivatedRoute,
    private apiService: ApiService,
    public userService: UserService,
    private snackbar: MatSnackBar,
    public dialog: MatDialog,
    public modalService: NgbModal,
    private _location: Location,
    private router: Router
  ) {
    // this.userId = this.userService.user.UserId;
  }

  ngOnInit() {
    this.getSituation();
  }

  getSituation() {
    this.loading = true;
    this.apiService.viewSituation(this.situationId).then((res: any) => {
      this.situation = res.data.situation;
      this.recommendedSituations = res.data.recommendedSituations;
      this.recentSituations = res.data.recentSituations;
      this.loaded?.emit(this.situation);
      setTimeout(() => {
        this.situation.SituationMaps.forEach((analysis: any) => {
          if (analysis.CreatedOn && analysis.CreatedOn.indexOf('Z') == -1) {
            analysis.CreatedOn = analysis.CreatedOn + 'Z';
          }
          analysis.SituationPrincipleMapsDict = {};
          analysis.SituationPrincipleTriggerMaps.forEach((pm: any) => {
            analysis.SituationPrincipleMapsDict[pm.PrincipleTriggerId] = pm;
          });
        });
        this.loading = false;
      }, 100);
    }).catch(e => {
      console.log(e);
      if (!e.msg) {
        e.msg = "Something went wrong";
      }
      this.snackbar.open(e.msg);
      this.loading = false;
      this._location.back();
    });
  }

  viewPrincipleDescription(content: any, maps: any) {
    this.loadingDetails = true;
    this.detailPrinciple = maps.Principle;
    this.modalService.open(content, { size: 'lg', centered: true, backdrop: 'static', backdropClass: 'popup-backdrop', windowClass: 'index-fix' });
    
    setTimeout(() => {
      this.loadingDetails = false;
    }, 500);

  }

  viewSituation(s: any) {
    this.router.navigateByUrl('/adioscope/view/' + s.SituationId);
    this.ngOnInit();
  }

  createSituation(){
    console.log('/adioscope/create');
    this.router.navigateByUrl('/adioscope/create');
    this.modalService.dismissAll();
  }


}
