import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { ApiService } from '../../services/api.service';
import { UserService } from '../../services/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-conversation',
  templateUrl: './conversation.component.html',
  styleUrls: ['./conversation.component.scss']
})
export class ConversationComponent implements OnInit {

  // @ViewChild(ScrollDirective) messageScrollDirective?: ScrollDirective;
  @ViewChild('listbottom', { static: false }) listbottom?: ElementRef;
  @ViewChild('unreadlist', { static: false }) unreadlist?: ElementRef;
  @ViewChild('messagelist', { static: false }) messagelist?: ElementRef;
  @ViewChild(InfiniteScrollDirective, { static: false }) infiniteScrollDirective?: InfiniteScrollDirective;

  @Input('isTrainer') isTrainer?: boolean = false;
  @Input('batchId') batchId: string | any;
  loading?: boolean = false;
  conversationGroup: any;
  messages: any = [];
  unread: any = [];
  userId: string;
  newMessageText?: any = {};
  dateOffset: any;
  sendingMessage: boolean = false;
  loadingPreviousMessages: boolean = false;
  loadingMessages: boolean = false;
  lastread: any;
  hasNewMessages: boolean = false;
  sas: any;
  refreshInterval: any;

  constructor(
    private router: Router, 
    private snackbar: MatSnackBar, 
    private apiService: ApiService, 
    private _authService: UserService
  ) {
    this.userId = this._authService.user.UserId;
    this.dateOffset = this.apiService.getDateOffset();
    this.sas = environment.sas;
  }

  ngOnInit(): void {
    this.loading = true;
    this.getConversationDetails();
  }

  ngOnDestroy(): void {
    clearInterval(this.refreshInterval);
  }

  getConversationDetails() {
    this.apiService.getConversationGroup(this.batchId)
      .then((res: any) => {
        this.loading = false;
        this.conversationGroup = res.data.converation;
        this.lastread = res.data.lastread;
        this.messages = res.data.messages;
        this.unread = res.data.unread;

        this.refreshInterval = setInterval(() => {
          console.log("Set Fetching Interval");
          this.fetchMessages();
        }, 7000);

        setTimeout(() => {
          if (this.unread > 0) {
            this.unreadlist?.nativeElement.scrollIntoView();
          } else {
            this.listbottom?.nativeElement.scrollIntoView();
          }
        }, 100);
      }).catch((error) => {
        // if (!error.msg) {
        //   error.msg = "Something went wrong";
        // }
        // this.snackbar.open(error.msg);
        this.loading = false;
      });
  }

  markSubmissionAsReviewed(message) {
    if(message.IsReviewing) {
      return false;
    }
    message.IsReviewing = true;
    this.apiService.approveSubmissionAsReviewed(message.MessageId)
    .then((res: any) => {
      message.IsReviewing = false;  
      message.IsReviewed = true;
      // message = res.data;
      this.snackbar.open("Submission Reviewed");
    }).catch((error) => {
      if (!error.msg) {
        error.msg = "Something went wrong";
      }
      this.snackbar.open(error.msg);
      message.IsReviewing = false;  
    });
  }

  sendMessage() {
    if (this.newMessageText.Text && this.newMessageText.Text != '') {
      this.sendingMessage = true;
      this.newMessageText.ConversationGroupId = this.conversationGroup.ConversationGroupId;
      this.newMessageText.BatchId = this.conversationGroup.BatchId;
      this.newMessageText.BatchId = this.conversationGroup.BatchId;
      this.newMessageText.Type = 'TEXT';

      var last_message = new Date().toISOString();
      if (this.messages.length > 0) {
        last_message = this.messages[this.messages.length - 1].CreatedOn;
      }
      this.apiService.sendMessage(last_message, this.newMessageText)
        .then((res: any) => {
          this.lastread = res.data.lastread;
          this.messages = this.messages.concat(this.unread);
          this.unread = [];
          this.messages = this.messages.concat(res.data.messages);
          this.newMessageText = {};
          this.sendingMessage = false;
          setTimeout(() => {
            this.listbottom?.nativeElement.scrollIntoView();
          }, 100);
        }).catch((error) => {
          if (!error.msg) {
            error.msg = "Something went wrong";
          }
          this.snackbar.open(error.msg);
          this.sendingMessage = false;
        });
    }
  }

  scrollToMessage() {

  }

  loadMore() {
    // console.log("load more + ", this.messagelist?.nativeElement.scrollTop);
  }

  onScrollDown() {
    console.log("scrolled down!!");
  }

  onUp() {
    console.log("scrolled up!");
    if (!this.loadingPreviousMessages) this.fetchPreviousMessages();
  }

  fetchPreviousMessages() {
    this.loadingPreviousMessages = true;
    if (this.messages.length > 0) {
      var first_message = this.messages[0].createdOn;
      var last_message = this.messages[this.messages.length - 1].createdOn;
      var first_message_id = this.messages[0].messageId;
      if (this.unread.length > 0) {
        last_message = this.unread[this.unread.length - 1].createdOn;
      }

      this.apiService.getMessages(this.batchId, first_message, last_message)
        .then((res: any) => {
          // res.data.previous_messages.forEach((element:any) => {
          //   this.messages.unshift(element);
          // });
          this.messages = res.data.previous_messages.concat(this.messages);
          this.unread = this.unread.concat(res.data.new_messages);
          this.loadingPreviousMessages = false;
          document.getElementById(first_message_id)?.scrollIntoView();
        }).catch((error) => {
          if (!error.msg) {
            error.msg = "Something went wrong";
          }
          this.snackbar.open(error.msg);
          this.loadingPreviousMessages = false;
        });
    }
  }

  fetchMessages() {
    this.loadingMessages = true;
    var last_message = undefined;
    var first_message = undefined;

    if (this.messages.length > 0) {
      var last_message = this.messages[this.messages.length - 1].CreatedOn;
      var first_message_id = this.messages[0].MessageId;
    }

    if (this.unread.length > 0) {
      last_message = this.unread[this.unread.length - 1].CreatedOn;
    }

    this.apiService.getMessages(this.batchId, first_message, last_message)
      .then((res: any) => {
        if (res.data.new_messages.length > 0) {
          console.log("Scroll Top " + this.messagelist?.nativeElement.scrollTop);
          console.log("Scroll Height " + this.messagelist?.nativeElement.scrollHeight);
          console.log("Scroll Offset " + this.messagelist?.nativeElement.offsetHeight);
          if (this.messagelist?.nativeElement.scrollTop == (this.messagelist?.nativeElement.scrollHeight - this.messagelist?.nativeElement.offsetHeight)) {
            this.unread = this.unread.concat(res.data.new_messages);
            this.messages = this.messages.concat(this.unread);
            this.unread = [];
            setTimeout(() => {
              this.listbottom?.nativeElement.scrollIntoView();
            }, 100);
          } else {
            this.unread = this.unread.concat(res.data.new_messages);
            this.hasNewMessages = true;
            setTimeout(() => {
              this.hasNewMessages = false;
            }, 3000);
          }
        }
      }).catch((error) => {
        // if (!error.msg) {
        //   error.msg = "Something went wrong";
        // }
        // this.snackbar.open(error.msg);
        this.loadingPreviousMessages = false;
      });
  }

}
