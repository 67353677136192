<ng-container *ngIf="!userService.user.AuthCode || !userService.user.RefreshToken">
  <div class="d-flex gap-3 place-center">
    <button *ngIf="scriptLoaded" class="btn btn-default d-flex align-center mw-25 place-center" type="button" (click)="calendarConsent()">
      Google
    </button>
    <button *ngIf="scriptLoaded" class="btn btn-default d-flex align-center mw-25 place-center" type="button" [disabled]="true">
      Outlook (Coming Soon)
    </button>
  </div>
</ng-container>

<ng-container *ngIf="userService.user.AuthCode && userService.user.RefreshToken">
  <button class="btn d-flex bg-white text-dark w-100" type="button">
    <mat-icon class="me-2">check_circle</mat-icon> Google Calendar
  </button>
</ng-container>