<ion-content class="ion-padding">
  <div class="fullscreen beige-space">
    <div class="box" style="max-width: 800px;">
      <div class="back" (click)="closeModal()">
        <mat-icon>close</mat-icon>
      </div>
      <h1 class="title flex">Congratulations, {{ name }}! You resolved this situation on your own!
        <div class="ml-auto align-self-end pr-4"></div>
      </h1>
      <div class="box-body" [class.celebration]="showCelebration">
        <img class="throw" *ngIf="showCelebration" src="../../../../assets/images/ballon-beige.gif">
        <ng-container *ngIf="!showCelebration && !loading">
          <div class="happy-content pb-5 mb-5">
            <ng-container *ngIf="!importanceSet">

              <h2>You have resolved {{numSituations}} situations until now.</h2>

              <h2>With every situation you resolve, you will get better and better at finding your own answers in your
                life on your own without relying on anyone else.
              </h2>
              <h2>And some situations take longer while others might be resolved quickly.</h2>
              <h2 class="mb-5">Take your time. No rush.</h2>
  
            </ng-container>
            <div class="question-card">
              <ng-container *ngIf="!importanceSet">
                <h2>How important was the situation for you?</h2>
                <div class="options">
                  <button class="btn btn-primary" [class.selected]="selectedOption == 1"
                    (click)="selectOption(1)">Medium</button>
                  <button class="btn btn-primary" [class.selected]="selectedOption == 2"
                    (click)="selectOption(2)">High</button>
                  <button class="btn btn-primary" [class.selected]="selectedOption == 3" (click)="selectOption(3)">Life
                    changing</button>
                </div>
              </ng-container>

              <div class="w-100" *ngIf="isSettingImportance">
                <p class="mt-5 text-center ng-star-inserted dflex flex-1 place-center align-center mb-0">
                  <span class="spinner-border spinner-border text-primary" role="status" aria-hidden="true"></span>
                </p>
              </div>

              <div *ngIf="!isSettingImportance && importanceSet && !loveSent">
                <h2>Thank you.</h2>
              </div>

              <div *ngIf="!isSettingImportance && importanceSet && loveSent">
                <h2>We have sent your love to our
                  team. Thank you for your kindness :)</h2>
              </div>

              <div class="message mt-5" *ngIf="selectedOption && !isSettingImportance && importanceSet && !loveSent">
                <h2>Our team works passionately every single day to make minute improvements in the Adios system to
                  enable you to find your answers more efficiently.</h2>

                <h2>A short message from your side will cheer them up. Would you like to say something to them?</h2>
                <mat-form-field class="w-100" appearance="outline">
                  <textarea matInput [(ngModel)]="messageToTeam" cols="5" rows="5" class="text-muted"
                    [disabled]="sendingLove" placeholder="Words of encouragement..." style="line-height: 1.5;"></textarea>
                </mat-form-field>
                <h2>If our team members were here right now you would give them:</h2>
                <div class="icons mb-5">
                  <button class="btn btn-primary bg-white dflex flex-column" [disabled]="sendingLove"
                    [class.selected]="selectedIcon == 'thank-you-handshake'"
                    (click)="selectIcon('thank-you-handshake')">
                    <img src="../../../../assets/images/handshake.gif" style="width: 100px; margin-bottom: 10px;">
                    <span>A handshake</span>
                  </button>
                  <button class="btn btn-primary bg-white dflex flex-column" [disabled]="sendingLove"
                    [class.selected]="selectedIcon == 'friendly-hug'" (click)="selectIcon('friendly-hug')">
                    <img src="../../../../assets/images/hug-me.gif" style="width: 100px; margin-bottom: 10px;">
                    <span>A friendly hug</span>
                  </button>
                  <button class="btn btn-primary bg-white dflex flex-column" [disabled]="sendingLove"
                    [class.selected]="selectedIcon == 'bear-hugs-and-kisses'"
                    (click)="selectIcon('bear-hugs-and-kisses')">
                    <img src="../../../../assets/images/bear-bear-hug.gif" style="width: 100px; margin-bottom: 10px;">
                    <span>Bear hugs and kisses</span>
                  </button>
                </div>

                <button *ngIf="!sendingLove" class="btn btn-primary text-white"
                  [disabled]="sendingLove || (!selectedIcon && (!messageToTeam || messageToTeam == ''))"
                  (click)="sendLove()">Send your love</button>

                <div class="" *ngIf="sendingLove">
                  <p class="text-center ng-star-inserted dflex flex-1 place-center align-center mb-0">
                    <span class="spinner-border spinner-border text-primary" role="status" aria-hidden="true"></span>
                  </p>
                </div>

              </div>
            </div>


            <div class="pt-2 question-card" *ngIf="loveSent">
              <div class="question-header">
                <h1>Share Contemplate with someone you care about</h1>
                <p>Do you think someone you know might also benefit from Contemplation or the Adios principles?</p>
                <p>Let them know what you think and you might just give them the gift of clarity of thought for the rest of their life!</p>
                <img src="../../assets/images/icons/venus-color-icon-1.png">
  
              </div>
              <app-recommend-box></app-recommend-box>
            </div>

          </div>
        </ng-container>
      </div>
    </div>
  </div>


  <!-- <p>{{message}}</p>
    <ion-item>
      <ion-label>How important was the situation for you?</ion-label>
      <ion-select [(ngModel)]="importance">
        <ion-select-option value="medium">Medium</ion-select-option>
        <ion-select-option value="high">High</ion-select-option>
        <ion-select-option value="life-changing">Life Changing</ion-select-option>
      </ion-select>
    </ion-item>
    <ion-item>
      <ion-label position="floating">Would you like to send a message to our team?</ion-label>
      <ion-textarea [(ngModel)]="messageToTeam"></ion-textarea>
    </ion-item>
    <ion-item>
      <ion-label>Choose an icon to represent your message:</ion-label>
      <ion-grid>
        <ion-row>
          <ion-col><ion-icon name="handshake-outline" (click)="selectedIcon = 'handshake'"></ion-icon></ion-col>
          <ion-col><ion-icon name="happy-outline" (click)="selectedIcon = 'happy'"></ion-icon></ion-col>
          <ion-col><ion-icon name="heart-outline" (click)="selectedIcon = 'heart'"></ion-icon></ion-col>
        </ion-row>
      </ion-grid>
    </ion-item> -->
</ion-content>