<div class="notes-box">
    <mat-sidenav-container class="w-100 h-100 p-0" [hasBackdrop]="true" (backdropClick)="closeMenu()">
        <mat-sidenav #sidenav mode="push" (keydown.escape)="closeMenu()">
            <div class="room-menu-header">
                <h1 class="m-0">Zach</h1>
            </div>
            <button class="room-menu-item" (click)="openContemplationRoom(null)"
                [class.unread]="!currentContext.UpdatedOn || !currentContext.LastSeenOn || currentContext.UpdatedOn > currentContext.LastSeenOn">
                <div class="dflex flex-row w-100">
                    <h1>Concierge</h1>
                    <p *ngIf="currentContext.UpdatedOn">{{ currentContext.UpdatedOn | customDate: 'fromNow' }}</p>
                </div>
                <div class="dflex flex-row w-100 align-center">
                    <p class="last-message" *ngIf="currentContext.IsLastMessageByZach == undefined">
                        Setting up...
                    </p>
                    <p class="last-message" *ngIf="currentContext.IsLastMessageByZach != undefined">
                        <span *ngIf="currentContext.IsLastMessageByZach">Z : </span>
                        <span *ngIf="!currentContext.IsLastMessageByZach">You : </span>
                        {{ currentContext.LastMessage }}
                    </p>
                </div>
            </button>
            <div class="room-menu-section">Your Contemplation Rooms</div>
            <div class="room-menu pt-0" [class.w-100]="contemplationRoomSetups.length == 0" [class.h-100]="contemplationRoomSetups.length == 0" cdkMenu>
                <div class="dflex flex-row w-100 h-100 place-center align-center" *ngIf="contemplationRoomSetups.length == 0">
                    <p>No Contemplation rooms.</p>
                </div>

                <button class="room-menu-item" *ngFor="let room of contemplationRoomSetups" cdkMenuItem
                    (click)="openContemplationRoom(room)"
                    [class.unread]="!room.value.UpdatedOn || !room.value.LastSeenOn || room.value.UpdatedOn > room.value.LastSeenOn">


                    <div class="dflex flex-row w-100" *ngIf="room.value.UpdatedOn">
                        <h1>{{room.value.Title}}</h1>
                        <p>{{ room.value.UpdatedOn | customDate: 'fromNow' }}</p>
                    </div>
                    <div class="dflex flex-row w-100 align-center" *ngIf="room.value.LastMessage">
                        <p class="last-message">
                            <span *ngIf="room.value.IsLastMessageByZach">Z : </span>
                            <span *ngIf="!room.value.IsLastMessageByZach">You : </span>
                            {{ room.value.LastMessage }}
                        </p>
                    </div>
                </button>
            </div>
        </mat-sidenav>
        <mat-sidenav-content class="sidenavContent dflex flex-column">
            <ng-container *ngIf="loadingRoom">
                <div class="box-container content-center">
                    <div class="spinner-border" role="status" *ngIf="loadingRoom">
                        <span class="sr-only"></span>
                    </div>
                    <!-- <p *ngIf="!contemplationRoom">Setting up...</p> -->
                    <p *ngIf="contemplationRoom && contemplationRoom.value.SetupRequired">Setting up your Contemplation room. Please wait...</p>
                    <p *ngIf="contemplationRoom && !contemplationRoom.value.SetupRequired">Taking you to your Contemplation room. Please wait...</p>

                    <p *ngIf="!contemplationRoom && settingUp">Setting up your main room. Please wait...</p>
                    <p *ngIf="!contemplationRoom && !settingUp">Taking you to your main room. Please wait...</p>
                </div>
            </ng-container>

            <ng-container *ngIf="!loadingRoom">

                <mat-toolbar class="w-100 room-toolbar">
                    <button class="mr-1" mat-icon-button (click)="sidenav.toggle()">
                        <mat-icon color="primary" svgIcon="head_icon"
                            style="font-size: 32px; width: 32px; height: auto;"></mat-icon>
                    </button>
                    <span class="tooltitle" *ngIf="contemplationRoom">{{ contemplationRoom.value.Title }}</span>
                    <span class="tooltitle" *ngIf="!contemplationRoom"></span>
                    <span class="spacer"></span>
                    <button mat-icon-button *ngIf="contemplationRoom">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                </mat-toolbar>
                <div class="list-box p-0" *ngIf="conversations.length == 0 && !receivingMessage && !requestingMessage">
                    <div class="row m-0 w-100">
                        <div class="col-md-12 col-md-12 dflex content-center">
                            <div class="box content-center">
                                <!-- <p>No messages yet</p> -->
                                <div class="lds-ellipsis">
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div #messagesList id="messagesList" class="message-list scrollbox" *ngIf="conversations.length > 0 || receivingMessage || requestingMessage"
                    infiniteScroll [infiniteScrollUpDistance]="2" [infiniteScrollDistance]="2" [scrollWindow]="false"
                    [alwaysCallback]="true" (scrolledUp)="onUp()" (scrolled)="onScrollDown()">

                    <div class="spinner-border inline-center" role="status" *ngIf="loadingPreviousMessages">
                        <span class="sr-only"></span>
                    </div>
                    <ng-container *ngFor="let message of conversations; let i = index;">
                        <ng-container *ngIf="message.Type != messageType.systemMessage">
                            <div class="message-card" [class.left]="message.IsZach" [class.right]="!message.IsZach"
                                [id]="message.Key">

                                <div class="profile-icon" *ngIf="message.IsZach">
                                    Z
                                </div>
                                <div class="profile-icon" *ngIf="!message.IsZach && username">
                                    {{ username[0] }}
                                </div>
                                <div class="content-box">
                                    <ng-container *ngIf="message.Type == messageType.text">
                                        <p class="m-0">{{ message.Message }}</p>
                                    </ng-container>
                                    <ng-container *ngIf="message.Type == messageType.action">
                                        <p>{{ message.Message }}</p>
                                        <ul class="actions">
                                            <ng-container *ngIf="message.Actions">
                                                <li *ngFor="let action of message.Actions | keyvalue: asIsOrder"
                                                    (click)="processUserActions(action.key, message)">{{ action.value }}
                                                </li>
                                            </ng-container>
                                            <ng-container *ngIf="message.Options">
                                                <li *ngFor="let action of message.Options | keyvalue: asIsOrder"
                                                    (click)="processActions(action.key, message)">{{ action.value }}
                                                </li>
                                            </ng-container>
                                        </ul>
                                    </ng-container>
                                    <!-- <p class="timestamp">
                                    <small>{{ message.CreatedOn | date: 'MMM d, y, h:mm a': dateOffset}}</small>
                                </p> -->
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                    <div class="message-card left" *ngIf="processingAction || receivingMessage || requestingMessage">
                        <div class="lds-ellipsis">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    </div>
                </div>

                <div class="message-input-box w-100" *ngIf="
                    (
                        !contemplationRoom && currentContext && !processingAction &&
                        currentContext?.Context && 
                        (
                            (currentContext.ContextType == contextType.createSituation && (currentContext?.Context.NextAction == createSituationNextPropertyList.Title || currentContext?.Context.NextAction == createSituationNextPropertyList.Description))
                        )
                    ) || 
                    (
                        contemplationRoom && !processingAction && 
                        (
                            (contemplationRoom.value.Type == userAssistantContextType.analyseSituation && (contemplationRoom.value.NextAction == analyseNextPropertyList.TriggerAnalysis || contemplationRoom.value.NextAction == analyseNextPropertyList.PrincipleAnalysis))
                        )
                    )
                    ">
                    <mat-label class="place-start mt-2 mb-1">
                        <span
                            *ngIf="!contemplationRoom && currentContext && currentContext.Context && currentContext.ContextType == contextType.createSituation && currentContext?.Context.NextAction == createSituationNextPropertyList.Description">Please
                            describe your situation</span>
                        <span
                            *ngIf="!contemplationRoom && currentContext && currentContext.Context && currentContext.ContextType == contextType.createSituation && currentContext?.Context.NextAction == createSituationNextPropertyList.Title">Situation
                            Title</span>
                    </mat-label>

                    <mat-form-field appearance="outline" class="input-box" style="flex: 1;">

                        <textarea
                            *ngIf="!contemplationRoom && currentContext && currentContext.Context && currentContext.ContextType == contextType.createSituation && currentContext?.Context.NextAction == createSituationNextPropertyList.Description"
                            [disabled]="sendingTextMessage" matInput class="message-input contemplation-space"
                            [placeholder]="createSituationDescriptionPlaceholder"
                            [(ngModel)]="textMessage.Text"></textarea>

                        <textarea
                            *ngIf="!contemplationRoom && currentContext && currentContext.Context && currentContext.ContextType == contextType.createSituation && currentContext?.Context.NextAction == createSituationNextPropertyList.Title"
                            [disabled]="sendingTextMessage" matInput class="message-input contemplation-space"
                            [placeholder]="'Please write your situation title here...'"
                            [(ngModel)]="textMessage.Text"></textarea>


                        <textarea
                            *ngIf="contemplationRoom && contemplationRoom.value.Type == userAssistantContextType.analyseSituation && contemplationRoom.value.NextAction == analyseNextPropertyList.TriggerAnalysis"
                            [disabled]="sendingTextMessage" matInput class="message-input contemplation-space"
                            [placeholder]="'Please write here...'" [(ngModel)]="textMessage.Text"></textarea>

                        <textarea
                            *ngIf="contemplationRoom && contemplationRoom.value.Type == userAssistantContextType.analyseSituation && contemplationRoom.value.NextAction == analyseNextPropertyList.PrincipleAnalysis"
                            [disabled]="sendingTextMessage" matInput class="message-input contemplation-space"
                            [placeholder]="'Please write here...'" [(ngModel)]="textMessage.Text"></textarea>


                    </mat-form-field>

                    <div class="action-box">
                        <div class="spinner-border p-2" *ngIf="sendingTextMessage" role="status">
                            <span class="sr-only"></span>
                        </div>
                        <ng-container *ngIf="!sendingTextMessage">
                            <app-adios-btn [addmarginleft]="true" [addmargin]="false"
                                [disabled]="!textMessage.Text || textMessage.Text.trim().length == 0"
                                (click)="processActions()">Send </app-adios-btn>
                        </ng-container>
                    </div>
                </div>


                <div class="message-input-box w-100" *ngIf="userAction">
                    <mat-label class="place-start mt-2 mb-1">
                        {{ userAction.Title }}
                    </mat-label>

                    <mat-form-field appearance="outline" class="input-box" style="flex: 1;">

                        <textarea
                            *ngIf="userAction.UserActionType == 'large-text'"
                            [disabled]="sendingTextMessage" matInput class="message-input contemplation-space"
                            [placeholder]="userAction.Placeholder"
                            [(ngModel)]="textMessage.Text"></textarea>

                    </mat-form-field>

                    <div class="action-box">
                        <div class="spinner-border p-2" *ngIf="sendingTextMessage" role="status">
                            <span class="sr-only"></span>
                        </div>
                        <ng-container *ngIf="!sendingTextMessage">
                            <app-adios-btn [addmarginleft]="true" [addmargin]="false"
                                [disabled]="!textMessage.Text || textMessage.Text.trim().length == 0"
                                (click)="processUserActions(null, conversations[conversations.length - 1])">Send </app-adios-btn>
                        </ng-container>
                    </div>
                </div>

            </ng-container>
        </mat-sidenav-content>
    </mat-sidenav-container>
</div>