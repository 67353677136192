<ion-footer class="p-2" *ngIf="currentTrack && currentTrack.type">
    <!-- <ion-toolbar class="p-0 slim">
        <mat-progress-bar class="w-100" color="primary" mode="determinate" [value]="state?.currentTime * 100 / state?.duration">
        </mat-progress-bar>
    </ion-toolbar> -->
    <ion-toolbar class="p-3 rounding" *ngIf="currentTrack">
        <ng-container *ngIf="!currentTrack.type || state?.loading">
            <p class="text-center mb-0 p-2">
                <span class="spinner-border spinner-border spinner-border-sm text-primary" role="status"
                    aria-hidden="true"></span>
            </p>
        </ng-container>
        <ng-container *ngIf="!state?.loading">
            <div class="d-flex">
                
            <ion-thumbnail slot="start" *ngIf="currentTrack?.albumArt">
                <img [src]="currentTrack?.albumArt">
            </ion-thumbnail>
            <ion-label>
                <h2>{{ currentTrack?.currentPlaylistContentTitle }}</h2>
                <p>{{ currentTrack?.currentPlaylistTitle }} 
                    <!-- <span *ngIf="state.readableRemainingTime"> {{ state.readableRemainingTime }}</span> -->
                </p>
            </ion-label>
            <div class="flex-1">

            </div>
            <ion-buttons slot="end">
                <p class="text-center mb-0 p-2" *ngIf="state.seeking">
                    <span class="spinner-border spinner-border spinner-border-sm text-primary" role="status"
                        aria-hidden="true"></span>
                </p>
                <ng-container *ngIf="!state.seeking">

                    <ion-button *ngIf="state.playbackSpeed" (click)="changePlayRate()" [disabled]="state?.loading">
                        x {{ state.playbackSpeed }}
                    </ion-button>

                    <ion-button (click)="previousContent()" [disabled]="state?.loading || !currentTrack.hasPrevious">
                        <ion-icon name="play-back"></ion-icon>
                    </ion-button>
                    <ion-button (click)="resume()" [disabled]="state?.loading" *ngIf="!state?.playing">
                        <ion-icon name="play"></ion-icon>
                    </ion-button>
                    <ion-button (click)="pause()" class="play-icons" [disabled]="state?.loading" *ngIf="state?.playing">
                        <ion-icon name="pause"></ion-icon>
                    </ion-button>
                    <ion-button (click)="nextContent()" [disabled]="state?.loading || !currentTrack.hasNext">
                        <ion-icon name="play-forward"></ion-icon>
                    </ion-button>
                </ng-container>
            </ion-buttons>
            </div>

            <div class="slider-container d-flex align-center mt-2"
                *ngIf="(state?.playing || state?.paused || state?.canplay) && !state?.loading">
                <small class="text-muted mw-150">{{ state?.readableCurrentTime }}</small>

                <mat-slider class="time-slider ml-1 mr-1 flex-1" min="0" color="primary"
                    [max]="state?.duration" step="1"
                    [value]="state?.currentTime"
                    (input)="onSliderChangeEnd($event)"
                    [disabled]="state?.error">
                </mat-slider>
                
                <!-- 
                    (input)="onSliderChangeEnd($event)"
                 -->
                <!-- <mat-slider class="time-slider ml-1 mr-1 flex-1" min="0" color="primary"
                    [max]="state?.duration" step="1"
                    [disabled]="state?.error">
                    <input matSliderThumb 
                        (input)="onSliderChangeEnd($event)"
                     [value]="state.currentTime">
                </mat-slider> -->
                <small class="text-muted mw-150 text-end">{{ state?.readableDuration }}</small>
            </div>
        </ng-container>
    </ion-toolbar>
</ion-footer>

<ion-footer class="p-2" *ngIf="metronomeState != 'stopped'">
    <ion-toolbar class="p-3 rounding">
        <div class="d-flex">
            <ion-label>
                <h2>Walking Contemplation - Steps</h2>
                <p>{{ metronomeService.tempo }} steps per minute 
                    <!-- <span *ngIf="state.readableRemainingTime"> {{ state.readableRemainingTime }}</span> -->
                </p>
            </ion-label>
            <div class="flex-1">

            </div>
            <ion-buttons slot="end">
                <ng-container>
                    <ion-button (click)="resumeMetronome()" *ngIf="metronomeState == 'paused'">
                        <ion-icon name="play"></ion-icon>
                    </ion-button>
                    <ion-button (click)="pauseMetronome()" class="play-icons" *ngIf="metronomeState == 'playing'">
                        <ion-icon name="pause"></ion-icon>
                    </ion-button>
                    <ion-button (click)="stopMetronome()">
                        <ion-icon name="stop"></ion-icon>
                    </ion-button>
                </ng-container>
            </ion-buttons>
        </div>
    </ion-toolbar>
</ion-footer>

<!-- <mat-toolbar>
    <mat-toolbar-row class="toolbar-row" cols="1" style="padding: 70px 0px !important;">
        <p class="text-center mb-0" *ngIf="state.seeking">
            <span class="spinner-border spinner-border" role="status"
                aria-hidden="true"></span>
        </p>
        <ng-container *ngIf="!state.seeking">
            <button mat-icon-button color="primary" class="play-icons"
                (click)="state?.paused || state?.canplay ? resume() : openAudioFile(options)"
                [disabled]="state?.loading" *ngIf="!state?.playing">
                <mat-icon mat-list-icon color="primary">play_circle_filled
                </mat-icon>
            </button>
            <button mat-icon-button color="primary" (click)="pause()" class="play-icons"
                [disabled]="state?.loading" *ngIf="state?.playing">
                <mat-icon mat-list-icon color="primary">pause_circle_filled
                </mat-icon>
            </button>
        </ng-container>
    </mat-toolbar-row>
    <mat-toolbar-row class="toolbar-row p-0" cols="1">

        <div class="slider-container" *ngIf="(state?.loading)">
            <p class="text-center mb-0">
                <span class="spinner-border spinner-border-lg" role="status"
                    aria-hidden="true"></span>
            </p>
        </div>
        <div class="slider-container"
            *ngIf="(state?.playing || state?.paused || state?.canplay) && !state?.loading">
            {{ state?.readableCurrentTime }}
            <mat-slider class="time-slider" min="0" color="primary"
                [max]="state?.duration" step="1" [value]="state?.currentTime"
                (input)="onSliderChangeEnd($event)" [disabled]="state?.error">
            </mat-slider>
            {{ state?.readableDuration }}
        </div>
    </mat-toolbar-row>
</mat-toolbar> -->