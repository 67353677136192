import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-adios-btn',
  templateUrl: './adios-btn.component.html',
  styleUrls: ['./adios-btn.component.scss']
})
export class AdiosBtnComponent implements OnInit {

  @Input() hideArrow?: boolean = false;

  @Input() type?: any = "button";
  @Input() disabled?: boolean = false;

  @Input() highlight?: any;

  @Input() addmargin?: boolean = true;
  @Input()addmarginleft?: boolean = true;

  constructor() { }

  ngOnInit(): void {
  }
}
