export const environment = {
  appVersion: require('../../package.json').version,
  sentry: "https://a59cf478f82db94d67d1fc70fb68f772@o4506634193731584.ingest.sentry.io/4506634198777856",
  firebase: {
    projectId: 'adios-b4bd2',
    appId: '1:820702138597:web:980f25e21bd27d0d82c40c',
    databaseURL: 'https://adios-b4bd2.firebaseio.com',
    storageBucket: 'adios-b4bd2.appspot.com',
    locationId: 'asia-south1',
    apiKey: 'AIzaSyDh9O_JMl81gdUxD6kX_q2a6sO_5ZejIu4',
    authDomain: 'adios-b4bd2.firebaseapp.com',
    messagingSenderId: '820702138597',
    measurementId: 'G-KS0LCJK0BB',
  },
  production: true,
  // domain: "http://192.168.0.138:5002/",
  domain: "https://adios-world.azurewebsites.net/",
  website: "https://contemplate.life/",
  appdomain: "app.contemplate.life",
  stripe_key: "pk_live_51IjlcWSEIRI6P3kdLlwjuGp3L7iWEggZyKXfBDojGO59xz6N0YcVgsrnswTy8irvA7gbFvj4kYuW0UM61EaaIw9300a56NZZGJ",
  storagedomain: "https://adiosworld.blob.core.windows.net/",
  sas: "?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2028-01-31T00:08:22Z&st=2024-07-24T16:08:22Z&spr=https,http&sig=ojLSjGJ2FaTw2yydXUC%2B26t2i7WGKZ2X55emg%2Bt0nDQ%3D",
  recaptcha: {
    siteKey: '6LcjY0kgAAAAAMbf5uNaQHUz3Fb1ePBcio597sNA',
    v2: "6LfTJk8gAAAAAGlBSZz_auJ0FFZVGrRWIxm4_k-9",
  },
  googleProvider: "820702138597-d6hb4fra3h9pd1bbao4m7pfqkjsmgk94.apps.googleusercontent.com",
  androidGoogleProviderKey: '820702138597-blvr3keavds2f9jffftsgt1up7je8nd8.apps.googleusercontent.com',
  iosGoogleProviderKey: '820702138597-7jpvjtd7ip4vq6ddp7v8gsbcbet4kv7v.apps.googleusercontent.com',
  category: 'core'
};

// window.console.log = function () {};