
<div class="align-items-center row" *ngIf="!isloading">
  <div class="col-md-12 text-center">
    <!-- <mat-label>Send them your love</mat-label> -->
    <mat-form-field class="w-100" appearance="outline">
      <textarea matInput [(ngModel)]="programMessage[program]" cols="5" rows="15" class="text-muted"
        style="line-height: 1.5;"></textarea>
    </mat-form-field>

    <!-- <ng-container *ngIf="!navigatorShare">

      <mat-label>Email Ids</mat-label>
      <mat-form-field class="w-100" appearance="outline">
        <mat-chip-list #chipList aria-label="Email ids" multiple [formControl]="emailControl">
          <mat-chip *ngFor="let email of emails" [selectable]="selectable" [removable]="removable"
            (removed)="remove(email)">
            {{email}}
            <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
          </mat-chip>
          <input placeholder="New email..." type="email" [matChipInputFor]="chipList"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="addOnBlur"
            (matChipInputTokenEnd)="add($event)">
        </mat-chip-list>
        <mat-hint align="end"><strong>You can add multiple email ids separated by a comma</strong> </mat-hint>
        <mat-hint *ngIf="emailError" class="text-danger" align="start"><strong>{{ emailError
            }}</strong></mat-hint>
      </mat-form-field>

    </ng-container> -->

    <p *ngIf="error">
      <small>
        <mat-hint class="text-danger" align="start"><strong>{{ error }}</strong></mat-hint>
      </small>
    </p>

    <div class="action-buttons mt-4 mb-4 text-center">
      <button mat-flat-button color="primary" type="button" class="text-white m-2"
        [cdkCopyToClipboard]="programMessage[program]" (click)="copyMessage()">Copy Message</button>
      
      <button mat-flat-button color="primary" type="button" class="text-white m-2" *ngIf="!isNative"
        (click)="shareOnWhatsapp()">Share on WhatsApp</button>

      <button mat-flat-button color="primary" type="button" class="text-white m-2" (click)="sendEmail()">Share via Email</button>

      <button mat-flat-button color="primary" *ngIf="navigatorShare" type="button" class="text-white mb-2"
        (click)="shareUsingNavigator()">Share via other apps</button>

    </div>
  </div>
</div>
<div class="align-items-center row" *ngIf="isloading">
  <div class="col-md-12 text-center p-5">
    <div class="spinner-border"></div>
  </div>
</div>
