import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ApiService } from 'src/app/shared/services/api.service';
import { SessionSchedulerService } from '../../services/session-scheduler.service';
import { UserService } from '../../services/user.service';

interface Session {
  date: string;
  startTime: string;
  sessionTime: string;
}

interface Contemplation {
  title: string;
  description: string;
  sessions: Session[];
}

@Component({
  selector: 'sb-schedule-session',
  templateUrl: './schedule-session.component.html',
  styleUrls: ['./schedule-session.component.scss']
})
export class ScheduleSessionComponent implements OnInit {

  today = new Date();
  contemplationForm: any = {};
  timeOptions: any = [];

  sessionTimes: number[] = [];


  constructor(
    private fb: FormBuilder, 
    public dialogRef: MatDialogRef<ScheduleSessionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private apiService: ApiService,
    public userService: UserService, 
    private dialog: MatDialog,
    private snackbar: MatSnackBar,
    private sessionSchedulerService: SessionSchedulerService
  ) {
    
    var importance  = this.data.importance;
    var resolvedBy  = this.data.resolvedBy;

    console.log(this.data);
    // Populate session times (15 minutes to 180 minutes)
    for (let i = 15; i <= 180; i += 15) {
      this.sessionTimes.push(i);
    }


    const start = new Date();
    start.setHours(0, 0, 0, 0);
    
    for (let i = 0; i < 96; i++) {
      const hours = start.getHours();
      const minutes = start.getMinutes().toString().padStart(2, '0');
      const period = hours >= 12 ? 'PM' : 'AM';
      const displayHours = (hours % 12) || 12; // Convert 0 to 12 for midnight
      const timeString = `${displayHours}:${minutes} ${period}`;
      const minutesSinceMidnight = (hours * 60) + start.getMinutes();
    
      this.timeOptions.push({ label: timeString, value: minutesSinceMidnight });
      start.setMinutes(start.getMinutes() + 15);
    }
    
    this.contemplationForm = {
      SituationId: this.data.situationId,
      Title: this.data.title,
      Description: this.data.description,
      CalendarTitle: this.data.title,
      CalendarConfirmation: this.data.confirmation,
      Sessions: this.sessionSchedulerService.calculateSessions(this.data.importance, this.data.resolveBy)
    };

    console.log(this.contemplationForm);

    // this.addSession();  // Add default 3 sessions
    // this.addSession();
    // this.addSession();
  }

  ngOnInit(): void {
    
  }

  addSession(): void {
    this.contemplationForm.Sessions.push({
      StartOn: new Date(),
      SessionTime: 30
    });
  }

  removeSession(index: number): void {
    this.contemplationForm.Sessions.splice(index, 1);
  }

  confirmationError: boolean = false;
  scheduling: boolean = false;
  scheduleSession() {
    if(this.scheduling){
      return;
    }

    if(!this.contemplationForm.CalendarConfirmation) {
      this.confirmationError = true;
      document.getElementById("schedule-box-title").scrollIntoView({ behavior: "smooth"});
      return;
    }
    this.confirmationError = false

    this.scheduling = true;
    console.log(this.contemplationForm);
    this.apiService.scheduleSession(this.contemplationForm).then((res: any) => {
      if(res.success) {
        this.scheduling = false;
        if(this.contemplationForm.Sessions.length > 1) {
          this.snackbar.open("Your Contemplation sessions have been scheduled.");
        } else {
          this.snackbar.open("Your Contemplation session has been scheduled.");
        }
        this.dialog.closeAll();
      }
    }).catch(e => {
      if(!e.msg) {
        e.msg = "Something went wrong";
      }
      this.snackbar.open(e.msg);
      this.scheduling = false;
      
    });
  }

  remindLater() {
    console.log(this.contemplationForm);
    this.dialog.closeAll();
  }
  
}
