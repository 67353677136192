import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { UserService } from '../user.service';

@Injectable({
  providedIn: 'root'
})
export class WebHttpService {

  constructor(
    private http: HttpClient,
    private userService: UserService
  ) {
  }

  public getFile(url: string, contentType: string) {
    return new Promise((res, rej) => {
      this.getTokenHeader().then((token: any) => {
        var httpOptions = new HttpHeaders({
          'Content-Type': contentType,
          responseType: 'blob' as 'json',
          'Token': token
        });

        this.http.get(url, { headers: httpOptions }).toPromise().then((r: any) => {
          console.log("No Error in get ", r);
          res(r);
        }).catch(e => {
          rej(e);
          //console.log("Error in get", e);
          // if (!e.ok && (e.status < 200 || e.status >= 300)) {
          //   if (e.status == 422) {
          //     rej({ success: false, data: e, msg: "helper not found" });
          //   } else if (e.status == 401) {
          //     rej({ success: false, data: e, msg: "Session Expired" });
          //   } else if (e.status == 404) {
          //     rej({ success: false, data: e, msg: "Unable to complete your request" });
          //   } else if (e.status == 403) {
          //     rej({ success: false, data: e, msg: "Unauthorized access" });
          //   } else if (e.status == 406) {
          //     rej({ success: false, data: e, msg: "Unable to update the record as its been linked somewhere" });
          //   } else {
          //     rej({ success: false, data: e });
          //   }
          // }
        });
      });
    });
  }

  public getDataFromUrl(url: string) {
    return new Promise((res, rej) => {
      this.getTokenHeader().then((token: any) => {
        var httpOptions = new HttpHeaders({
          'Content-Type': 'application/json',
          'Token': token
        });

        this.http.get(url, { headers: httpOptions, withCredentials: true }).toPromise().then((r: any) => {
          // console.log("No Error in get ", r);
          res(r);
        }).catch(e => {
          rej(e);
          //console.log("Error in get", e);
          // if (!e.ok && (e.status < 200 || e.status >= 300)) {
          //   if (e.status == 422) {
          //     rej({ success: false, data: e, msg: "helper not found" });
          //   } else if (e.status == 401) {
          //     rej({ success: false, data: e, msg: "Session Expired" });
          //   } else if (e.status == 404) {
          //     rej({ success: false, data: e, msg: "Unable to complete your request" });
          //   } else if (e.status == 403) {
          //     rej({ success: false, data: e, msg: "Unauthorized access" });
          //   } else if (e.status == 406) {
          //     rej({ success: false, data: e, msg: "Unable to update the record as its been linked somewhere" });
          //   } else {
          //     rej({ success: false, data: e });
          //   }
          // }
        });
      });
    });

  }

  public postDataToUrl(url: string, data: any) {
    return new Promise((res, rej) => {

      this.getTokenHeader().then((token: any) => {
        console.log(token);
        var httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Token': token
          })
        };

        this.http.post(url, data, httpOptions).toPromise().then((r: any) => {
          //console.log(r);
          res(r);
        }).catch(e => {
          //console.log("Error in get", e);
          rej(e);
          // if (!e.ok && (e.status < 200 || e.status >= 300)) {
          //   if (e.status == 422) {
          //     rej({ success: false, data: e, msg: "helper not found" });
          //   } else if (e.status == 401) {
          //     rej({ success: false, data: e, msg: "Session Expired" });
          //   } else if (e.status == 404) {
          //     rej({ success: false, data: e, msg: "Unable to complete your request" });
          //   } else if (e.status == 403) {
          //     rej({ success: false, data: e, msg: "Unauthorized access" });
          //   } else if (e.status == 406) {
          //     rej({ success: false, data: e, msg: "Unable to update the record as its been linked somewhere" });
          //   } else {
          //     rej({ success: false, data: e });
          //   }
          // }
        });
      });
    });
  }

  public postFormDataToUrl(url: string, data: any) {
    return new Promise((res, rej) => {

      this.getTokenHeader().then((token: any) => {
        console.log(token);
        var httpOptions = {
          headers: new HttpHeaders({
            'Token': token
          })
        };

        this.http.post(url, data, httpOptions).toPromise().then((r: any) => {
          //console.log(r);
          res(r);
        }).catch(e => {
          //console.log("Error in get", e);
          rej(e);
          // if (!e.ok && (e.status < 200 || e.status >= 300)) {
          //   if (e.status == 422) {
          //     rej({ success: false, data: e, msg: "helper not found" });
          //   } else if (e.status == 401) {
          //     rej({ success: false, data: e, msg: "Session Expired" });
          //   } else if (e.status == 404) {
          //     rej({ success: false, data: e, msg: "Unable to complete your request" });
          //   } else if (e.status == 403) {
          //     rej({ success: false, data: e, msg: "Unauthorized access" });
          //   } else if (e.status == 406) {
          //     rej({ success: false, data: e, msg: "Unable to update the record as its been linked somewhere" });
          //   } else {
          //     rej({ success: false, data: e });
          //   }
          // }
        });
      });
    });
  }

  public deleteDataFromUrl(url: string) {
    return new Promise((res, rej) => {
      this.getTokenHeader().then((token: any) => {
        var httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Token': token
          })
        };

        this.http.delete(url, httpOptions).toPromise().then((r: any) => {
          res({ success: true, data: r._body });
        }).catch(e => {
          rej(e);
          //console.log("Error in get", e);
          // if (!e.ok && (e.status < 200 || e.status >= 300)) {
          //   if (e.status == 422) {
          //     rej({ success: false, data: e, msg: "helper not found" });
          //   } else if (e.status == 401) {
          //     rej({ success: false, data: e, msg: "Session Expired" });
          //   } else if (e.status == 404) {
          //     rej({ success: false, data: e, msg: "Unable to complete your request" });
          //   } else if (e.status == 403) {
          //     rej({ success: false, data: e, msg: "Unauthorized access" });
          //   } else if (e.status == 406) {
          //     rej({ success: false, data: e, msg: "Unable to update the record as its been linked somewhere" });
          //   } else {
          //     rej({ success: false, data: e.error });
          //   }
          // }
        });
      });
    });
  }

  public putDataOnUrl(url: string, data: any) {
    return new Promise((res, rej) => {
      this.getTokenHeader().then((token: any) => {
        var httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Token': token
          })
        };

        this.http.put(url, data, httpOptions).toPromise().then((r: any) => {
          //console.log(r);
          res(r);
        }).catch(e => {
          rej(e);
          //console.log("Error in get", e);
          // if (!e.ok && (e.status < 200 || e.status >= 300)) {
          //   if (e.status == 422) {
          //     rej({ success: false, data: e, msg: "helper not found" });
          //   } else if (e.status == 401) {
          //     rej({ success: false, data: e, msg: "Session Expired" });
          //   } else if (e.status == 404) {
          //     rej({ success: false, data: e, msg: "Unable to complete your request" });
          //   } else if (e.status == 403) {
          //     rej({ success: false, data: e, msg: "Unauthorized access" });
          //   } else if (e.status == 406) {
          //     rej({ success: false, data: e, msg: "Unable to update the record as its been linked somewhere" });
          //   } else {
          //     rej({ success: false, data: e });
          //   }
          // }
        });
      });
    });
  }

  getTokenHeader() {
    return new Promise((r, j) => {

      this.userService.getToken().then((token: any) => {
        if(token) {
          r(token);
        } else {
          r('n');
        }
      }).catch(e => {
        r('n');
      });
    });
  }

}
