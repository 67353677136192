import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ModalController, Platform } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { ApiService } from '../../services/api.service';
import { VideoRecorderPopupComponent } from '../video-recorder-popup/video-recorder-popup.component';

@Component({
  selector: 'app-video-recorder',
  templateUrl: './video-recorder.component.html',
  styleUrls: ['./video-recorder.component.scss'],
})
export class VideoRecorderComponent {

  @ViewChild('recordingEle') videoEle: ElementRef<HTMLVideoElement>;
  @Input('uploadedUrl') uploadedUrl : any;
  @Input('reviewed') reviewed: boolean = false;
  @Input('reviewedBy') reviewedBy: any;
  
  @Output('onSubmitForReview') onSubmitForReview: EventEmitter<any> = new EventEmitter<any>();
  @Output('videoUploaded') videoUploaded : EventEmitter<any> = new EventEmitter<any>();

  videoFile: string;
  countdown: number = 0;
  isRecording: boolean = false;
  recorded: boolean = false;
  recordMode: boolean = true;
  recordingTime: number = 0;
  private mediaRecorder: MediaRecorder;
  private chunks: BlobPart[] = [];
  private recordInterval: any;
  uploading: boolean = false;
  _sas: any = environment.sas;
  constructor(
    private platform: Platform,
    private changeRef:ChangeDetectorRef,
    private snackbar: MatSnackBar,
    private apiService: ApiService,
    private modalCtrl: ModalController
  ) { }

  ngOnInit() {
    if(this.uploadedUrl) {
      this.uploadedUrl;
      this.recordMode = false;
    } else {
      this.recordMode = true;
    }
  }

  submitForReview() {
    this.onSubmitForReview.emit(this.uploadedUrl);
  }

  async openRecordingPopup() {

    var modal = await this.modalCtrl.create({
      component: VideoRecorderPopupComponent,
      canDismiss: true,
    });
    modal.present();
    modal.onDidDismiss().then((res:any) => {
      console.log(res);
      if(res.data) {
        this.uploadedUrl = res.data;
        this.videoUploaded.emit(res.data);
      }
    }).catch(e => {
      console.log(e);
    });
  }

  startCountdown() {
    this.countdown = 3;
    const countdownInterval = setInterval(() => {
      this.countdown--;
      if (this.countdown === 0) {
        clearInterval(countdownInterval);
        this.recordVideo();
      }
    }, 1000);
  }

  recordAgain() {
    this.recordMode = true;
    this.recaptureVideo();
  }

  cancelRecording() {
    this.recordMode = false;
    this.videoFile = null;
    this.isRecording = false;
    this.recorded = false;
  }

  recordVideo() {
    if (this.platform.is('cordova')) {
        
    } else {
      navigator.mediaDevices.getUserMedia({ video: true, audio: true }).then(stream => {
        const videoElement = this.videoEle.nativeElement;
        videoElement.srcObject = stream;
        videoElement.volume = 0.0;
        videoElement.controls = false;
        videoElement.play();

        this.mediaRecorder = new MediaRecorder(stream);
        this.chunks = [];
        this.recordingTime = 0;
        this.isRecording = true;

        this.recordInterval = setInterval(() => {
          this.recordingTime++;
        }, 1000);

        this.mediaRecorder.ondataavailable = e => this.chunks.push(e.data);
        this.mediaRecorder.onstop = () => {
          clearInterval(this.recordInterval);
          stream.getVideoTracks()[0].stop();
          const blob = new Blob(this.chunks, { type: 'video/mp4' });
          const videoURL = URL.createObjectURL(blob);
          this.videoFile = videoURL;
          this.recorded = true;
          this.isRecording = false;
          this.mediaRecorder = null;
          this.changeRef.detectChanges();
        };
        
        this.mediaRecorder.start();
      }).catch(error => {
        // this.snackbar.open("Can't access the media at this moment");
        console.error('Error accessing media devices.', error);
      });
    }
  }

  stopRecording() {
    if (this.isRecording && this.mediaRecorder) {
      if (this.videoEle) {
        (this.videoEle.nativeElement.srcObject as MediaStream).getVideoTracks()[0].stop();
      }
      this.mediaRecorder.stop();
    }
  }

  replayVideo() {
    if (this.videoFile) {
      const videoElement = document.querySelector('video#playback') as HTMLVideoElement;
      videoElement.src = this.videoFile;
      videoElement.play();
    }
  }

  recaptureVideo() {
    this.videoFile = undefined;
    this.isRecording = false;
    this.recorded = false;
    this.startCountdown();
  }

  uploadVideo() {
    if (this.platform.is('cordova')) {
      // Mobile platform upload
      // Implement the file upload logic here
    } else {
      this.uploading = true;
      this.changeRef.detectChanges();
      fetch(this.videoFile)
        .then(res => res.blob())
        .then(blob => this.apiService.uploadContentVideoFile(blob, 'recorded-video.mp4').then((r: any) => {
          this.uploading = false;
          this.changeRef.detectChanges();
          var url = r.data.urls[0];
          this.uploadedUrl = url;
          this.isRecording = false;
          this.videoFile = undefined;
          this.videoUploaded.emit(url);
          this.recordMode = false;
        }).catch(e => {
          this.uploading = false;
          if(!e.msg) {
            e.msg = "Something went wrong";
          }
          this.snackbar.open(e.msg);
        }))
      .catch(err => {
        this.uploading = false;
        this.changeRef.detectChanges();
        console.error(err)
      });
    }
  }
}
