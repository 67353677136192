import { Component } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-referral-thank-you-dialog',
  template: `
    <div class="dialog-container">
      <div class="dialog-header">
        <button class="close-icon" color="primary" mat-icon-button type="button" (click)="onClose()">
          <mat-icon>close</mat-icon>
        </button>
      </div>
      <div class="dialog-content">
        <img class="mt-2 mb-4" style="max-width: 100px" src="../../assets/images/icons/venus-color-icon-1.png">
        <h1 class="title-h1" mat-dialog-title>Thank you for recommending Contemplate!</h1>
        <p class="description-p">We will do our best to help them. We promise.</p>
      </div>
    </div>
  `,
  styles: [
    `
      .dialog-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        /* padding: 10px 15px; */
        position: relative;
      }

      .dialog-header {
        display: flex;
        justify-content: flex-end;
        position: absolute;
        right: 10px;
        top: 10px;
      }

      .close-icon {
        cursor: pointer;
        font-size: 1.5rem;
        font-weight: bold;
      }

      .dialog-content {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      .title-h1 {
        font-size: 1.5rem;
    margin-bottom: 20px;
    line-height: 1.5;
    text-align: center;
      }

      .description-p {
        font-size: 1.2rem;
        line-height: 1.5;
        text-align: center;
      }
    `
  ]
})
export class ReferralThankYouDialogComponent {
  constructor(public dialogRef: MatDialogRef<ReferralThankYouDialogComponent>) { }

  onClose(): void {
    this.dialogRef.close();
  }
}