<div class="dialog-box bg-grey">
  <div class="d-flex place-space-between align-center header">
    <h1>Schedule your Contemplation sessions</h1>
    <app-google-connect *ngIf="userService.user.AuthCode && userService.user.RefreshToken"></app-google-connect>
  </div>
  <div mat-dialog-content class="dialog-content overflow-auto">

      <mat-label class="mt-1dflex place-start" style="opacity: 0.7;">Session Title</mat-label>
      <mat-form-field class="w-100 mb-4"  appearance="outline">
        <input matInput [(ngModel)]="contemplationForm.Title" type="text" placeholder="Enter your title for your session">
      </mat-form-field>

      <mat-label class="dflex place-start" style="opacity: 0.7;">Session Description</mat-label>
      <mat-form-field class="w-100 mb-4"  appearance="outline">
        <textarea matInput [(ngModel)]="contemplationForm.Description" type="text" placeholder="Enter your description for your session">
        </textarea>
      </mat-form-field>

      <!-- <mat-hint>This title and description is only for the session. This will appear in your calendar and is not encrypted.</mat-hint> -->

      <div class="mt-5" >
        <h2 id="schedule-box-title">Schedule Contemplation Sessions in your Calendar</h2>

        <p class="mt-3">Step 1: Define your Situation Title</p>
        <mat-label class="mt-1dflex place-start" style="opacity: 0.7;">It will show in your calendar as</mat-label>
        <mat-form-field class="w-100 mb-4"  appearance="outline">
          <input matInput [(ngModel)]="contemplationForm.CalendarTitle" type="text" placeholder="Enter your title for your session">
        </mat-form-field>
        
        <mat-checkbox class="text-danger" color="warn" [(ngModel)]="contemplationForm.CalendarConfirmation">Privacy Alert: I am comfortable with this title appearing in my calendar</mat-checkbox>

        <p class="mt-5">Step 2: Schedule Contemplation Time</p>
        <ng-container *ngFor="let session of contemplationForm.Sessions; let i = index">
          <div class="session">
            <h2>Session {{ i + 1 }} 
              <span class="inline-button badge bg-white text-dark" *ngIf="contemplationForm.Sessions.length > 1" (click)="removeSession(i)">
                <mat-icon class="me-2">delete</mat-icon> Remove Session
              </span>
            </h2>
            <div class="session-control">

              <mat-form-field floatLabel="never" appearance="outline">
                <input matInput [(ngModel)]="session.StartDate" [matDatepicker]="picker" [min]="today"
                  placeholder="ex: 11/07/1994">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>
              
              <mat-form-field floatLabel="never" appearance="outline">
                <!-- <mat-label>Start Time</mat-label> -->
                <mat-select [(ngModel)]="session.StartTime">
                  <mat-option *ngFor="let time of timeOptions" [value]="time.value">{{ time.label }}</mat-option>
                </mat-select>
              </mat-form-field>
              <span>for</span>
              <mat-form-field floatLabel="never" appearance="outline">
                <mat-select [(ngModel)]="session.Duration">
                  <mat-option *ngFor="let time of sessionTimes" [value]="time">{{ time }} minutes</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </ng-container>
        <div class="d-flex place-end mt-4">
          <button type="button" class="btn bg-primary btn-round text-white" (click)="addSession()">Add Session</button>
        </div>
        <ng-container *ngIf="!userService.user.AuthCode || !userService.user.RefreshToken">
          <p class="mt-5">Step 3: Add to your Calendar</p>
          <app-google-connect></app-google-connect>
        </ng-container>

      </div>
      
  
  </div>
  <mat-dialog-actions align="end">
    <div class="col-12 mb-3 text-center">
      <mat-error *ngIf="confirmationError && !contemplationForm.CalendarConfirmation">
        Please confirm if you are comfortable with this title appearing in your calendar
      </mat-error>
    </div>
    <div class="col-12 d-flex place-end align-center gap-3">

      <span class="inline-button badge bg-white text-dark" (click)="remindLater()">
        Remind me later
      </span>

      <button [disabled]="!userService.user.AuthCode || !userService.user.RefreshToken" type="button" class="btn btn-primary text-white w-25 me-3" (click)="scheduleSession()">
        <span *ngIf="!scheduling">SCHEDULE NOW</span>
        <div class="spinner-border" role="status"  *ngIf="scheduling">
          <span class="sr-only"></span>
        </div>
      </button>

    </div>
  </mat-dialog-actions>
</div>