import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class MetronomeService {

    audioContext: AudioContext | null = null;
    unlocked = false;
    isPlaying = false;      // Are we currently playing?
    startTime: number;      // The start time of the entire sequence.
    current16thNote: number;        // What note is currently last scheduled?
    tempo = 100.0;          // tempo (in beats per minute)
    lookahead = 25.0;       // How frequently to call scheduling 
    // (in milliseconds)
    scheduleAheadTime = 0.1;    // How far ahead to schedule audio (sec)
    // This is calculated from lookahead, and overlaps 
    // with next interval (in case the timer is late)
    nextNoteTime = 0.0;     // when the next note is due.
    noteResolution = 0;     // 0 == 16th, 1 == 8th, 2 == quarter note
    noteLength = 0.05;      // length of "beep" (in seconds)
    canvas: HTMLCanvasElement;                 // the canvas element
    canvasContext: CanvasRenderingContext2D;          // canvasContext is the canvas' context 2D
    last16thNoteDrawn = -1; // the last "box" we drew on the screen
    notesInQueue: { note: number, time: number }[] = [];      // the notes that have been put into the web audio,
    // and may or may not have played yet. {note, time}
    timerWorker: Worker | null = null;     // The Web Worker used to fire timer messages

    status: string = "stopped";

    private audioObj = new Audio();

    constructor() {
        this.init()
    }

    private stateChange: BehaviorSubject<any> = new BehaviorSubject(
        this.status
    );

    public setTempo(temp) {
        this.tempo = temp;
    }

    public setNote(note) {
        this.noteResolution = note;
    }

    // First, let's shim the requestAnimationFrame API, with a setTimeout fallback
    // requestAnimFrame = window.requestAnimationFrame;

    nextNote() {
        try {
            // Advance current note and time by a 16th note...
            const secondsPerBeat = 60.0 / this.tempo;    // Notice this picks up the CURRENT 
            // tempo value to calculate beat length.
            // console.log("Seconds Per Beat: " + secondsPerBeat);
            // this.nextNoteTime += 0.25 * secondsPerBeat;
            this.nextNoteTime += 1.0 * secondsPerBeat;    // Add beat length to last beat time
            // console.log("Tempo Changed: " + this.nextNoteTime);

            // this.current16thNote++;    // Advance the beat number, wrap to zero
            if (this.current16thNote == 16) {
                this.current16thNote = 0;
            }
        } catch (e) {
            console.log(e);
        }
    }

    scheduleNote(beatNumber: number, time: number) {
        // push the note on the queue, even if we're not playing.
        this.notesInQueue.push({ note: beatNumber, time: time });

        if ((this.noteResolution == 1) && (beatNumber % 2))
            return; // we're not playing non-8th 16th notes
        if ((this.noteResolution == 2) && (beatNumber % 4))
            return; // we're not playing non-quarter 8th notes

        // create an oscillator
        const osc = this.audioContext!.createOscillator();
        osc.connect(this.audioContext!.destination);
        if (beatNumber % 16 === 0)    // beat 0 == high pitch
            osc.frequency.value = 880.0;
        else if (beatNumber % 4 === 0)    // quarter notes = medium pitch
            osc.frequency.value = 0.0;
        // osc.frequency.value = 440.0;
        else                        // other 16th notes = low pitch
            osc.frequency.value = 0.0;
        // osc.frequency.value = 220.0;

        osc.start(time);
        osc.stop(time + this.noteLength);
    }

    scheduler() {
        // while there are notes that will need to play before the next interval, 
        // schedule them and advance the pointer.
        while (this.nextNoteTime < this.audioContext!.currentTime + this.scheduleAheadTime) {
            this.scheduleNote(this.current16thNote, this.nextNoteTime);
            this.nextNote();
        }
    }

    getState(): Observable<any> {
        return this.stateChange.asObservable();
    }

    stop() {
        this.isPlaying = false;
        this.timerWorker!.postMessage("stop");
        this.status = "stopped";
        this.stateChange.next(this.status);
    }

    bufferToDataURL(buffer) {
        const arrayBuffer = buffer.getChannelData(0).buffer;
        const blob = new Blob([arrayBuffer], { type: 'audio/wav' }); // Adjust the type as needed
        return URL.createObjectURL(blob);
    }
    
    play() {
        if (!this.audioContext)
            this.audioContext = new AudioContext();

        if (!this.unlocked) {
            // play silent buffer to unlock the audio
            const buffer = this.audioContext.createBuffer(1, 1, 22050);
            const node = this.audioContext.createBufferSource();
            node.buffer = buffer;
            const audioDataURL = this.bufferToDataURL(buffer);
            this.audioObj.src = audioDataURL;
            node.start(0);
            this.unlocked = true;
        }

        this.isPlaying = !this.isPlaying;
        this.setMediaSessionInfo();
        if (this.isPlaying) {
            this.status = "playing";

        } else {
            this.status = "paused";
        }

        this.stateChange.next(this.status);

        if (this.isPlaying) { // start playing
            this.current16thNote = 0;
            this.nextNoteTime = this.audioContext.currentTime;
            this.timerWorker!.postMessage("start");
            this.audioObj.play();
            return "stop";
        } else {
            this.timerWorker!.postMessage("stop");
            this.audioObj.pause();
            return "play";
        }
    }


    setMediaSessionInfo(){
        if('mediaSession' in navigator) {
            navigator.mediaSession.metadata = new MediaMetadata({
                title: "Walking Speed Practice",
                album: "Contemplate Practice"
            });
            navigator.mediaSession.setActionHandler('nexttrack', null);
            navigator.mediaSession.setActionHandler('previoustrack', null);
            navigator.mediaSession.setActionHandler('play', () => {
              this.play();
            });
            navigator.mediaSession.setActionHandler('pause', () => {
              console.log("play");
              this.play()
            });
            navigator.mediaSession.setActionHandler('seekto', null);
            navigator.mediaSession.setActionHandler('stop', () => {
                this.stop();
            });
        }   
    }

    resetCanvas(e: Event) {
        // resize the canvas - but remember - this clears the canvas too.
        this.canvas.width = window.innerWidth;
        this.canvas.height = window.innerHeight;

        //make sure we scroll to the top left.
        window.scrollTo(0, 0);
    }

    draw() {
        var currentNote = this.last16thNoteDrawn;
        if (this.audioContext) {
            const currentTime = this.audioContext.currentTime;

            while (this.notesInQueue.length && this.notesInQueue[0].time < currentTime) {
                currentNote = this.notesInQueue[0].note;
                this.notesInQueue.splice(0, 1);   // remove note from queue
            }

            // We only need to draw if the note has moved.
            if (this.last16thNoteDrawn != currentNote) {
                const x = Math.floor(this.canvas.width / 18);
                this.canvasContext.clearRect(0, 0, this.canvas.width, this.canvas.height);
                for (let i = 0; i < 16; i++) {
                    this.canvasContext.fillStyle = (currentNote == i) ?
                        ((currentNote % 4 === 0) ? "red" : "blue") : "black";
                    this.canvasContext.fillRect(x * (i + 1), x, x / 2, x / 2);
                }
                this.last16thNoteDrawn = currentNote;
            }
        }
        // set up to draw again
        // this.requestAnimFrame(this.draw);
    }

    init() {
        if (typeof Worker !== 'undefined') {
            // Create a new
            this.timerWorker = new Worker(new URL('./metronome.worker', import.meta.url));
            this.timerWorker.onmessage = (e) => {
                if (e.data == "tick") {
                    this.scheduler();
                }
                else
                    console.log("message: " + e.data);
            };
            this.timerWorker.postMessage({ "interval": this.lookahead });
            this.audioObj.src = "../../../assets/blank.mp3"
            this.audioObj.controls = false;
            this.audioObj.volume = 0;
            this.audioObj.addEventListener('timeupdate', () => {
                if(this.audioObj.duration - this.audioObj.currentTime < 2) {
                    this.audioObj.currentTime = 0;
                    // this.audioObj.play();
                }
            });
        } else {
            // Web workers are not supported in this environment.
            // You should add a fallback so that your program still executes correctly.
        }
    }

}