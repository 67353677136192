<form autocomplete="off" class="login-form mt-5" [formGroup]="basicProfileForm" (ngSubmit)="saveProfile()">

    <input formControlName="UserBasicId" type="hidden">

    <input formControlName="CreatedOn" type="hidden">
    <input formControlName="CreatedBy" type="hidden">

    <input formControlName="ModifiedOn" type="hidden">
    <input formControlName="ModifiedBy" type="hidden">

    <div class="row place-center">
        <div class="col-md-12 pb-2">
            <mat-label>About me</mat-label>
            <mat-form-field floatLabel="never" appearance="outline" class="w-100">
                <textarea matInput formControlName="About" type="text" placeholder="About me"
                    style="min-height: 200px;"></textarea>
            </mat-form-field>
        </div>


        <div class="row">
            <div class="col-md-6 pb-2">
                <mat-label>I was born on</mat-label>
                <mat-form-field floatLabel="never" appearance="outline" class="w-100">
                    <input matInput formControlName="DateOfBirth" [matDatepicker]="picker">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
            </div>
            <div class="col-md-6 pb-2">
                <mat-label>I am a </mat-label>
                <mat-form-field appearance="outline" floatLabel="never" class="w-100">
                    <mat-select formControlName="Gender">
                        <mat-option value="Man looking for a woman">Man looking for a woman</mat-option>
                        <mat-option value="Woman looking for man">Woman looking for man</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-6 pb-2">
                <mat-label>I currently stay in</mat-label>
                <mat-form-field floatLabel="never" appearance="outline" class="w-100">
                    <input matInput formControlName="City" type="text" placeholder="City">
                </mat-form-field>
            </div>
            <div class="col-md-6 pb-2">
                <mat-label>My hometown is </mat-label>
                <mat-form-field floatLabel="never" appearance="outline" class="w-100">
                    <input matInput formControlName="HomeTown" type="text" placeholder="City">
                </mat-form-field>
            </div>
            <div class="col-md-6 pb-2">
                <mat-label>My Highest Education is </mat-label>
                <mat-form-field floatLabel="never" appearance="outline" class="w-100">
                    <input matInput formControlName="HighestEducation" type="text">
                </mat-form-field>
            </div>
            <div class="col-md-6 pb-2">
                <mat-label>My Profession is </mat-label>
                <mat-form-field floatLabel="never" appearance="outline" class="w-100">
                    <input matInput formControlName="Profession" type="text">
                </mat-form-field>
            </div>
            <div class="col-md-12 pb-2">
                <mat-radio-group formControlName="IsReliguous">
                    <mat-radio-button value="true">I am religous</mat-radio-button>
                    <mat-radio-button value="false">I am not religous</mat-radio-button>
                </mat-radio-group>
            </div>
            <div class="col-md-12 pb-2">
                <mat-selection-list formControlName="Languages">
                    <div mat-subheader>Language I’m most comfortable in</div>
                    <mat-list-option value="English"> English</mat-list-option>
                    <mat-list-option value="Hindi"> Hindi</mat-list-option>
                    <mat-list-option value="Marathi"> Marathi</mat-list-option>
                    <mat-list-option value="Others"> Others</mat-list-option>
                </mat-selection-list>
            </div>
        </div>
        <div class="action-button dflex place-center mt-5">
            <button class="btn btn-primary full-width" type="submit" [disabled]="saving">
                <span *ngIf="saving" class="spinner-border spinner-border-sm m-0" role="status"
                    aria-hidden="true"></span>
                <span *ngIf="saving" class="sr-only"></span>
                <span *ngIf="!saving">
                    Save
                </span>
            </button>
        </div>
    </div>
</form>