<ng-container *ngIf="userService.isloggedin && userService.user && userService.user.IsBeta">
    <!-- <div class="journal-title" chatKnob [class.hide]="journalTogglerState == 'active'" (click)="onToggleJournal()">
        <div class="profile-icon">Z</div>
    </div> -->
    
    
    <div class="journal-panel" [class.spaced-out]="journalExpanded" [class.expanded]="journalExpanded">
        <div class="journal-title" (click)="currentContemplationRoom ? openContemplationRoom(null) : onToggleJournal()">
            <div class="profile-icon">Z</div>
            <div class="" *ngIf="currentContemplationRoom && journalTogglerState == 'active'">
                <h1>{{ currentContemplationRoom.value.Title }}</h1>
            </div>

        </div>
        <div class="journal-notes" [class.expanded]="journalTogglerState == 'active'">
            <ng-container *ngIf="loadingRoom">
                <div class="box-container content-center">
                    <div class="spinner-border" role="status" *ngIf="loadingJournal">
                        <span class="sr-only"></span>
                    </div>
                </div>
            </ng-container>

            <sb-assistant-conversation *ngIf="!loadingRoom && journalTogglerState == 'active'" class="notes-box" (showBadge)="showBadge($event)"
                [contemplationRoom]="currentContemplationRoom"></sb-assistant-conversation>
        </div>
    </div>
    <div class="overlay" *ngIf="journalTogglerState == 'active'"></div>
</ng-container>