import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Router } from '@angular/router';
import { ModalController, NavController } from '@ionic/angular';
import { ApiService } from '../../services/api.service';
import { CommonService } from '../../services/common.service';
import { UserService } from '../../services/user.service';
import { IonicSlides } from '@ionic/angular';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-intro-screens',
  templateUrl: './intro-screens.component.html',
  styleUrls: ['./intro-screens.component.scss'],
})
export class IntroScreensComponent implements OnInit {

  username: string;
  loading: boolean = false;

  public options = {
    keyboard: true,
    
  }
  private swiperInstance: any;
  public swiperModules = [IonicSlides];

  @ViewChild('swiper')
  set swiper(swiperRef: ElementRef) {
    /**
     * This setTimeout waits for Ionic's async initialization to complete.
     * Otherwise, an outdated swiper reference will be used.
     */
    setTimeout(() => {
      this.swiperInstance = swiperRef.nativeElement.swiper;
    }, 0);
  }


  constructor(
    private userService: UserService, 
    private navController: NavController,
    private modal: ModalController,
    private dialog: MatDialog,
    private apiService: ApiService,
    private router: Router
  ) {
    this.username = this.userService.getUserName();
  }

  ngOnInit() {
    this.markIntroduced();
  }

  markIntroduced() {
    this.loading = true;
    this.apiService.markIntroduced().then((res: any) => {
      this.userService.setIntroSteps(true).then((res: any) => {
      });
      this.loading = false;
    }).catch(e => {
      this.modal.dismiss();
      this.loading = false;
    });

  }

  getStarted(){
    this.userService.user.IsIntroduced = true;
    this.router.navigateByUrl('/dashboard/programs/core-level-1');
    this.dialog.closeAll();
  }

  skip() {
    this.dialog.closeAll();
  }

  previous() {
    if (!this.swiperInstance) return;
    this.swiperInstance.slidePrev();
  }

  next(){
    if (!this.swiperInstance) return;
    this.swiperInstance.slideNext();
  }
}
