<div class="col-md-12 p-0">
  <div #card class="box program-card white-space" [class.cleared-space]="situation.IsCleared"
    [class.uncleared-space]="!situation.IsCleared">
    <p class="status text-muted">
      {{ situation.CreatedOn | date: 'MMM d, y, h:mm a': dateOffset}}
    </p>
    <h1>{{ situation.Title }}</h1>
    <p class="margin-top-20">{{ situation.Description }}</p>
    <div class="action-buttons place-end flex-row mt-0">
      <app-adios-btn class="action-button" highlight="lillac" [addmarginleft]="false" [addmargin]="false"
        (click)="analyseSituation(situation)">ANALYSE
      </app-adios-btn>
    </div>
    <div class="swipe-box" (pan)="onPan($event)"></div>
  </div>
</div>