import { Injectable } from '@angular/core';
import { Browser } from '@capacitor/browser';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(private platform: Platform) { }


  canUseNative() {
    return new Promise<boolean>((res, rej) => {
      this.platform.ready().then(() => {
        // 'hybrid' detects both Cordova and Capacitor
        if (this.platform.is('hybrid')) {
          res(true);
        } else {
          res(false);
        }
      });
    });
  }

  isAndroid(){
    if(this.platform.is('android')){
      return true;
    } else {
      return false;
    }
  }

  isIos(){
    return new Promise<boolean>((res, rej) => {
      this.platform.ready().then(() => {
        // 'hybrid' detects both Cordova and Capacitor
        if (this.platform.is('ios')) {
          res(true);
        } else {
          res(false);
        }
      });
    });
  }

  openLink(url: string){
    this.canUseNative().then((isNative: boolean) => {
      if(isNative) {
        Browser.open({ url: url });
      } else {
        window.open(url, '_blank');
      }
    }).catch(e => {
      
    })
  }
  // openWebpage(url: string) {
  //   const options: InAppBrowserOptions = {
  //     zoom: 'no'
  //   }

  //   // Opening a URL and returning an InAppBrowserObject
  //   const browser = this.inAppBrowser.create(url, '_self', options);

  //  // Inject scripts, css and more with browser.X
  // }
}
