import { Injectable } from '@angular/core';
import { NativeStorage } from '@awesome-cordova-plugins/native-storage/ngx';
import { Platform } from '@ionic/angular';
import { BehaviorSubject, Subject } from 'rxjs';
import { DataService } from './data/data.service';
@Injectable({
  providedIn: 'root'
})
export class UserService {

  private _authChangeSub = new Subject<boolean>()
  public authChanged = this._authChangeSub.asObservable();
  user: any;
  organisation: any;
  organisationToIntroduce: any;
  isloggedin = false;
  isUserVerified = false;
  email: string = '';
  token: string;
  subscription: any;
  isCoreCompleted: boolean = false;

  private _subscriptionState = new BehaviorSubject<any>(null)
  public subscriptionState = this._subscriptionState.asObservable();

  constructor(private data: DataService) {
    
  }

  // public setUser(user: any) {
  //   this.user = user;
  // }

  public sendAuthStateChangeNotification = (isAuthenticated: boolean) => {
    this._authChangeSub.next(isAuthenticated);
  }

  public setSubscriptionState = (subscriptionState: any) => {
    this._subscriptionState.next(subscriptionState);
  }


  public getSubscriptionState = () => {
    return this._subscriptionState.value;
  }



  public logout() {
    this.data.clearAll().then((r) => {
      this.setIntroSteps(true).then((r) => {
        this.sendAuthStateChangeNotification(false);
      }).catch(e => {
        this.sendAuthStateChangeNotification(false);
      });
    }).catch(e => {

    });
  }

  public getOrganisation = (): string => {
    var organisation = "";
    if (this.organisation) {
      organisation = this.organisation;
    }
    return organisation;
  }

  public getUserName = (): string => {
    var name = "";
    if (this.user) {
      name = this.user.FirstName;
    }
    return name;
  }

  public getAvatar = (): string => {
    var avatar = null;
    if (this.user && this.user.Avatar) {
      avatar = this.user.Avatar;
    }
    return avatar;
  }

  public getFullName = (): string => {
    var name = "";
    if (this.user) {
      name = this.user.FirstName + " " + this.user.LastName;
    }
    return name;
  }

  getTermsStatus() {
    return new Promise((res, rej) => {
      var terms = this.user.TermsAgreed;
      res(terms);
    });
  }
  setTermsStatus(value: any) {
    this.user.TermsAgreed = value;
  }


  getAdioscopeStatus() {
    return new Promise((res, rej) => {
      var terms = this.user.IsAdioscopeWarningNotified;
      res(terms);
    });
  }
  setAdioscopeStatus(value: any) {
    this.user.IsAdioscopeWarningNotified = value;
  }


  getIntroducedStatus() {
    return new Promise((res, rej) => {
      var introduced = this.user.IsIntroduced;
      res(introduced);
    });
  }
  setIntroducedStatus(value: any) {
    this.user.IsIntroduced = value;
  }



  public isValidUser() {
    return new Promise((res, rej) => {
      this.data.getToken().then((token: string) => {
        if(token) {
          res(true);
          this.data.getValidity().then((validtill: string) => {
            var validtilldate = Date.parse(validtill);
            const d = new Date();
            let day = d.getUTCDate();
            if(validtilldate > day) {
              res(true);
            } else {
              rej(false);
            }
          }).catch(e => {
            rej(e);
          });
        } else {
          rej(false);
        }
      }).catch(e => {
        rej(e);
      });
    });
  }
  
  public getUserToken() {
    return new Promise((res, rej) => {
      this.data.getToken().then((token: string) => {
        this.token = token;
        res(token);
      }).catch(e => {
        rej('');
      });
    });
  }

  public getUser() {
    return new Promise((res, rej) => {
      this.data.getItem('user').then((user: string) => {
        this.user = JSON.parse(user);
        res(JSON.parse(user));
      }).catch(e => {
        rej(e);
      });
    });
  }

  public setUser(value: any) {
    return new Promise((res, rej) => {
      this.data.setItem('user',JSON.stringify(value)).then((r) => {
        this.user = value;
        this.sendAuthStateChangeNotification(true);
        res(r);
      }).catch(e => {
        rej(e);
      });
    });
  }

  public getToken() {
    return new Promise((res, rej) => {
      this.data.getToken().then((token: string) => {
        this.token = token;
        res(token);
      }).catch(e => {
        rej(e);
      });
    });
  }

  public setToken(value: any) {
    return new Promise((res, rej) => {
      this.data.setToken(value).then((r) => {
        this.token = value;
        res(r);
      }).catch(e => {
        rej(e);
      });
    });
  }


  public getValidity() {
    return new Promise((res, rej) => {
      this.data.getValidity().then((token: string) => {
        res(token);
      }).catch(e => {
        rej('');
      });
    });
  }


  public isTokenValid(setToken?: boolean) {
    return new Promise((res, rej) => {
      this.data.getValidity().then((validtill: string) => {
        if(setToken){
          res(true);
        } else {
          var validtilldate = Date.parse(validtill);
          const d = new Date();
          let day = d.getUTCDate();
          if(validtilldate > day) {
            res(true);
          } else {
            rej(false);
          }
        }
      }).catch(e => {
        rej(e);
      });
    });
  }

  public setValidity(value: any) {
    return new Promise((res, rej) => {
      this.data.setValidity(value).then((r) => {
        res(r);
      }).catch(e => {
        rej(e);
      });
    });
  }

  public getProgramHighlight() {
    return new Promise((res, rej) => {
      this.data.getItem('program_highlight').then((token: string) => {
        res(token);
      }).catch(e => {
        rej(e);
      });
    });
  }

  public setProgramHighlight(value: any) {
    return new Promise((res, rej) => {
      this.data.setItem('program_highlight',value).then((r) => {
        res(r);
      }).catch(e => {
        rej(e);
      });
    });
  }

  public getIntroSteps() {
    return new Promise((res, rej) => {
      this.data.getItem('intro').then((token: string) => {
        res(token);
      }).catch(e => {
        rej(e);
      });
    });
  }

  public setIntroSteps(value: any) {
    return new Promise((res, rej) => {
      this.data.setItem('intro',value).then((r) => {
        res(r);
      }).catch(e => {
        rej(e);
      });
    });
  }

  public clearJoiningCohort() {
    return new Promise((res, rej) => {
      this.data.removeItem('joiningCohort').then((cohort: any) => {
        res(cohort);
      }).catch(e => {
        rej(e);
      });
    });
  }
  public getJoiningCohort() {
    return new Promise((res, rej) => {
      this.data.getItem('joiningCohort').then((cohort: string) => {
        res(JSON.parse(cohort));
      }).catch(e => {
        rej(e);
      });
    });
  }

  public setJoiningCohort(value: any) {
    return new Promise((res, rej) => {
      this.data.setItem('joiningCohort',JSON.stringify(value)).then((r) => {
        res(r);
      }).catch(e => {
        rej(e);
      });
    });
  }



  public clearUpgradingParticipant() {
    return new Promise((res, rej) => {
      this.data.removeItem('upgradingPP').then((cohort: any) => {
        res(cohort);
      }).catch(e => {
        rej(e);
      });
    });
  }
  public getUpgradingParticipant() {
    return new Promise((res, rej) => {
      this.data.getItem('upgradingPP').then((cohort: string) => {
        res(cohort);
      }).catch(e => {
        rej(e);
      });
    });
  }

  public setUpgradingParticipant(value: any) {
    return new Promise((res, rej) => {
      this.data.setItem('upgradingPP',value).then((r) => {
        res(r);
      }).catch(e => {
        rej(e);
      });
    });
  }
  
}
