import { Injectable, LOCALE_ID } from '@angular/core';
import { Observable, BehaviorSubject, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
// import * as moment from "moment";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserService } from 'src/app/shared/services/user.service';
// import { StreamState } from '../interfaces/stream-state';
// import { MusicControls } from '@awesome-cordova-plugins/music-controls/ngx';
import { format } from 'date-fns';

import { Howl, Howler } from 'howler';
import { ApiService } from './api.service';
import { CommonService } from './common.service';


@Injectable()
export class HowlAudioService {

  cachedPlayer: any = {};
  currentPlaylist: any;
  currentPlaylistContent: any = [];
  currentIndex: any;
  type: any;
  currentPlayer: Howl;

  currentTrack: any = {};
  progress: any = {};

  private state: any = {
    loading: false,
    loaded: false,
    playing: false,
    paused: false,
    readableCurrentTime: '',
    readableRemainingTime: '',
    readableDuration: '',
    duration: undefined,
    currentTime: undefined,
    remainingTime: undefined,
    volume: 0.5,
    canplay: false,
    error: false,
  };
  private stop$ = new Subject();
  private audioObj = new Audio();
  audioEvents = [
    "ended",
    "error",
    "play",
    "playing",
    "pause",
    "timeupdate",
    "canplay",
    "loadedmetadata",
    "loadstart",
    "seeking",
    "seeked"
  ];
  
  private stateChange: BehaviorSubject<any> = new BehaviorSubject(
    this.state
  );


  private currentTrackObservable: BehaviorSubject<any> = new BehaviorSubject(
    this.currentTrack
  );

  private progressObservable: BehaviorSubject<any> = new BehaviorSubject(
    this.progress
  );

  constructor(
    private userService: UserService,
    private apiService: ApiService,
    private common: CommonService,
    // private musicControls: MusicControls,
    private http: HttpClient,) {

    let howler = new  HowlerGlobal()
    howler.usingWebAudio = true;
    howler.autoUnlock = true;
    howler.autoSuspend = false;
    // howler.autoUnlock = false;
  }

  setPlayer(type: any, playlist: any, content: any, index: any) {
    this.type = type;
    this.currentPlaylist = playlist;
    this.currentPlaylistContent = content;
    this.currentIndex = index;

    this.currentTrack = {
      currentPlaylistTitle: playlist.Title,
      currentPlaylistId: playlist.Id,
      currentPlaylistContentTitle: content[index].Title,
      currentContentId: content[index].Id,
      currentIndex: index,
      contents: content,
      type: this.type,
      playlist: playlist,
      hasPrevious: index > 0 ? true : false,
      hasNext: index < content.length - 1 ? true : false, 
    }

    if (this.currentPlaylist && this.currentPlaylist.PlaylistId == playlist.PlaylistId) {
      this.skipTo(index);
    } else {
      this.play(index);
    }
  }


  /**
   * Play a song in the playlist.
   * @param  {Number} index Index of the song in the playlist (leave empty to play the first or current).
   */
  play(index) {
    this.currentIndex = index;
    // index = typeof index === 'number' ? index : this.currentIndex;
    if(!this.cachedPlayer[this.type]) {
      this.cachedPlayer[this.type] = {};
    }
    
    // If we already loaded this track, use the current one.
    // Otherwise, setup and load a new Howl.
    console.log("Checking from existing");
    if (this.cachedPlayer[this.type][this.currentPlaylistContent[this.currentIndex].Id]) {
      this.currentPlayer = this.cachedPlayer[this.type][this.currentPlaylistContent[this.currentIndex].Id];
    } else {
      this.currentPlayer = this.cachedPlayer[this.type][this.currentPlaylistContent[this.currentIndex].Id] = this.getHowlFor(this.currentIndex);
    }

    this.currentTrack = {
      currentPlaylistTitle: this.currentPlaylist.Title,
      currentPlaylistId: this.currentPlaylist.Id,
      currentPlaylistContentTitle: this.currentPlaylistContent[index].Title,
      currentContentId: this.currentPlaylistContent[index].Id,
      currentIndex: index,
      type: this.type,
      contents: this.currentPlaylistContent,
      playlist: this.currentPlaylist,
      hasPrevious: index > 0 ? true : false,
      hasNext: index < this.currentPlaylistContent.length - 1 ? true : false, 
    }

    this.preload();

    this.setMediaSessionInfo(index);

    this.setTrack(this.currentTrack, this.type);
    
    // Begin playing the sound.
    Howler.stop();
    this.currentPlayer.play();

    // Show the pause button.
    console.log(this.currentPlayer.state());
    if (this.currentPlayer.state() === 'loaded') {
      console.log("player loaded");
      this.state.loading = false;
      this.state.loaded = true;
      this.state.seeking = false;
      this.state.duration = this.currentPlayer.duration();
      this.state.readableDuration = this.formatTime(this.state.duration);
      if (this.state.currentTime) {
        this.state.remainingTime = this.currentPlayer.duration() - this.state.currentTime;
        this.state.readableRemainingTime = this.formatTime(this.state.remainingTime);
        this.state.readableCurrentTime = this.formatTime(this.state.currentTime);
      } else {
        this.state.readableCurrentTime = "00:00"
      }
      this.state.canplay = true;
    } else {
      this.state.loading = true;
      console.log("player loading")
    }

    this.stateChange.next(this.state);

    // Keep track of the index we are currently playing.
    this.currentIndex = index;
  }

  preload() {

    if(!this.cachedPlayer[this.type]) {
      this.cachedPlayer[this.type] = {};
    }

    if(this.currentIndex > 0) {
      //Preload Previous
      if (!this.cachedPlayer[this.type][this.currentPlaylistContent[this.currentIndex - 1].Id]) {
        this.cachedPlayer[this.type][this.currentPlaylistContent[this.currentIndex - 1].Id] = this.getHowlFor(this.currentIndex - 1, true);
      }
    }

    if (this.currentIndex < this.currentPlaylistContent.length - 1) {
      //Preload next
      if (!this.cachedPlayer[this.type][this.currentPlaylistContent[this.currentIndex + 1].Id]) {
        this.cachedPlayer[this.type][this.currentPlaylistContent[this.currentIndex + 1].Id] = this.getHowlFor(this.currentIndex + 1, true);
      }
    }
  }

  markCompleted(contentId, meta) {
    this.progress[contentId] = meta;
    this.progressObservable.next(this.progress);
  }

  setProgress(progress){
    this.progress = progress;
    this.progressObservable.next(this.progress);
  }

  getHowlFor(index, preload ?: any) {
    var data = this.currentPlaylistContent[index];
    var howl: Howl = new Howl({
      src: [data.Url],
      preload: true,
      html5: true, // Force to HTML5 so that the audio can stream in (best for large files).
      onplay: () => {
        console.log("Start Playing");
        // Display the duration.
        this.state.playing = true;
        this.state.loading = false;
        this.state.loaded = true;
        this.state.paused = false;

        this.state.duration = howl.duration();
        this.state.currentTime = howl.seek();
        this.state.remainingTime = howl.duration() - this.state.currentTime;
        this.state.readableDuration = this.formatTime(this.state.duration);
        this.state.readableRemainingTime = this.formatTime(this.state.remainingTime);
        this.state.readableCurrentTime = this.formatTime(this.state.currentTime);

        this.stateChange.next(this.state);
        this.setMediaSessionInfo(index);
        this.step();
      },
      onload: () => {
        console.log("State Loaded");
        this.state.loading = false;
        this.state.loaded = true;
        this.state.seeking = false;
        this.state.duration = howl.duration();
        this.state.currentTime = howl.seek();
        this.state.remainingTime = howl.duration() - this.state.currentTime;
        this.state.readableDuration = this.formatTime(this.state.duration);
        this.state.readableRemainingTime = this.formatTime(this.state.remainingTime);
        this.state.readableCurrentTime = this.formatTime(this.state.currentTime);

        this.state.canplay = true;
        this.stateChange.next(this.state);
        this.setMediaSessionInfo(index);
      },
      onfade: () => {
        console.log("Fading: ", data);
      },
      onend: () => {
        console.log("End: ", data);
        setTimeout(() => {
          this.skip('next');
          this.stateChange.next(this.state);
        }, 2000);
      },
      onpause: () => {
        console.log("On Paused Called");
        this.state.playing = false;
        this.state.paused = true;
        this.stateChange.next(this.state);
      },
      onstop: () => {
        console.log("Sound has been stopped");
        this.stateChange.next(this.state);
      },
      onseek: () => {
        console.log("Sound has been seeked");
        this.stateChange.next(this.state);
        this.step();
        // Start updating the progress of the track.
      },
      onloaderror: (error) => {
        console.log("onload error: ", error);
        this.stateChange.next(this.state);
      },
      onplayerror: (error) => {
        console.log("onplay error: ", error);
        this.stateChange.next(this.state);
      },
    });

    if(preload) {
      var currentVolume = this.currentPlayer.volume();
      howl.volume(0);
      howl.play();
      howl.stop();
      howl.volume(currentVolume);
    }

    return howl;
  }

  setMediaSessionInfo(index){
    this.common.canUseNative().then((isNative: boolean) => {
      if (!isNative) {
        var data = this.currentPlaylistContent[index];
        /* Implementation of the Media Session API */
        if('mediaSession' in navigator) {
          navigator.mediaSession.metadata = new MediaMetadata({
              title: data.Title,
              album: this.currentPlaylist ? this.currentPlaylist.Title : '',
              // artwork: [
              //     { src: 'https://assets.codepen.io/4358584/1.300.jpg', sizes: '96x96', type: 'image/png' },
              //     { src: 'https://assets.codepen.io/4358584/1.300.jpg', sizes: '128x128', type: 'image/png' },
              //     { src: 'https://assets.codepen.io/4358584/1.300.jpg', sizes: '192x192', type: 'image/png' },
              //     { src: 'https://assets.codepen.io/4358584/1.300.jpg', sizes: '256x256', type: 'image/png' },
              //     { src: 'https://assets.codepen.io/4358584/1.300.jpg', sizes: '384x384', type: 'image/png' },
              //     { src: 'https://assets.codepen.io/4358584/1.300.jpg', sizes: '512x512', type: 'image/png' }
              // ]
          });
          navigator.mediaSession.setActionHandler('nexttrack', (details) => {
            this.skip('next')
          });
          navigator.mediaSession.setActionHandler('previoustrack', (details) => {
            this.skip('prev');
          });
          navigator.mediaSession.setActionHandler('play', () => {
              if(!this.currentPlayer.playing()) {
                  this.currentPlayer.play();
              } else {
                  this.pause();
              }
          });
          navigator.mediaSession.setActionHandler('pause', () => {
            if(!this.currentPlayer.playing()) {
              this.currentPlayer.play();
            } else {
                this.pause();
            }
          });
          navigator.mediaSession.setActionHandler('seekbackward', (details) => {
            this.currentPlayer.seek(this.currentPlayer.seek() - (details.seekOffset || 10));
          });
          navigator.mediaSession.setActionHandler('seekforward', (details) => {
            this.currentPlayer.seek(this.currentPlayer.seek() + (details.seekOffset || 10));
          });
          navigator.mediaSession.setActionHandler('seekto', (details) => {
            this.seek(details.seekTime);
          });
          navigator.mediaSession.setActionHandler('stop', () => {
              this.currentPlayer.stop();
          });
        }
      }
    });
  }

  /**
   * Pause the currently playing track.
   */
  resume() {
    // Pause the sound.
    if(this.currentPlayer)
      this.currentPlayer.play();

  }

  /**
   * Pause the currently playing track.
   */
  pause() {
    // Pause the sound.
    if(this.currentPlayer)
      this.currentPlayer.pause();

  }

  /**
   * Skip to the next or previous track.
   * @param  {String} direction 'next' or 'prev'.
   */
  skip(direction) {
    // Get the next track based on the direction of the track.
    var index = 0;
    if (direction === 'prev') {
      index = this.currentIndex - 1;
      if (index < 0) {
        index = this.currentPlaylistContent.length - 1;
      }
    } else {
      index = this.currentIndex + 1;
      if (index >= this.currentPlaylistContent.length) {
        index = 0;
      }
    }

    this.skipTo(index);
  }

  /**
   * Skip to a specific track based on its playlist index.
   * @param  {Number} index Index in the playlist.
   */
  skipTo(index) {
    // Stop the current track.
    if (this.cachedPlayer[this.type] && this.cachedPlayer[this.type][this.currentPlaylistContent[this.currentIndex].Id]) {
      this.cachedPlayer[this.type][this.currentPlaylistContent[this.currentIndex].Id].stop();
    }

    // Play the new track.
    this.play(index);
  }

  /**
   * Set the volume and update the volume slider display.
   * @param  {Number} val Volume between 0 and 1.
   */
  volume(val) {
    // Update the global volume (affecting all Howls).
    Howler.volume(val);
  }

  /**
   * Seek to a new position in the currently playing track.
   * @param  {Number} per Percentage through the song to skip.
   */
  seek(per) {
    // Get the Howl we want to manipulate.
    var sound = this.currentPlayer;

    // Convert the percent into a seek position.
    if (sound) {
      sound.seek(per);
    }
  }

  // /**
  //  * The step called within requestAnimationFrame to update the playback position.
  //  */
  step = () => {
    // Get the Howl we want to manipulate.
    var sound = this.currentPlayer;

    // Determine our current seek position.
    var seek = sound.seek() || 0;

    this.state.currentTime = seek;
    this.state.duration = sound.duration();
    this.state.remainingTime = sound.duration() - this.state.currentTime;
    this.state.readableDuration = this.formatTime(this.state.duration);
    this.state.readableRemainingTime = this.formatTime(this.state.remainingTime);
    this.state.readableCurrentTime = this.formatTime(this.state.currentTime);
    this.stateChange.next(this.state);

    // If the sound is still playing, continue stepping.
    if (sound) {
      requestAnimationFrame(this.step);
    }
  }

  /**
   * Toggle the playlist display on/off.
   */
  togglePlaylist() {
    // var self = this;
    // var display = (playlist.style.display === 'block') ? 'none' : 'block';

    // setTimeout(function() {
    //   playlist.style.display = display;
    // }, (display === 'block') ? 0 : 500);
    // playlist.className = (display === 'block') ? 'fadein' : 'fadeout';
  }

  /**
   * Toggle the volume display on/off.
   */
  toggleVolume() {
    // var self = this;
    // var display = (volume.style.display === 'block') ? 'none' : 'block';

    // setTimeout(function() {
    //   volume.style.display = display;
    // }, (display === 'block') ? 0 : 500);
    // volume.className = (display === 'block') ? 'fadein' : 'fadeout';
  }

  /**
   * Format the time from seconds to M:SS.
   * @param  {Number} secs Seconds to format.
   * @return {String}      Formatted time.
   */
  formatTime(secs) {
    var minutes = parseInt(Math.floor(secs / 60).toFixed(0)) || 0;
    var seconds = parseInt((secs - minutes * 60).toFixed(0)) || 0;

    return minutes + ':' + (seconds < 10 ? '0' : '') + seconds;
  }












  private updateStateEvents(event: Event): void {
    switch (event.type) {
      case "loadstart":
        this.state.loading = true;
        break;
      case "seeking":
        this.state.seeking = true;
        break;
      case "seeked":
        this.state.seeking = false;
        break;
      case "canplay":
        this.state.loading = false;
        this.state.loaded = true;
        this.state.seeking = false;
        this.state.duration = this.audioObj.duration;
        this.state.readableDuration = this.formatTime(this.state.duration);
        if (this.state.currentTime) {
          this.state.readableCurrentTime = this.formatTime(this.state.currentTime);
        } else {
          this.state.readableCurrentTime = "00:00"
        }
        this.state.canplay = true;
        break;
      case "playing":
        this.state.playing = true;
        this.state.paused = false;
        break;
      case "pause":
        this.state.playing = false;
        this.state.paused = true;
        break;
      case "timeupdate":
        this.state.currentTime = this.audioObj.currentTime;
        this.state.readableCurrentTime = this.formatTime(
          this.state.currentTime
        );
        break;
      case "error":
        this.resetState();
        this.state.error = true;
        break;
    }
    this.stateChange.next(this.state);
  }

  public resetState() {
    this.state = {
      playing: false,
      paused: false,
      readableCurrentTime: '',
      readableDuration: '',
      duration: undefined,
      currentTime: undefined,
      volume: 1,
      canplay: false,
      error: false,
      seeking: false
    };
  }

  getState(): Observable<any> {
    return this.stateChange.asObservable();
  }

  getCurrentTrack(): Observable<any> {
    return this.currentTrackObservable.asObservable();
  }

  getProgress(): Observable<any> {
    return this.progressObservable.asObservable();
  }

  public unsetTrack() {
    this.currentTrackObservable.next(null);
  }

  public setTrack(track, type) {
    track.Type = type;
    this.currentTrackObservable.next(track);
  }

  stop() {
    console.log("Stop called");
    this.stop$.next(true);
  }

  seekTo(seconds: any) {
    this.audioObj.currentTime = seconds;
  }

  setVolume(volume: any) {
    this.audioObj.volume = volume;
  }


  // formatTime(time: number, format2: string = "mm:ss") {
  //   // const momentTime = time * 1000;
  //   // return moment.utc(momentTime).format(format);
  //   const dt = new Date(time * 1000);
  //   const date1 = new Date(dt.valueOf() + dt.getTimezoneOffset() * 60 * 1000);
  //   return format(date1, format2);
  // }














}
