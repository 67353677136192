<div class="reaction-test">
  <h2>Test 1: Button Reaction Test</h2>
  <p>Instructions: Press the button as quickly as you can when you see the light flash on the screen.</p>
  <button (click)="startTest()">Start Test</button>
  <div *ngIf="showLight" class="light"></div>
  <div *ngIf="trials.length > 0">
    <div *ngFor="let trial of trials; let i = index">
      <p>Trial {{i + 1}}: {{trial}} ms</p>
    </div>
    <p>Average Reaction Time: {{averageTime}} ms</p>
  </div>
</div>