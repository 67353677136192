import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
// import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { ApiService } from 'src/app/shared/services/api.service';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'sb-join-program',
  templateUrl: './join-batch.component.html',
  styleUrls: ['./join-batch.component.scss']
})
export class JoinBatchComponent implements OnInit {

  // @Input() close: any;
  @Output() onsubmit: EventEmitter<any> = new EventEmitter();  


  form?: FormGroup;
  loading: boolean = false;
  error?: string;

  constructor(
    public fb: FormBuilder,
    // public dialogRef: MatDialogRef<JoinBatchComponent>,
    public modal: ModalController,
    public apiService: ApiService,
    public snackbar: MatSnackBar,
    public userService: UserService,
    private router: Router
  ) {

    this.form = this.fb.group({
      JoiningCode: new FormControl('', Validators.compose([Validators.required])),
    });
  }

  ngOnInit(): void {
  }

  submitPassword() {
    this.error = undefined
    if (this.form?.valid) {
      var data = this.form.value;
      this.loading = true;
      this.apiService
        .enrollBatch(data.JoiningCode, false, false)
        .then((res: any) => {
          if (res.data) {
            if(res.data.orderRef){
              this.onNoClick();
              this.router.navigateByUrl('/payment/finish/' + res.data.orderRef + "?t=" + res.data.token);
            } else {
              this.onsubmit.emit(res.data);
              this.onNoClick();
            }
          }
          this.loading = false;
        })
        .catch(e => {
          console.log(e);
          if (!e.msg) {
            e.msg = 'Something went wrong. Please try again';
          }
          this.error = e.msg;
          this.snackbar.open(e.msg);
          this.loading = false;
        });
    }

  }

  onNoClick() {
    this.modal.dismiss();
    // this.close.emit();
  }

}
