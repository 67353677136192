<ng-container *ngIf="loading">
    <div class="row m-0 flex h-100" style="place-content: center; align-content: center;">
        <div class="col-md-12 text-center" style="padding: 5rem;">
            <p>
                <span class="spinner-border spinner-border-lg" role="status" aria-hidden="true"></span>
            </p>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="!loading && situation">
    <div class="list-box pt-3 p-0 w-100">
        <div class="row m-0 w-100">
            <div class="p-0" [class.col-md-8]="!fullWidth" [class.col-md-12]="fullWidth"
                [class.offset-md-2]="(recommendedSituations.length == 0 && recentSituations.length == 0) && !fullWidth">
                <mat-accordion class="chapter-list w-100" multi>
                    <mat-expansion-panel class="chapter-card mat-elevation-z0" hideToggle disabled>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                Situation / Question
                            </mat-panel-title>
                            <mat-panel-description>
                                {{ situation.Description }}
                            </mat-panel-description>
                        </mat-expansion-panel-header>
                    </mat-expansion-panel>
                    <ng-container *ngFor="let situationMap of situation.SituationMaps; let i = index;">
                        <mat-expansion-panel *ngFor="let map of situationMap.SituationPrincipleMaps; let j = index;"
                            class="chapter-card mat-elevation-z0" [expanded]="true">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    {{ map.Principle.Name }}
                                </mat-panel-title>
                                <mat-panel-description class="m-0 mt-0">
                                    <i>{{ map.Principle.Description }}</i>
                                </mat-panel-description>
                            </mat-expansion-panel-header>

                            <mat-list class="module-list">
                                <ng-container *ngFor="let trigger of map.Principle.PrincipleTriggers">
                                    <ng-container
                                        *ngIf="situationMap.SituationPrincipleMapsDict[trigger.PrincipleTriggerId] && situationMap.SituationPrincipleMapsDict[trigger.PrincipleTriggerId].Explanation">
                                        <div mat-subheader class="module-name">Question: {{ trigger.Trigger }}</div>
                                        <mat-list-item class="content-card">
                                            <!-- <div mat-line class="content-title">{{ trigger.Trigger }}</div> -->
                                            <div mat-line class="content-title"
                                                *ngIf="situationMap.SituationPrincipleMapsDict[trigger.PrincipleTriggerId] && situationMap.SituationPrincipleMapsDict[trigger.PrincipleTriggerId].Explanation">
                                                {{
                                                situationMap.SituationPrincipleMapsDict[trigger.PrincipleTriggerId].Explanation
                                                }}</div>
                                            <div mat-line class="content-title text-center"
                                                *ngIf="!situationMap.SituationPrincipleMapsDict[trigger.PrincipleTriggerId] || !situationMap.SituationPrincipleMapsDict[trigger.PrincipleTriggerId].Explanation">
                                                NA</div>
                                        </mat-list-item>
                                    </ng-container>
                                </ng-container>
                                <ng-container *ngIf="map.Explanation">
                                    <div mat-subheader class="module-name">Question: Other thoughts</div>
                                    <mat-list-item class="content-card">
                                        <div mat-line class="content-title">{{map.Explanation}}</div>
                                    </mat-list-item>
                                </ng-container>
                                <ng-container *ngIf="map.Action">
                                    <div mat-subheader class="module-name">Question: What should I do?</div>
                                    <mat-list-item class="content-card" *ngIf="map.Action">
                                        <div mat-line class="content-title">{{map.Action}}</div>
                                    </mat-list-item>
                                </ng-container>
                            </mat-list>

                        </mat-expansion-panel>
                    </ng-container>
                </mat-accordion>
            </div>
            <div class="col-md-4"
                *ngIf="(recommendedSituations.length > 0 || recentSituations.length > 0) && !fullWidth">
                <div class="w-100">
                    <div class="chapter-list w-100">
                        <div class="chapter-card mat-elevation-z0" *ngIf="recommendedSituations.length > 0">
                            <mat-list class="module-list">
                                <div mat-subheader class="module-name">Related Situations</div>
                                <mat-list-item class="content-card" *ngFor="let s of recommendedSituations"
                                    (click)="viewSituation(s)">
                                    <div mat-line class="content-title link">{{ s.Title }}</div>
                                </mat-list-item>
                            </mat-list>
                        </div>
                        <div class="chapter-card mat-elevation-z0" *ngIf="recentSituations.length > 0">
                            <mat-list class="module-list">
                                <div mat-subheader class="module-name">Recent Situations</div>
                                <mat-list-item class="content-card" *ngFor="let s of recentSituations"
                                    (click)="viewSituation(s)">
                                    <div mat-line class="content-title link">{{ s.Title }}</div>
                                </mat-list-item>
                            </mat-list>
                        </div>
                    </div>
                    <!-- <mat-accordion class="chapter-list w-100" multi>
                        <mat-expansion-panel class="chapter-card mat-elevation-z0" [expanded]="true" hideToggle disabled
                            *ngIf="recommendedSituations.length > 0">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    Related Situations
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <mat-list class="module-list">
                                <div mat-subheader class="module-name">Related Situations</div>
                                <mat-list-item class="content-card" *ngFor="let s of recommendedSituations"
                                    (click)="viewSituation(s)">
                                    <div mat-line class="content-title link">{{ s.Title }}</div>
                                </mat-list-item>
                            </mat-list>
                        </mat-expansion-panel>
    
    
                        <mat-expansion-panel class="chapter-card mat-elevation-z0" [expanded]="true" hideToggle disabled
                            *ngIf="recentSituations.length > 0">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    Recent Situations
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <mat-list class="module-list">
                                <mat-list-item class="content-card" *ngFor="let s of recentSituations"
                                    (click)="viewSituation(s)">
                                    <div mat-line class="content-title link">{{ s.Title }}</div>
                                </mat-list-item>
                            </mat-list>
                        </mat-expansion-panel>
    
                    </mat-accordion> -->
                </div>
            </div>
        </div>
    </div>

</ng-container>

<ng-template #principleDetails let-modal let-d="dismiss">
    <div class="modal-container">
        <div class="box" *ngIf="!loadingDetails">
            <div class="back" (click)="d()">
                <mat-icon>close</mat-icon>
            </div>
            <h1 class="title flex">{{ detailPrinciple?.Name }}
                <div class="ml-auto align-self-end pr-4">
                </div>
            </h1>
            <div class="login-form text-left">
                <ng-container *ngIf="detailPrinciple?.Description && detailPrinciple?.Description != ''">
                    <div class="form-group">
                        <label class="font-weight-semibold" style="color: rgba(0,150,136, 1);">Description</label>
                        <p style="font-size: 14px; line-height: 20px;">{{ detailPrinciple?.Description }}</p>
                    </div>
                </ng-container>
                <ng-container *ngIf="detailPrinciple?.PrincipleTriggers.length > 0">
                    <div class="form-group">
                        <label class="font-weight-semibold" style="color: rgba(0,150,136, 1);">Trigger Questions</label>
                        <ul>
                            <li *ngFor="let trigger of detailPrinciple?.PrincipleTriggers">
                                {{ trigger.Trigger }}
                            </li>
                        </ul>
                    </div>
                </ng-container>
            </div>
        </div>
        <div class="align-items-center row" *ngIf="loadingDetails">
            <div class="col-md-12 text-center p-5">
                <div class="spinner-border"></div>
            </div>
        </div>
    </div>
</ng-template>