<div #page class="" *ngIf="!subscriptionState?.IsMember || (subscriptionState?.IsMember && !subscriptionState?.IsSubscribed)">
    <!-- <button *ngIf="!subscriptionState?.IsMember" class="btn btn-success w-100 text-white" type="button" (click)="startTrial()">
      <span *ngIf="startingTrial" class="spinner-border spinner-border-lg" role="status"
        aria-hidden="true"></span>
      <span *ngIf="!startingTrial">TRY WEEK 1 FOR FREE<br><small>(No credit card required)</small></span>
    </button> -->
    <button (click)="openSubscribeModal()" *ngIf="subscriptionState?.IsMember && !subscriptionState?.IsSubscribed" class="btn btn-default bg-white w-100 text-dark" type="button">
      <span *ngIf="subscribing" class="spinner-border spinner-border-lg" role="status"
        aria-hidden="true"></span>
        <span *ngIf="!subscribing && !isMobile">
          Subscribe Now
        </span>
        <span *ngIf="!subscribing && isMobile">
          Use Affiliation Code
        </span>
    </button>
</div>


<button *ngIf="subscriptionState?.IsMember && subscriptionState?.IsSubscribed" (click)="recommendAdios()" style="font-size: 14px" class="float-button btn btn-default bg-white text-dark w-100 dflex place-center align-center">
  Recommend Contemplate
</button>